import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Layout, Button, Icon, Select, InputNumber, Radio, Upload, Input, Modal, Transfer, Tag, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DraggerProps } from 'antd/lib/upload';

import { IPublisher } from 'models/publisher';
import { IAppOverview } from 'models/appOverview';
import { AppPurchaseType } from 'models/purchaseRecord';
import { IAppPricingPlan } from 'models/appPricing';

import { NewAppPricingPlan } from 'components/prices/newPricingPlanDrawer';
import Loading from 'containers/Loading';

import { getCategories, publishNewBundle, latestEngineVersion, getApps } from 'api/apps';
import { getPublisher } from 'api/publisher';

import { capitalizeFirstLetter } from 'utils/appStatus';

import './newbundle.less';

interface INewAppBundleFormValues {
    name: string;
    nameSlug: string;
    description: string;
    version: string;
    categories: string[];
    selectedApps: string[];
    engineVersion: string;
    price: string;
    purchaseType: AppPurchaseType;
}

interface INewAppBundleProps extends RouteComponentProps, FormComponentProps<INewAppBundleFormValues> { }

interface INewAppBundleState {
    isLoading: boolean;
    isSaving: boolean;
    drawerVisible: boolean;
    categories: string[];
    latestEngineVersion: string;
    pricingPlans: Partial<IAppPricingPlan>[];
    iconFileData: string;
    iconFile?: File;
    availableApps: IAppOverview[];
    publisher?: IPublisher;
}

class NewAppBundleBase extends React.PureComponent<INewAppBundleProps, INewAppBundleState> {
    state: Readonly<INewAppBundleState> = {
        isLoading: true,
        isSaving: false,
        drawerVisible: false,
        categories: [],
        latestEngineVersion: '',
        pricingPlans: [],
        iconFileData: '',
        availableApps: [],
    };

    async componentDidMount() {
        const publisher = await getPublisher();
        if (!publisher || !publisher.canCreateBundles) {
            notification.error({
                message: 'Bundle Creation not Available',
                description: 'Bundle creation has not yet been enabled for you. Please contact Rocket.Chat Support if you are interested in creating bundles.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const categories = await getCategories().then((r) => r.map((c) => c.title));
        const latestVersion = await latestEngineVersion();
        const apps = await getApps();

        this.setState({
            categories,
            latestEngineVersion: latestVersion.version,
            availableApps: apps,
            isLoading: false,
            publisher,
        });
    }

    notLatestEngineVersionPopUp = () => {
        return new Promise<void>((resolve, reject) => {
            Modal.confirm({
                title: 'Required API Version Not Latest',
                content: 'The required api version is not the latest version of the App Engine. Are you sure you want to create the bundle?',
                onOk() { resolve() },
                onCancel() { reject() },
                okText: 'Yes!',
                okType: 'danger',
                cancelText: 'Whoops, no.',
                cancelButtonProps: { type: 'primary' },
            });
        });
    }

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { form } = this.props;

        form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            console.log('new app bundle form values:', values);

            if (values.engineVersion !== this.state.latestEngineVersion) {
                try {
                    await this.notLatestEngineVersionPopUp();
                } catch (e) {
                    return;
                }
            }

            this.setState({ isSaving: true }, async () => {
                const data = new FormData();
                const info = {
                    name: values.name,
                    nameSlug: values.nameSlug,
                    description: values.description,
                    version: values.version,
                    categories: values.categories,
                    requiredApiVersion: values.engineVersion,
                    iconFileData: this.state.iconFileData,
                    purchaseType: values.purchaseType,
                    price: values.price,
                    pricingPlans: this.state.pricingPlans,
                };

                data.append('bundleInfo', JSON.stringify(info));
                data.append('appIds', JSON.stringify(values.selectedApps));

                const result = await publishNewBundle(data);

                this.props.history.push({ pathname: `/publisher/bundles/${result.appId}` });
            });
        });
    }

    get disablePublish() {
        const errors = this.props.form.getFieldsError();

        return Object.keys(errors).length === 0 ||
            Object.keys(errors).some(key => errors[key] !== undefined) ||
            (typeof this.state.iconFile === 'undefined' || typeof this.state.iconFile.name === 'undefined' || this.state.iconFile.name.trim() === '' ||
                typeof this.state.iconFileData === 'undefined' || this.state.iconFileData.length === 0);
    }

    get header() {
        return (
            <Layout.Header className="newapp-header">
                <h2>Publish a New App Bundle</h2>
            </Layout.Header>
        )
    }

    //#region name inputs
    handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        const nameSlug = name.toLowerCase().replace(/ /g, '-').replace(/[^a-z-]+/g, '').replace(/-$/, '');

        this.props.form.setFieldsValue({ nameSlug });
    }

    get nameInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Name" colon={false}>
                {getFieldDecorator('name', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please set the name of the bundle.' }]
                })(
                    <Input
                        placeholder="Bundle Name"
                        onChange={this.handleNameChange}
                        disabled={this.state.isSaving}
                    />
                )}
            </Form.Item>
        );
    }

    get nameSlugInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Name Slug" colon={false}>
                {getFieldDecorator('nameSlug', {
                    initialValue: '',
                })(
                    <Input placeholder="name-slug" disabled />
                )}
            </Form.Item>
        );
    }
    //#endregion name inputs

    get descriptionInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Description" colon={false}>
                {getFieldDecorator('description', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please describe the bundle so clients understand what they\'re getting.' }]
                })(
                    <Input.TextArea
                        placeholder="Description"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={this.state.isSaving}
                    />
                )}
            </Form.Item>
        );
    }

    get versionInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Version" colon={false}>
                {getFieldDecorator('version', {
                    initialValue: '',
                    rules: [
                        { required: true, message: 'Please provide a version for the Bundle.' },
                        { pattern: /\b(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)$/, message: 'Version must be of semver format: 1.0.0' },
                    ]
                })(
                    <Input placeholder="1.0.0" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }

    get categorySelection() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { categories } = this.state;
        const selectedCategories = getFieldValue('categories') as string[];

        let filteredOptions = [...categories];
        if (selectedCategories && Array.isArray(selectedCategories) && selectedCategories.length > 0) {
            filteredOptions = categories.filter(o => !selectedCategories.includes(o));
        }

        return (
            <Form.Item label="Categories" colon={false}>
                {getFieldDecorator('categories', {
                    initialValue: [],
                    rules: [{ required: true, message: 'Please select at least one category.' }]
                })(
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Categories"
                        disabled={this.state.isSaving}
                    >
                        {filteredOptions.map((item) => (
                            <Select.Option key={item}>
                                {item}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        );
    }

    get requiredApiVersionInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Engine Version" colon={false}>
                {getFieldDecorator('engineVersion', {
                    initialValue: this.state.latestEngineVersion,
                    rules: [
                        { required: true, message: 'Please provide the required engine version for the Bundle.' },
                        { pattern: /\b(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)\.(?:0|[1-9]\d*)$/, message: 'Version must be of semver format: 1.0.0' },
                    ]
                })(
                    <Input placeholder="1.0.0" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }

    //#region purchase type
    get purchaseType() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Purchase Type" colon={false}>
                {getFieldDecorator('purchaseType', {
                    initialValue: AppPurchaseType.Buy,
                    rules: [{ required: true, message: 'Please select the purchase type.' }]
                })(
                    <Radio.Group buttonStyle="solid" disabled={this.state.isSaving || !this.state.publisher || !this.state.publisher.canCreatePaidApps}>
                        <Radio.Button value={AppPurchaseType.Buy}>Buy</Radio.Button>
                        <Radio.Button value={AppPurchaseType.Subscription}>Subscription</Radio.Button>
                    </Radio.Group>
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region price input
    get priceInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Price" colon={false}>
                {getFieldDecorator('price', {
                    initialValue: 0,
                    rules: [{ required: true, message: 'Please set a fixed price to charge.' }]
                })(
                    <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                        min={0}
                        disabled={this.state.isSaving || !this.state.publisher || !this.state.publisher.canCreatePaidApps}
                    />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region pricing plans
    onPlanAddClick = () => {
        this.setState({ drawerVisible: true });
    }

    drawerFinished = (plan: Partial<IAppPricingPlan>) => {
        const { pricingPlans } = this.state;

        const existing = this.state.pricingPlans.filter((p) => p.strategy === plan.strategy);
        if (existing.length !== 0) {
            notification.error({
                message: `A ${capitalizeFirstLetter(plan.strategy)} Plan Already Exists!`,
                description: `You already have a pricing plan for the ${plan.strategy} strategy, only one of the same strategy is allowed.`,
                duration: 0,
            });

            return;
        }

        pricingPlans.push(plan);

        this.setState({ drawerVisible: false, pricingPlans });
    }

    drawerClose = () => {
        this.setState({ drawerVisible: false });
    }

    handleRemovalOfPlan = (plan: Partial<IAppPricingPlan>) => {
        const pricingPlans = this.state.pricingPlans.filter((p) => p.strategy !== plan.strategy);
        this.setState({ pricingPlans });
    }

    get pricingPlans() {
        const { pricingPlans } = this.state;

        const has = { monthly: false, yearly: false };
        pricingPlans.forEach((p) => {
            switch (p.strategy) {
                case 'monthly':
                    has.monthly = true;
                    return;
                case 'yearly':
                    has.yearly = true;
                    return;
                default:
                    return;
            }
        });

        return (
            <Form.Item label="Pricing Plans" required={true} colon={false}>
                <Button type="dashed" size="small" onClick={this.onPlanAddClick} disabled={has.yearly && has.monthly}><Icon type="plus" /> Add a plan</Button><br />
                {pricingPlans.map((p) => <Tag key={p.strategy} color="cyan" closable={true} onClose={() => this.handleRemovalOfPlan(p)}>{capitalizeFirstLetter(p.strategy)}</Tag>)}
            </Form.Item>
        );
    }
    //#endregion

    get uploadBundleImage() {
        const uploadProps: DraggerProps = {
            accept: 'image/*',
            multiple: false,
            showUploadList: false,
            beforeUpload: (file) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.addEventListener('load', () => {
                    if (!reader || typeof reader.result !== 'string') {
                        return;
                    }

                    this.setState({ iconFileData: reader ? reader.result.replace('data:image/jpeg;base64,', '') : '' });
                });

                this.setState({ iconFile: file });
                return false;
            }
        };

        return (
            <Form.Item label="Bundle Icon" required={true} colon={false}>
                <Upload.Dragger {...uploadProps} disabled={this.state.isSaving}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="picture" />
                    </p>
                    <p className="ant-upload-text">Click or drag the Bundle's icon</p>
                    <p className="ant-upload-hint">Ensure you only select images, such as png or jpg.</p>
                </Upload.Dragger>

                {this.state.iconFile && this.state.iconFileData && this.state.iconFileData.length !== 0 ?
                    <div>
                        <Icon type="paper-clip" /> {this.state.iconFile.name}
                    </div>
                    : null}
            </Form.Item>
        );
    }

    get appSelection() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Bundled Apps" required={true} colon={false} wrapperCol={{ span: 12 }}>
                {getFieldDecorator('selectedApps', {
                    initialValue: [],
                    valuePropName: 'targetKeys',
                    rules: [{ required: true, message: 'At least one App is required to be in the bundle.' }]
                })(
                    <Transfer
                        dataSource={this.state.availableApps as any[]} //TODO: fix and test
                        titles={['Available', 'Selected']}
                        rowKey={(app) => app.appId}
                        render={(app) => app.latest.name}
                        disabled={this.state.isSaving}
                        listStyle={{
                            width: 300,
                        }}
                    />
                )}
            </Form.Item>
        );
    }

    get publishButton() {
        return (
            <Form.Item wrapperCol={{ span: 12, offset: 3 }}>
                <Button htmlType="submit" type="primary" loading={this.state.isSaving} disabled={this.disablePublish} icon="save">
                    Publish Bundle
                </Button>
            </Form.Item>
        );
    }

    get newAppBundleView() {
        const formItemLayout = {
            labelCol: { span: 3 },
            wrapperCol: { span: 8 },
        };

        return (
            <Layout className="newbundle">
                {this.header}
                <Layout.Content>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit} id="new-bundle-form">
                        {this.nameInput}
                        {this.nameSlugInput}
                        {this.descriptionInput}
                        {this.versionInput}
                        {this.categorySelection}
                        {this.requiredApiVersionInput}
                        {this.purchaseType}
                        {this.props.form.getFieldValue('purchaseType') === AppPurchaseType.Subscription ? this.pricingPlans : this.priceInput}
                        {this.uploadBundleImage}
                        {this.appSelection}
                        {this.publishButton}
                    </Form>
                    <NewAppPricingPlan isVisible={this.state.drawerVisible} finished={this.drawerFinished} close={this.drawerClose} />
                </Layout.Content>
            </Layout>
        );
    }

    render() {
        return this.state.isLoading ? <Loading /> : this.newAppBundleView;
    }
}

export const NewAppBundleView = withRouter(Form.create<INewAppBundleProps>()(NewAppBundleBase));
