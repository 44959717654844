import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, notification, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IAdminAppOverview } from 'models/appOverview';
import { AppStatus } from 'models/appInfo';
import { getAdminApps, makeAppFeatured } from 'api/admin';

interface IFormValues {
    appId: string;
    sectionSlug: string;
}

interface IMarkAsFeaturedDrawerProps extends FormComponentProps<IFormValues> {
    visible: boolean;
    close: () => void;
}

const MarkAsFeaturedDrawerBase: React.FC<IMarkAsFeaturedDrawerProps> = (props) => {
    const [apps, setApps] = useState<IAdminAppOverview[]>([]);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        getAdminApps().then((apps) => {
            setApps(apps);
        }).catch((e) => {
            console.warn('failed to load the apps for the create purchase record:', e);
        });
    }, []);

    const onClose = () => {
        props.form.resetFields();
        props.close();
    };

    const onSaveClick = async () => {
        setSaving(true);

        try {
            await makeAppFeatured(props.form.getFieldValue('appId'), props.form.getFieldValue('sectionSlug'));
            notification.success({ message: 'Successfully made the app featured. '});

            onClose();
        } catch (e) {
            console.warn('error making an app featured:', e);
            notification.warn({ message: 'Failed to make the app featured :(' });
        }
    };

    return (
        <Drawer
            title="Mark App As Featured"
            visible={props.visible}
            onClose={onClose}
            closable={!saving}
            maskClosable={!saving}
            width={350}
        >
            <Form layout="vertical">
                <Form.Item label="App">
                    { props.form.getFieldDecorator('appId', {
                        rules: [{ required: true, message: 'Select the App which will be featured.' }],
                    })(
                        <Select disabled={saving}>
                            { apps.map((a) => (
                                <Select.Option key={a.appId} value={a.appId} disabled={a.latest.status !== AppStatus.Published}>
                                    { a.latest.name }
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item label="Section Slug">
                    { props.form.getFieldDecorator('sectionSlug', {
                        rules: [{ required: true, message: 'The section slug is required in order to make it featured'}],
                    })(
                        <Select disabled={saving}>
                            <Select.Option value="community_featured">Featured Community Apps</Select.Option>
                            <Select.Option value="community_supported">Community Supported Apps</Select.Option>
                            <Select.Option value="enterprise">Featured Premium Apps</Select.Option>
                            <Select.Option value="featured">Featured Apps</Select.Option>
                            <Select.Option value="most_popular">Most Popular Apps</Select.Option>
                            <Select.Option value="new_arrivals">New Arrivals</Select.Option>
                            <Select.Option value="popular_this_month">Apps Popular this Month</Select.Option>
                            <Select.Option value="recommended">Recommended Apps</Select.Option>
                            <Select.Option value="social">Social Apps</Select.Option>
                            <Select.Option value="trending">Trending Apps</Select.Option>
                            <Select.Option value="omnichannel">Omnichannel Apps</Select.Option>
                            <Select.Option value="video_conferencing">Video Conferencing Apps</Select.Option>
                        </Select>
                    )}
                </Form.Item>
            </Form>

            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    zIndex: 1235,
                }}
            >
                <Button onClick={onClose} disabled={saving} style={{ marginRight: 8 }}>Cancel</Button>
                <Button onClick={onSaveClick} type="primary" disabled={saving} loading={saving}>Save</Button>
            </div>
        </Drawer>
    );
}

export const MarkAsFeaturedDrawer = Form.create<IMarkAsFeaturedDrawerProps>()(MarkAsFeaturedDrawerBase);
