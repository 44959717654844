import React from 'react';
import { Layout, Row, Col, Button, Form, Input, message } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';

import { IDeveloper } from 'models/developer';

import Loading from 'containers/Loading';

import { getMyInfo, update as updateProfile } from 'api/profile';

import './profile.less';

interface IProfileViewFormValues {
    firstName: string;
    lastName: string;
    email: string;
}

interface IProfileViewProps extends RouteComponentProps, FormComponentProps<IProfileViewFormValues> { }

interface IProfileViewState {
    loading: boolean;
    isSaving: boolean;
    profile?: IDeveloper;
}

class ProfileViewBase extends React.PureComponent<IProfileViewProps, IProfileViewState> {
    state: Readonly<IProfileViewState> = {
        loading: true,
        isSaving: false,
    };

    async componentDidMount() {
        const profile = await getMyInfo();

        this.setState({ profile, loading: false });
    }

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ isSaving: true }, async () => {
                const { firstName, lastName } = values;
                const profile = await updateProfile({ firstName, lastName });

                this.setState({ profile, isSaving: false });
                message.success('Profile successfully updated!');
            })
        })
    }

    get disableSave() {
        const errors = this.props.form.getFieldsError();

        return Object.keys(errors).length === 0 || Object.keys(errors).some(key => errors[key] !== undefined);
    }

    get header() {
        return (
            <Layout.Header className="profile-header">
                <h2>Developer Profile</h2>
                <Button htmlType="submit" type="primary" form="profile-form" icon="save" loading={this.state.isSaving} disabled={this.disableSave}>Save changes</Button>
            </Layout.Header>
        )
    }

    get nameInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="First Name" colon={false}>
                        {getFieldDecorator('firstName', {
                            initialValue: this.state.profile ? this.state.profile.firstName : '',
                            rules: [{ required: true, message: 'First name is required.' }],
                        })(
                            <Input size="large" placeholder="Enter your first name" />
                        )}
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="Last Name" colon={false}>
                        {getFieldDecorator('lastName', {
                            initialValue: this.state.profile ? this.state.profile.lastName : '',
                            rules: [{ required: true, message: 'Last name is required.' }],
                        })(
                            <Input size="large" placeholder="and last name here" />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    get emailInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col span={12}>
                    <Form.Item label="Email address" colon={false}>
                        {getFieldDecorator('email', {
                            initialValue: this.state.profile ? this.state.profile.email : '',
                            rules: [{ required: true, message: 'Email address is required.' }],
                        })(
                            <Input size="large" type="email" placeholder="example@example.com" disabled />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    get profileView() {
        return (
            <Layout className="profile layout-modal">
                {this.header}

                <Layout.Content>
                    <Form onSubmit={this.handleSubmit} id="profile-form">
                        {this.nameInput}
                        {this.emailInput}
                    </Form>
                </Layout.Content>
            </Layout>
        )
    }

    render() {
        return this.state.loading ? <Loading /> : this.profileView;
    }
}

export const ProfileView = withRouter(Form.create<IProfileViewProps>()(ProfileViewBase))
