import React from 'react';
import { Drawer, Row, Col, Form, Button, Input, Select, Icon, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IPublisher } from 'models/publisher';
import { IAppOverview } from 'models/appOverview';
import { IAdminEmailPublisherPayload } from 'models/payloads/admin';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { postAdminEmailPublisher, getAdminPublisherApps } from 'api/admin';

interface IFormValues {
    layout: string;
    subject: string;
    relatedAppId: string;
    replyTo: string;
}

interface IContactPublisherDrawerProps extends FormComponentProps<IFormValues> {
    isVisible: boolean;
    publisher?: IPublisher;
    close: () => void;
    finished: () => void;
}

interface IContactPublisherDrawerState {
    isSaving: boolean;
    content: string;
    apps: IAppOverview[];
}

class ContactPublisherDrawerBase extends React.PureComponent<IContactPublisherDrawerProps, IContactPublisherDrawerState> {
    state: Readonly<IContactPublisherDrawerState> = {
        isSaving: false,
        content: '',
        apps: [],
    };

    async componentDidUpdate(prevProps: IContactPublisherDrawerProps) {
        if (prevProps.publisher && !this.props.publisher) {
            // there was a publisher before but now there's not
            // let's reset the apps array to nothing
            this.setState({ apps: [] });
            return;
        }

        if (!this.props.publisher) {
            //aside from the above, there's nothing else we care about when
            return;
        }

        if (!prevProps.publisher && this.props.publisher) {
            const apps = await getAdminPublisherApps(this.props.publisher._id);

            this.setState({ apps });
        }
    }

    get disableSave() {
        return this.state.content === '' || this.props.form.getFieldValue('subject') === '';
    }

    onClose = () => {
        this.props.close();

        this.props.form.resetFields();
        this.setState({ isSaving: false, content: '', apps: [] });
    };

    handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            if (!this.state.content || !this.props.publisher) {
                return;
            }

            this.setState({ isSaving: true }, async () => {
                const emailData: IAdminEmailPublisherPayload = {
                    layout: values.layout,
                    subject: values.subject,
                    content: this.state.content,
                    relatedAppId: values.relatedAppId,
                    replyTo: values.replyTo,
                };

                await postAdminEmailPublisher(this.props.publisher!._id, emailData);

                notification.success({ message: `Email successfully sent to: ${this.props.publisher!.name}!` });
                this.props.finished();

                setTimeout(() => {
                    if (!this.props.isVisible) {
                        //reset the form and state
                        this.props.form.resetFields();
                        this.setState({ isSaving: false, content: '', apps: [] });
                    }
                }, 500);
            });
        });
    }

    // sadly ckeditor doesn't have any typings
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleEditorChange = (event: void, editor: any) => {
        this.setState({ content: editor.getData() });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { publisher } = this.props;

        return (
            <Drawer
                title={"Contact Publisher: " + (publisher ? publisher.name : '-')}
                width={750}
                onClose={this.onClose}
                visible={this.props.isVisible}
                closable={this.state.isSaving}
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Layout">
                                {getFieldDecorator('layout', {
                                    initialValue: 'layout-email-main',
                                    rules: [{ required: true, message: 'please select the layout' }],
                                })(
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="layout"
                                        disabled={true}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Related App">
                                {getFieldDecorator('relatedAppId')(
                                    <Select disabled={this.state.isSaving}>
                                        {this.state.apps ? this.state.apps.map((a) => (
                                            <Select.Option key={a.appId}>
                                                {a.latest.name}
                                            </Select.Option>
                                        )) : null}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Subject">
                                {getFieldDecorator('subject', {
                                    rules: [{ required: true, message: 'please enter the subject of the email' }],
                                })(
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="email subject"
                                        disabled={this.state.isSaving}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Reply To">
                                {getFieldDecorator('replyTo')(
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="replyTo@rocket.chat"
                                        disabled={this.state.isSaving}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Email Content">
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.content}
                                    onChange={this.handleEditorChange}
                                    disabled={this.state.isSaving}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={this.state.isSaving}><Icon type="stop" /> Cancel</Button>
                    <Button onClick={this.handleSubmit} type="primary" disabled={this.state.isSaving || this.disableSave}><Icon type="cloud-upload" /> Send</Button>
                </div>
            </Drawer>
        );
    }
}

export const ContactPublisherDrawer = Form.create<IContactPublisherDrawerProps>()(ContactPublisherDrawerBase);
