import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Row, Col, Table, Icon, Menu, Dropdown, message, Button, notification } from 'antd';
import { ClickParam } from 'antd/lib/menu';

import { IPublisher } from 'models/publisher';
import { IDeveloper } from 'models/developer';
import { IAdminAppOverview } from 'models/appOverview';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import Loading from 'containers/Loading';

import { AdminAppsTable } from '../AppsTable';

import { EditDeveloperDrawer } from './editDeveloperDrawer';
import { EditPublisherDrawer } from './editPublisherDrawer';
import { ContactPublisherDrawer } from './contactPublisherDrawer';
import { PayoutPublisherModal } from './payoutModal';
import { AdminChangeDeveloperRoleModal } from './changeDeveloperRoleModal';
import { AdminChangeDeveloperSystemRoleModal } from './changeDeveloperSystemRoleModal';

import { getAdminPublisher, getAdminPublisherDevelopers, getAdminPublisherApps, getAdminPublisherBundles } from 'api/admin';
import { ColumnProps } from 'antd/lib/table';
import { getCloudOAuthInfo } from 'api/misc';

interface IAdminPublisherViewProps extends RouteComponentProps<{ id: string }>, IWithPermissionProps { }

interface IAdminPublisherViewState {
    loading: boolean;
    publisher?: IPublisher;
    developers: IDeveloper[];
    editingDeveloper?: IDeveloper;
    apps: IAdminAppOverview[];
    bundles: IAdminAppOverview[];
    drawers: {
        dev: boolean;
        pub: boolean;
        email: boolean;
        role: boolean;
        systemRole: boolean;
    };
    modals: {
        payout: boolean;
    };
    cloudUrl: string;
}


class AdminPublisherViewBase extends React.PureComponent<IAdminPublisherViewProps, IAdminPublisherViewState> {
    state: Readonly<IAdminPublisherViewState> = {
        loading: true,
        developers: [],
        apps: [],
        bundles: [],
        drawers: {
            dev: false,
            pub: false,
            email: false,
            role: false,
            systemRole: false,
        },
        modals: {
            payout: false,
        },
        cloudUrl: 'https://cloud.rocket.chat',
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IAdminPublisherViewProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:publishers'].canRead) {
            console.log('user can not read the system:publishers');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Publishers. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const { id } = this.props.match.params;
        const [publisher, developers, apps, bundles, cloudoAuthInfo] = await Promise.all([getAdminPublisher(id), getAdminPublisherDevelopers(id), getAdminPublisherApps(id), getAdminPublisherBundles(id), getCloudOAuthInfo()]);

        this.setState({
            loading: false,
            publisher,
            developers,
            apps,
            bundles,
            cloudUrl: cloudoAuthInfo.cloudUrl,
        });
    }

    //#region developer table
    onActionClick = (developer: IDeveloper) => async ({ key }: ClickParam) => {
        switch (key) {
            case 'edit':
                this.setState({
                    editingDeveloper: developer,
                    drawers: {
                        ...this.state.drawers,
                        dev: true,
                    },
                });
                return;
            case 'change-role':
                this.setState({
                    editingDeveloper: developer,
                    drawers: {
                        ...this.state.drawers,
                        role: true,
                    },
                });
                return;
            case 'change-system-role':
                this.setState({
                    editingDeveloper: developer,
                    drawers: {
                        ...this.state.drawers,
                        systemRole: true,
                    },
                });
                return;
            default:
                message.info(`You clicked on the ${key} action item on ${developer.email}`);
                break;
        }
    }

    getActionMenuItems = (developer: IDeveloper) => {
        return (
            <Menu onClick={this.onActionClick(developer)}>
                <Menu.Item key="edit" disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="edit" /> Edit
                </Menu.Item>
                <Menu.Item key="change-role" disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="team" /> Change Role
                </Menu.Item>
                <Menu.Item key="change-system-role" disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="solution" /> Change System Role
                </Menu.Item>
            </Menu>
        );
    }

    columns: ColumnProps<IDeveloper>[] = [
        {
            title: 'Name', key: 'name',
            render: (name, record) => record.firstName ? `${record.firstName} ${record.lastName}` : '-',
        },
        {
            title: 'Email', dataIndex: 'email', key: 'email',
        },
        {
            title: 'Role', dataIndex: 'role', key: 'role', className: 'title-caps',
        },
        {
            title: 'System Role', dataIndex: 'systemRole', key: 'systemRole', className: 'title-caps',
        },
        {
            title: 'Last Activity', dataIndex: 'lastActivityDate', key: 'lastActivityDate',
            render: (lastActivityDate) => new Date(lastActivityDate).toLocaleDateString(),
        },
        {
            title: 'Cloud User ID', dataIndex: 'cloudUserId', key: 'cloudUserId',
            render: (cloudUserId) => {
                if (!cloudUserId) {
                    return <span>Not linked</span>;
                }

                const url = `${ this.state.cloudUrl }/admin/users/${cloudUserId}`;

                return <a href={url} target="_blank" rel="noopener noreferrer">{cloudUserId} <Icon type="export" /></a>;
            },
        },
        {
            title: 'RC Username', dataIndex: 'rocketChatUsername', key: 'rocketChatUsername',
            render: (rocketChatUsername) => {
                if (!rocketChatUsername) {
                    return (
                        <span>-</span>
                    );
                }

                const url = `https://open.rocket.chat/direct/${rocketChatUsername}`;

                return (
                    <a href={url} target="_blank" rel="noopener noreferrer">@{rocketChatUsername}</a>
                );
            },
        },
        {
            key: 'actions', title: 'Actions',
            render: (nothing, record) => {
                return (
                    <Dropdown overlay={this.getActionMenuItems(record)} trigger={['hover']}>
                        <span className="ant-dropdown-link">
                            Actions <Icon type="down" />
                        </span>
                    </Dropdown>
                );
            }
        }
    ]
    //#endregion developer table

    //#region edit drawer
    drawerClose = async (reload?: boolean) => {
        if (reload) {
            await this.loadData();
        }

        this.setState({
            editingDeveloper: undefined,
            drawers: {
                dev: false,
                pub: false,
                email: false,
                role: false,
                systemRole: false,
            },
        });
    }

    drawerFinished = async () => {
        await this.loadData();
        await this.drawerClose(true);
    }
    //#endregion edit drawer

    //#region operations area
    onPayoutClose = async (submitted: boolean) => {
        if (submitted) {
            await this.loadData();
        }

        this.setState({ modals: { payout: false } });
    }

    onOperationActionClick = async ({ key }: ClickParam) => {
        switch (key) {
            case 'email':
                this.setState({
                    drawers: {
                        ...this.state.drawers,
                        email: true,
                    }
                });
                return;
            case 'edit':
                this.setState({
                    drawers: {
                        ...this.state.drawers,
                        pub: true,
                    }
                });
                return;
            case 'stripe-account':
                window.open(`https://dashboard.stripe.com/${process.env.NODE_ENV !== 'production' ? 'test/' : ''}connect/accounts/${this.state.publisher ? this.state.publisher.stripeAccountId : 'unknown'}`, '_blank');
                return;
            case 'stripe-payout':
                this.setState({ modals: { payout: true } });
                return;
            default:
                message.info(`You clicked on the ${key} operation item`);
                break;
        }
    }

    get operationsMenuItems() {
        if (!this.state.publisher) {
            return null;
        }

        return (
            <Menu onClick={this.onOperationActionClick}>
                <Menu.Item key="edit" disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="edit" /> Edit
                </Menu.Item>
                <Menu.Item key="email" disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="mail" /> Email
                </Menu.Item>
                <Menu.Item key="stripe-account" disabled={!this.state.publisher.stripeAccountId || !this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="link" /> Stripe Account
                </Menu.Item>
                <Menu.Item key="stripe-payout" disabled={!this.state.publisher.stripeAccountId || this.state.publisher.stripeAvailableBalance < 10 || !this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="wallet" /> Payout
                </Menu.Item>
            </Menu>
        );
    }
    //#endregion

    get tosLink() {
        if (!this.state.publisher) {
            return null;
        }

        return (
            <div>
                TOS Link: <a href={this.state.publisher.tosLink} target="_blank" rel="noopener noreferrer">{this.state.publisher.tosLink}</a>
            </div>
        );
    }

    get privacyLink() {
        if (!this.state.publisher) {
            return null;
        }

        return (
            <div>
                Privacy Link: <a href={this.state.publisher.privacyLink} target="_blank" rel="noopener noreferrer">{this.state.publisher.privacyLink}</a>
            </div>
        );
    }

    get webhookLink() {
        if (!this.state.publisher) {
            return null;
        }

        return (
            <div>
                Webhook Link: <a href={this.state.publisher.webhookLink} target="_blank" rel="noopener noreferrer">{this.state.publisher.webhookLink}</a>
            </div>
        );
    }

    get balance() {
        const { publisher } = this.state;

        if (!publisher || !publisher.stripeAccountId) {
            return null;
        }

        return (
            <div>
                <span>Available Balance: ${publisher.stripeAvailableBalance.toFixed(2)}</span><br />
                <span>Pending Balance: ${publisher.stripePendingBalance.toFixed(2)}</span>
            </div>
        );
    }

    get publisherView() {
        const { publisher, developers } = this.state;
        if (!publisher) {
            return null;
        }

        return (
            <Layout>
                <Layout.Header className='profile-header'>
                    <h2>Publisher Profile</h2>

                    <Dropdown overlay={this.operationsMenuItems} trigger={['hover']} disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                        <Button type="dashed" icon="tool">
                            Operations <Icon type="down" />
                        </Button>
                    </Dropdown>
                </Layout.Header>
                <Layout.Content>
                    <Row>
                        <Col span={8}>
                            <div>Name: {publisher.name}</div>
                            {this.tosLink}
                            {this.privacyLink}
                            {this.webhookLink}
                            {this.balance}
                        </Col>
                    </Row>
                    <Row>
                        <Layout.Header className="profile-header">
                            <h2>Developers</h2>
                        </Layout.Header>
                        <Table rowKey={(r) => r.email} columns={this.columns} dataSource={developers} />
                    </Row>
                    <Row>
                        <Layout.Header className="profile-header">
                            <h2>Apps</h2>
                        </Layout.Header>
                        <AdminAppsTable
                            type="adminApps"
                            onActionTaken={this.loadData}
                            tableProps={{
                                pagination: { hideOnSinglePage: true },
                                loading: this.state.loading,
                                dataSource: this.state.apps,
                                rowKey: (record) => record.latest.id,
                            }}
                        />
                    </Row>
                    <Row>
                        <Layout.Header className="profile-header">
                            <h2>Bundles</h2>
                        </Layout.Header>
                        <AdminAppsTable
                            type="adminApps"
                            tableProps={{
                                pagination: { hideOnSinglePage: true },
                                loading: this.state.loading,
                                dataSource: this.state.bundles,
                                rowKey: (record) => record.latest.id,
                            }}
                            onActionTaken={this.loadData}
                        />
                    </Row>

                    <PayoutPublisherModal publisher={this.state.publisher} visible={this.state.modals.payout} close={this.onPayoutClose} />
                    <EditDeveloperDrawer developer={this.state.editingDeveloper} publisherId={this.state.publisher ? this.state.publisher._id : ''} isVisible={this.state.drawers.dev} finished={this.drawerFinished} close={this.drawerClose} />
                    <EditPublisherDrawer publisher={this.state.publisher} isVisible={this.state.drawers.pub} finished={this.drawerFinished} close={this.drawerClose} />
                    <ContactPublisherDrawer publisher={this.state.publisher} isVisible={this.state.drawers.email} finished={this.drawerFinished} close={this.drawerClose} />
                    <AdminChangeDeveloperRoleModal developer={this.state.editingDeveloper} visible={this.state.drawers.role} onClose={this.drawerClose} />
                    <AdminChangeDeveloperSystemRoleModal developer={this.state.editingDeveloper} visible={this.state.drawers.systemRole} onClose={this.drawerClose} />
                </Layout.Content>
            </Layout>
        )
    }

    render() {
        return this.state.loading ? <Loading /> : this.publisherView;
    }
}

export const AdminPublisherView = connectPermissions({ feature: 'system:publishers', system: true })(withRouter(AdminPublisherViewBase));
