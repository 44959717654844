import React from 'react';
import { Layout, Row, Col, Badge, Avatar, Descriptions, Typography, Button, Modal, List, notification } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IAdminAppOverview } from 'models/appOverview';
import { IAppStatistics } from 'models/appStatus';

import { AdminAppsTable } from '../AppsTable';
import Loading from 'containers/Loading';

import { statusToColor, capitalizeFirstLetter } from 'utils/appStatus';
import { getAdminBundleByID, getAdminAppVersions, getAdminAppStats, getAdminBundleAppsByID } from 'api/admin';
import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

interface IAdminBundleViewProps extends RouteComponentProps<{ id: string }>, IWithPermissionProps { }

interface IAdminBundleViewState {
    loading: boolean;
    bundle?: IAdminAppOverview;
    versions: IAdminAppOverview[];
    bundledApps: IAdminAppOverview[];
    stats?: IAppStatistics;
}

class AdminBundleViewBase extends React.PureComponent<IAdminBundleViewProps, IAdminBundleViewState> {
    state: Readonly<IAdminBundleViewState> = {
        loading: true,
        versions: [],
        bundledApps: [],
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IAdminBundleViewProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:bundles'].canRead) {
            console.log('user can not read the system:bundles');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Bundles. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const [bundle, versions, stats, bundledApps] = await Promise.all([
            getAdminBundleByID(this.props.match.params.id),
            getAdminAppVersions(this.props.match.params.id),
            getAdminAppStats(this.props.match.params.id),
            getAdminBundleAppsByID(this.props.match.params.id),
        ]);

        const bundleVersions: IAdminAppOverview[] = versions.map((v) => {
            return {
                ...bundle,
                latest: v,
            };
        });

        this.setState({ loading: false, bundle, versions: bundleVersions, bundledApps, stats });
    }

    onStatsClick = () => {
        if (!this.state.bundle || !this.state.stats) {
            return;
        }

        const { stats } = this.state;
        const { purchaseType, latest } = this.state.bundle;

        const content = [<List.Item key='totalDownloads'>Total Downloads: {stats.totalDownloads}</List.Item>];
        if (purchaseType === 'buy') {
            content.push(<List.Item key='purchases'>Purchases: {stats.purchases}</List.Item>);
        } else {
            content.push(<List.Item key='activeSubscriptions'>Active Subscriptions: {stats.activeSubscriptions}</List.Item>);
            content.push(<List.Item key='inactiveSubscriptions'>Inactive Subscriptions: {stats.inactiveSubscriptions}</List.Item>);
        }

        Modal.info({
            title: latest.name + '\'s Stats',
            content: (
                <Layout>
                    <List>{content}</List>

                    <h3>Downloads</h3>
                    <List
                        dataSource={Object.keys(stats.appVersions)}
                        renderItem={(v, index) => {
                            const stat = stats.appVersions[v];

                            return (
                                <List.Item key={'version-' + v + '-' + index}>v{v}: {stat.downloads}</List.Item>
                            );
                        }}
                    />
                </Layout>
            ),
        });
    };

    get bundleHeader() {
        const { bundle } = this.state;
        if (!bundle) {
            return null;
        }

        const descriptions = [
            <Descriptions.Item key="description" label="Description" span={3}>{bundle.latest.description}</Descriptions.Item>,
            <Descriptions.Item key="categories" label="Categories" span={3}>{bundle.latest.categories.join(', ')}</Descriptions.Item>,
            <Descriptions.Item key="status" label="Status"><Badge color={statusToColor[bundle.latest.status]} text={capitalizeFirstLetter(bundle.latest.status)} /></Descriptions.Item>,
            <Descriptions.Item key="purchaseType" label="Purchase Type">{capitalizeFirstLetter(bundle.purchaseType)}</Descriptions.Item>,
            <Descriptions.Item key="appStats" label="Stats"><Button type="dashed" onClick={this.onStatsClick}>View Stats</Button></Descriptions.Item>
        ];

        return (
            <Layout.Content>
                <Row type="flex" justify="space-between">
                    <Col span={8}>
                        <Typography.Title level={2}>{bundle.latest.name}</Typography.Title>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={2}>
                        <Avatar className="avatar" size="large" shape="square" src={`data:image/jpg;base64,${bundle.latest.iconFileData}`} />
                    </Col>
                    <Col span={22}>
                        <Descriptions bordered>
                            {descriptions}
                        </Descriptions>
                    </Col>
                </Row>
            </Layout.Content>
        )
    }

    get bundleView() {
        return (
            <Layout>
                {this.bundleHeader}

                <Layout.Content>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={3}>Versions</Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <AdminAppsTable
                                type="adminVersions"
                                onActionTaken={this.loadData}
                                tableProps={{
                                    pagination: { hideOnSinglePage: true },
                                    loading: this.state.loading,
                                    dataSource: this.state.versions,
                                    rowKey: (record) => record.latest.version,
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Typography.Title level={3}>Bundled Apps</Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <AdminAppsTable
                                type="adminBundledApps"
                                onActionTaken={this.loadData}
                                tableProps={{
                                    pagination: { hideOnSinglePage: true },
                                    loading: this.state.loading,
                                    dataSource: this.state.bundledApps,
                                    rowKey: (record) => record.latest.internalId!,
                                }}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        );
    }

    render() {
        return this.state.loading ? <Loading /> : this.bundleView;
    }
}

export const AdminBundleView = connectPermissions({ feature: 'system:bundles', system: true })(withRouter(AdminBundleViewBase));
