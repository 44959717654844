import { IAppInfoAuthor } from "./appAuthorInfo";
import { ISimpleBundleInfo } from "./appOverview";
import { IAppPermission } from "./appPermission";
import { IAppPricingPlan } from "./appPricing";
import { AppPurchaseType } from "./purchaseRecord";
import { IPurchaseSubscription } from "./purchaseSubscription";

export interface IAppInfo {
    internalId?: string;

    id: string;
    name: string;
    nameSlug: string;
    version: string;
    categories: string[];
    languages: string[];
    shortDescription: string;
    description: string;
    privacyPolicySummary: string;
    documentationUrl?: string;

    requiredApiVersion: string;
    permissions: IAppPermission[];
    author: IAppInfoAuthor;
    classFile: string;
    iconFile: string;
    iconFileData: string;
    status: AppStatus;
    reviewedNote?: string;
    rejectionNote?: string;
    changesNote: string;
    internalChangesNote: string;
    isVisible: boolean;
    createdDate: string;
    modifiedDate: string;

    price: number;
    pricingPlans?: IAppPricingPlan[];
    isPurchased: boolean;
    isSubscribed: boolean;
    subscriptionInfo?: IPurchaseSubscription;
    purchaseType?: AppPurchaseType;

    bundledIn?: ISimpleBundleInfo[];

    compileJobId?: string;
    compiled: boolean;
}

export enum AppStatus {
    Draft = 'draft',
    Submitted = 'submitted',
    AuthorRejected = 'author-rejected',
    AuthorApproved = 'author-approved',
    Reviewed = 'reviewed',
    Rejected = 'rejected',
    Approved = 'approved',
    Published = 'published',
    Unpublished = 'unpublished',
    Compiling = 'compiling',
    Compiled = 'compiled',
    CompilerError = 'compiler-error',
}

export type AppInfoVersionMapByInternalId = { [internalId: string]: IAppInfo };
