import { message } from 'antd';

export const BASE_URL = '/v1';
export const jwtTokenKey = 'MyRocketChatToken';
export const jwtExpiresAtKey = 'MyRocketChatTokenExpiresAt';

export interface IRequestOptions {
  showErrorDialog?: boolean;
}

const handleError = async (response: Response, opts: IRequestOptions) => {
  if (!response.ok && opts.showErrorDialog) {
    let msg;
    try {
      msg = await response.json();

      if (msg.error) {
          message.error(`${ msg.error }; Request ID:  ${ msg.requestId }`);
      } else {
          message.error(`An error occured! Request ID: ${ msg.requestId }`);
      }
    } catch (e) {
      throw response.status;
    }

    if (msg) {
      throw msg;
    } else {
      throw response.status;
    }
  }

  if (!response.ok) {
    let message;
    try {
      message = await response.json();
    } catch (e) {
      throw response.status;
    }

    if (message) {
      throw message;
    } else {
      throw response.status;
    }
  }
}

export const getAuthorizationHeaderIfNeeded = (authenticationNeeded: boolean): { Authorization: string } => {
  if (authenticationNeeded) {

    const token = window.localStorage.getItem(jwtTokenKey);
    const tokenExpireDate = window.localStorage.getItem(jwtExpiresAtKey);

    if (!token || !tokenExpireDate) {
      return {
        Authorization: '',
      };
    }

    const expiresAt = new Date(tokenExpireDate);

    if (new Date().getTime() < expiresAt.getTime()) {
      return {
        Authorization: `Bearer ${token}`,
      };
    }

  }

  return {
    Authorization: '',
  };
}

const get = async <TResult>(url: string, authenticated = false, opts: IRequestOptions = {}): Promise<TResult> => {
  const config: RequestInit = {
    headers: {
      'Referer': 'https://marketplace.rocket.chat/',
      'X-Requested-With': 'Marketplace.Rocket.Chat',
      ...getAuthorizationHeaderIfNeeded(authenticated),
    }
  }

  const response = await fetch(BASE_URL + url, config);

  await handleError(response, opts)

  return response.json()
}

const post = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts: IRequestOptions = {}): Promise<TResult> => {
  const config: RequestInit = {
    method: 'POST',
    headers: {
      ...getAuthorizationHeaderIfNeeded(authenticated),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }

  const response = await fetch(BASE_URL + url, config)

  await handleError(response, opts)

  return response.json()
}

const put = async <TBody, TResult>(url: string, body: TBody, authenticated = false, opts: IRequestOptions = {}): Promise<TResult> => {
  const config: RequestInit = {
    method: 'PUT',
    headers: {
      ...getAuthorizationHeaderIfNeeded(authenticated),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }

  const response = await fetch(BASE_URL + url, config);

  await handleError(response, opts);

  return response.json();
}

const del = async <TResult>(url: string, authenticated = false, opts: IRequestOptions = {}): Promise<TResult> => {
  const config: RequestInit = {
    method: 'DELETE',
    headers: {
      ...getAuthorizationHeaderIfNeeded(authenticated),
      'Content-Type': 'application/json',
    }
  }

  const response = await fetch(BASE_URL + url, config);

  await handleError(response, opts);

  return response.json();
}

const postFormData = async <TResult>(url: string, formData: FormData, authenticated = false, opts: IRequestOptions = {}): Promise<TResult> => {
  const config: RequestInit = {
    method: 'POST',
    headers: {
      ...getAuthorizationHeaderIfNeeded(authenticated),
    },
    body: formData,
  }

  const response = await fetch(BASE_URL + url, config);

  await handleError(response, opts);

  return response.json();
}

const putFormData = async <TResult>(url: string, formData: FormData, authenticated = false, opts: IRequestOptions = {}): Promise<TResult> => {
    const config: RequestInit = {
      method: 'PUT',
      headers: {
        ...getAuthorizationHeaderIfNeeded(authenticated),
      },
      body: formData,
    }

    const response = await fetch(BASE_URL + url, config);

    await handleError(response, opts);

    return response.json();
  }

export { get, post, put, postFormData, putFormData, del };
