import React from 'react';
import { Button, Typography } from 'antd';

interface IDiscardChangesConfirmationProps {
    visible: boolean;
    onGoBackClick: () => void;
    onDisregardClick: () => void;
}

export const DiscardChangesConfirmation: React.FC<IDiscardChangesConfirmationProps> = (props) => {
    if (!props.visible) {
        return null;
    }

    return (
        <React.Fragment>
            <div
                style={{
                    position: 'absolute',
                    top: '55px',
                    left: '0px',
                    backgroundColor: 'white',
                    width: '100%',
                    height: 'calc(100% - 112px)',
                    padding: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    zIndex: 10000,
                }}
            >
                <Typography.Title level={3}>Disregard changes?</Typography.Title>
                <Typography.Paragraph>Are you sure you want to cancel?<br />All of the changes will be lost.</Typography.Paragraph>
            </div>

            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    zIndex: 10000,
                }}
            >
                <Button onClick={props.onGoBackClick} style={{ flex: 1, marginRight: '8px' }}>Go back</Button>
                <Button type="danger" onClick={props.onDisregardClick} style={{ flex: 1 }}>Yes, disregard</Button>
            </div>
        </React.Fragment>
    );
}
