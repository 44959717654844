import React from 'react';

import { IPrice } from 'models/appPrice';

interface ISubscriptionNameProps {
    price: IPrice;
    isActive?: boolean;
}

export const SubscriptionName: React.FC<ISubscriptionNameProps> = (props) => {
    const items: string[] = [`${ props.price.name ? props.price.name : 'Subscription' }${ props.isActive ? ` (Active)` : '' }`];

    if (props.price.monthly.enabled) {
        items.push(`$${ props.price.monthly.price } monthly`);
    }

    if (props.price.yearly.enabled) {
        items.push(`$${ props.price.yearly.price } yearly`);
    }

    if (props.price.trialEnabled) {
        items.push(`${ props.price.trialDuration } day trial`);
    }

    return (
        <span>{ items.join(' · ') }</span>
    );
};
