import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Result, Button } from 'antd';
import queryString from 'query-string';

import { jwtTokenKey, jwtExpiresAtKey } from 'api';
import { exchangeCodeForToken } from 'api/oauth';
import { getCloudOAuthInfo } from 'api/misc';

import Loading from 'containers/Loading';

interface IOAuthCallbackViewProps extends RouteComponentProps {}

interface IOAuthCallbackViewState {
    invalid: boolean;
}

class OAuthCallbackViewBase extends React.PureComponent<IOAuthCallbackViewProps, IOAuthCallbackViewState> {
    state: Readonly<IOAuthCallbackViewState> = {
        invalid: false,
    };

    async componentDidMount() {
        try {
            const cloudOAuth = await getCloudOAuthInfo();
            const query = queryString.parse(window.location.search);
            const oauthState = window.localStorage.getItem(query.state as string);

            if (!oauthState || oauthState.length === 0) {
                this.setState({ invalid: true });
                return;
            }

            const resp = await exchangeCodeForToken(query.code as string, `${cloudOAuth.marketplaceUrl}/oauth/callback`);
            window.localStorage.setItem(jwtTokenKey, resp.access_token);

            const now = new Date();
            window.localStorage.setItem(jwtExpiresAtKey, new Date(now.setSeconds(now.getSeconds() + resp.expires_in)).toJSON());
            window.localStorage.removeItem(query.state as string);

            window.location.href = oauthState;
        } catch (e) {
            console.error('failed to exchange the code for token:', e);
            this.setState({ invalid: true });
        }
    }

    onGoHomeClick = () => {
        this.props.history.push('/publisher/apps');
    }

    render() {
        if (this.state.invalid) {
            return (
                <Result
                    status="403"
                    title="Invalid oAuth state while attempting to authorize you."
                    extra={<Button type="primary" onClick={this.onGoHomeClick}>Go Home</Button>}
                />
            );
        }

        return (
            <Loading />
        );
    }
}

export const OAuthCallbackView = withRouter(OAuthCallbackViewBase);
