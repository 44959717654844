import * as api from 'api';

import { AppStatus, IAppInfo } from 'models/appInfo';
import { IAppOverview, IPublisherAppOverview } from 'models/appOverview';
import { IAppStatistics } from 'models/appStatus';
import { IAppCategory } from 'models/category';
import { IJob } from 'models/compiler/job';
import { IPublisherSetAppInfoPayload, IPublisherSetAppUpdateChangelogsPayload, IPublisherUpdateAppStatus, IPublisherUpdateAppVersionDetailsPayload } from 'models/payloads/publisher';
import { IScreenshot } from 'models/screenshot';
import { IPrice } from 'models/appPrice';

export const getApps = () => api.get<IAppOverview[]>('/publisher/apps', true);
export const postNewApp = (data: FormData) => api.postFormData<IPublisherAppOverview>('/publisher/apps', data, true);
export const getApp = (id: string) => api.get<IPublisherAppOverview>(`/publisher/apps/${id}`, true);
export const getAppVersions = (id: string) => api.get<IAppInfo[]>(`/publisher/apps/${id}/all`, true);
export const getAppVersion = (appId: string, versionInternalId: string) => api.get<IPublisherAppOverview>(`/publisher/apps/${ appId }/info/${ versionInternalId }`, true);
export const getAppStats = (id: string) => api.get<IAppStatistics>(`/publisher/apps/${id}/stats`, true);
export const getAppJobs = (id: string) => api.get<IJob[]>(`/publisher/apps/${ id }/jobs`, true);
export const getAppJobByID = (appId: string, jobId: string) => api.get<IJob>(`/publisher/apps/${ appId }/jobs/${ jobId }`, true);
export const getAppScreenshots = (id: string) => api.get<IScreenshot[]>(`/apps/${ id }/screenshots`, true);
export const getPublisherAppScreenshots = (appId: string, versionInternalId: string) => api.get<IScreenshot[]>(`/publisher/apps/${ appId }/info/${versionInternalId}/screenshots`, true);
export const removeAppScreenshot = (appId: string, screenshotId: string) => api.del<void>(`/apps/${ appId }/screenshots/${ screenshotId }`, true);
export const getAppPrices = (appId: string) => api.get<IPrice[]>(`/publisher/apps/${ appId }/prices`, true);
export const createAppPrice = (appId: string, price: Partial<IPrice>) => api.post<Partial<IPrice>, IPrice>(`/publisher/apps/${ appId }/prices`, price, true);
export const getAppPrice = (appId: string, priceId: string) => api.get<IPrice>(`/publisher/apps/${ appId }/prices/${ priceId }`, true);

export const getCategories = () => api.get<IAppCategory[]>('/categories');

export const publishNewApp = (data: FormData) => api.postFormData<IAppOverview>('/apps', data, true);
export const updateApp = (id: string, data: FormData) => api.postFormData<IAppInfo>(`/apps/${id}`, data, true);
export const startAppUpdate = (id: string, data: FormData) => api.putFormData<IPublisherAppOverview>(`/apps/${ id }`, data, true);
export const updateAppVersionDetails = (appId: string, versionInternalId: string, payload: IPublisherUpdateAppVersionDetailsPayload) => api.post<IPublisherUpdateAppVersionDetailsPayload, IPublisherAppOverview>(`/publisher/apps/${ appId }/info/${ versionInternalId }/details`, payload, true);
export const uploadAppVersionScreenshots = (appId: string, versionInternalId: string, screenshots: FormData) => api.postFormData<IScreenshot[]>(`/publisher/apps/${ appId }/info/${ versionInternalId }/screenshots`, screenshots, true);
export const setAppVersionInfo = (appId: string, versionInternalId: string, payload: IPublisherSetAppInfoPayload) => api.post<IPublisherSetAppInfoPayload, IPublisherAppOverview>(`/publisher/apps/${ appId }/info/${ versionInternalId }/info`, payload, true);
export const setAppUpdateChangelogs = (appId: string, versionInternalId: string, payload: IPublisherSetAppUpdateChangelogsPayload) => api.post<IPublisherSetAppUpdateChangelogsPayload, IPublisherAppOverview>(`/publisher/apps/${ appId }/info/${ versionInternalId }/changes`, payload, true);
export const submitAppVersion = (appId: string, versionInternalId: string) => api.post<undefined, IPublisherAppOverview>(`/publisher/apps/${ appId }/info/${ versionInternalId }/submit`, undefined, true);

export const publishNewBundle = (data: FormData) => api.postFormData<IAppOverview>('/publisher/bundles', data, true);
export const getBundles = () => api.get<IAppOverview[]>('/publisher/bundles', true);
export const getBundledApps = (bundleId: string) => api.get<IAppOverview[]>(`/publisher/bundles/${ bundleId }/apps`, true);

export const postAdminAppStatusUpdate = (appId: string, appVersionId: string, status: AppStatus, data: Partial<IPublisherUpdateAppStatus>) => {
    data.status = status;

    return api.post<Partial<IPublisherUpdateAppStatus>, void>(`/publisher/apps/${ appId }/status/${ appVersionId }`, data, true);
};

export const latestEngineVersion = () => api.get<{ version: string }>('/engine/version');
