import React from 'react';
import { Drawer, Row, Col, Form, Divider, Typography, Button, Input, InputNumber, Tooltip, Icon, Checkbox, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IPublisherAppOverview } from 'models/appOverview';
import { IPrice } from 'models/appPrice';
import { AppPurchaseType } from 'models/purchaseRecord';

import { usePermissions } from 'components/access/hasPermissionHOC';
import { DiscardChangesConfirmation } from 'components/discardChanges';

import { createAppPrice } from 'api/apps';

interface IFormValues {
    planName: string;
    monthlyEnabled: boolean;
    monthlyPrice: number;
    yearlyEnabled: boolean;
    yearlyPrice: number;
    trialEnabled: boolean;
    trialDays: number;
    referenceEnabled?: boolean;
    referenceId?: string;
}

interface INewPriceSubscriptionDrawerProps extends FormComponentProps<IFormValues> {
    visible: boolean;
    app?: IPublisherAppOverview;
    close: (saved: boolean) => Promise<void>;
    hasPermission: (feature: string, permission: string, group?: string) => boolean;
}

interface INewPriceSubscriptionDrawerState {
    saving: boolean;
    displayDisregardConfirmation: boolean;
}

class NewPriceSubscriptionDrawerBase extends React.PureComponent<INewPriceSubscriptionDrawerProps, INewPriceSubscriptionDrawerState> {
    state: Readonly<INewPriceSubscriptionDrawerState> = {
        saving: false,
        displayDisregardConfirmation: false,
    };

    onClose = () => {
        if (this.props.form.isFieldsTouched()) {
            this.setState({ displayDisregardConfirmation: true });
            return;
        }

        this.props.close(false);
        this.props.form.resetFields();
    }

    onDisregardGoBackClick = () => {
        this.setState({ displayDisregardConfirmation: false });
    }

    onDisregardDeletePlanClick = () => {
        this.props.close(false);
        this.props.form.resetFields();

        this.setState({ displayDisregardConfirmation: false });
    }

    onSaveClick = () => {
        this.props.form.validateFields((err, values: IFormValues) => {
            console.log('values:', values, 'error:', err);
            if (err) {
                return;
            }

            this.setState({ saving: true }, async () => {
                try {
                    const price: Partial<IPrice> = {
                        type: AppPurchaseType.Subscription,
                        name: values.planName,
                        monthly: {
                            enabled: values.monthlyEnabled,
                            price: typeof values.monthlyPrice === 'number' ? values.monthlyPrice : 0,
                            stripePriceId: '',
                            isSeatBased: false,
                            tiers: [],
                        },
                        yearly: {
                            enabled: values.yearlyEnabled,
                            price: typeof values.yearlyPrice === 'number' ? values.yearlyPrice : 0,
                            stripePriceId: '',
                            isSeatBased: false,
                            tiers: [],
                        },
                        trialEnabled: values.trialEnabled,
                        trialDuration: values.trialDays,
                        referenceEnabled: typeof values.referenceEnabled === 'boolean' ? values.referenceEnabled : false,
                        referenceId: values.referenceId,
                    };

                    const resultingPrice = await createAppPrice(this.props.app!.appId, price);

                    console.log('price created!:', resultingPrice);
                    notification.success({ message: 'New Price Created Successfully', description: `The new price for ${ this.props.app!.latest.name } was created successfully.` });

                    await this.props.close(true);
                    this.props.form.resetFields();
                    this.setState({ saving: false });
                } catch (e) {
                    console.warn('failed to save the new pricing:', e);
                    if (e && typeof e === 'object') {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        notification.error({ message: 'Save Failure', description: `Failed to save the new pricing plan: ${ (e as any).error }` })
                    }

                    this.setState({ saving: false });
                }
            });

            console.log('values:', values);
        });
    }

    get planNameLabel() {
        return (
            <span>
                Plan name&nbsp;
                <Tooltip title="Not displayed on marketplace listing"><Icon type="exclamation-circle" /></Tooltip>
            </span>
        );
    }

    get planNameInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={this.planNameLabel}>
                {getFieldDecorator('planName', {
                    rules: [{ required: true, message: 'Plan name is required.' }],
                })(
                    <Input size="large" />
                )}
            </Form.Item>
        );
    }

    get monthlyInputs() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const monthlyEnabled = getFieldValue('monthlyEnabled');

        return (
            <React.Fragment>
                <Row>
                    <Col span={24}>
                        {getFieldDecorator('monthlyEnabled', {
                            initialValue: true,
                            valuePropName: 'checked'
                        })(
                            <Checkbox>Monthly</Checkbox>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Price (USD)">
                            {getFieldDecorator('monthlyPrice', {
                                rules: [
                                    { required: monthlyEnabled, message: 'A monthly price is required.' },
                                    { type: 'number', min: 0, message: 'Monthly price can not be negative.' }
                                ],
                            })(
                                <InputNumber
                                    min={0}
                                    inputMode="numeric"
                                    disabled={!monthlyEnabled}
                                    style={{ width: '100%' }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    get yearlyInputs() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const yearlyEnabled = getFieldValue('yearlyEnabled');

        return (
            <React.Fragment>
                <Row>
                    <Col span={24}>
                        {getFieldDecorator('yearlyEnabled', {
                            initialValue: true,
                            valuePropName: 'checked'
                        })(
                            <Checkbox>Yearly</Checkbox>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Price (USD)">
                            {getFieldDecorator('yearlyPrice', {
                                rules: [
                                    { required: yearlyEnabled, message: 'A yearly price is required.' },
                                    { type: 'number', min: 0, message: 'Yearly price can not be negative.' },
                                ],
                            })(
                                <InputNumber
                                    min={0}
                                    inputMode="numeric"
                                    disabled={!yearlyEnabled}
                                    style={{ width: '100%' }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    get trialInputs() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const trialEnabled = getFieldValue('trialEnabled');

        return (
            <React.Fragment>
                <Row>
                    <Col span={24}>
                        {getFieldDecorator('trialEnabled', {
                            initialValue: false,
                            valuePropName: 'checked'
                        })(
                            <Checkbox>Trial (days)</Checkbox>
                        )}
                    </Col>
                </Row>
                <Row style={{ marginTop: '16px' }}>
                    <Col span={24}>
                        {getFieldDecorator('trialDays', {
                            rules: [
                                { required: trialEnabled, message: 'Trial duration is required.' },
                                { type: 'number', min: 1, message: 'Trial days must be at least 1 when enabled.' },
                            ],
                        })(
                            <InputNumber
                                min={1}
                                inputMode="numeric"
                                disabled={!trialEnabled}
                                style={{ width: '100%' }}
                            />
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Drawer
                title="New pricing plan"
                visible={this.props.visible}
                width={385}
                onClose={this.onClose}
            >
                <Typography.Paragraph>Create a new subscription pricing plan{ this.props.app ? ` for ${ this.props.app.latest.name }.` : '.'}</Typography.Paragraph>
                <Form hideRequiredMark colon={false}>
                    {this.planNameInput}

                    <Divider />

                    <Row gutter={24}>
                        <Col span={12}>{ this.monthlyInputs }</Col>
                        <Col span={12}>{ this.yearlyInputs }</Col>
                    </Row>

                    <Divider />

                    <Row gutter={24}>
                        <Col span={12}>{ this.trialInputs }</Col>
                    </Row>

                </Form>

                <DiscardChangesConfirmation
                    visible={this.state.displayDisregardConfirmation}
                    onGoBackClick={this.onDisregardGoBackClick}
                    onDisregardClick={this.onDisregardDeletePlanClick}
                />

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                        display: 'flex',
                        justifyContent: 'space-evenly',
                    }}
                >
                    <Button onClick={this.onClose} disabled={this.state.saving} style={{ flex: 1, marginRight: '8px' }}>Cancel</Button>
                    <Button type="primary" onClick={this.onSaveClick} disabled={this.state.saving} loading={this.state.saving} style={{ flex: 1 }}>Save plan</Button>
                </div>
            </Drawer>
        );
    }
}

const NewPriceSubscriptionDrawerWithForm = Form.create<INewPriceSubscriptionDrawerProps>()(NewPriceSubscriptionDrawerBase);

 export const NewPriceSubscriptionDrawer: React.FC<Omit<INewPriceSubscriptionDrawerProps, 'form' | 'hasPermission' | 'children'>> = (props) => {
    const hasPermission = usePermissions();

    return (
        <NewPriceSubscriptionDrawerWithForm hasPermission={hasPermission} visible={props.visible} app={props.app} close={props.close} />
    );
};
