import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import qs from 'query-string';

import { exchangeStripeCode } from 'api/oauth';
import Loading from 'containers/Loading';

interface IStripeOAuthCallbackViewProps extends RouteComponentProps { }

interface IStripeOAuthCallbackViewState {
    invalid: boolean;
}

class StripeOAuthCallbackViewBase extends React.PureComponent<IStripeOAuthCallbackViewProps, IStripeOAuthCallbackViewState> {
    state: Readonly<IStripeOAuthCallbackViewState> = {
        invalid: false,
    }

    async componentDidMount() {
        const stored = window.localStorage.getItem('rcMarketplaceStripe');
        if (!stored) {
            this.setState({ invalid: true });
            return;
        }

        const stdata = JSON.parse(stored);
        const parsed = qs.parse(window.location.search);

        if (stdata.state !== parsed.state) {
            this.setState({ invalid: true });
            return;
        }

        try {
            await exchangeStripeCode(parsed.code as string);
            window.localStorage.removeItem('rcMarketplaceStripe');
        } catch {
            this.setState({ invalid: true });
            return;
        }

        this.props.history.push('/publisher/info');
    }

    onGoHomeClick = () => {
        this.props.history.push('/publisher/info');
    }

    render() {
        if (this.state.invalid) {
            return (
                <Result
                    status="500"
                    title="Internal Error (500)"
                    subTitle="We apologize, something wrong happened on the server side."
                    extra={<Button type="primary" onClick={this.onGoHomeClick}>Go Home</Button>}
                />
            );
        }

        return (
            <Loading />
        );
    }
}

export const StripeOAuthCallbackView = withRouter(StripeOAuthCallbackViewBase);
