import * as api from 'api';
import { ILookup } from 'models/common/lookup';

import { IDeveloper } from 'models/developer';
import { IPublisherAddUserPayload, IPublisherChangeUserRolePayload, IPublisherCloudAssociationPayload } from 'models/payloads/publisher';
import { IPublisher, IPublisherStripeDashboardLink } from 'models/publisher';
import { IPurchaseRecord } from 'models/purchaseRecord';
import { IRestResponse } from 'models/results/rest';

export const getPublisher = () => api.get<IPublisher>('/publisher', true);
export const getPublisherDevelopers = () => api.get<IDeveloper[]>('/publisher/developers', true);
export const getPublisherUsers = () => api.get<ILookup[]>('/publisher/users', true);
export const updatePublisherInfo = (publisher: IPublisher) => api.put<IPublisher, IPublisher>('/publisher', publisher, true);
export const associateCloudAccount = (data: IPublisherCloudAssociationPayload) => api.post<IPublisherCloudAssociationPayload, { publisher: IPublisher, developer: IDeveloper }>('/publisher', data, true);
export const isPublisherSignUpEnabled = () => api.get<{ enabled: boolean }>('/misc/supported/signups');
export const retrieveStripeDashboardLink = () => api.get<IPublisherStripeDashboardLink>('/publisher/stripe/dashboard', true);
export const addPublisherUser = (payload: IPublisherAddUserPayload) => api.post<IPublisherAddUserPayload, void>('/publisher/users', payload, true);
export const changePublisherUserRole = (payload: IPublisherChangeUserRolePayload) => api.post<IPublisherChangeUserRolePayload, void>(`/publisher/developers/${payload.developerId}/role`, payload, true);
export const removeDeveloper = (developerId: string) => api.del<void>(`/publisher/developers/${developerId}`, true);

export const getPurchases = (limit = 25, offset = 0) => api.get<IRestResponse<IPurchaseRecord[]>>(`/publisher/purchases?limit=${limit}&offset=${offset}`, true);
