import { IWebhookEvent } from "./webhooks/event";

export enum WebhookActivityStatus {
    Created = 'created',
    InternalError = 'internal-error',
    Delivered = 'delivered',
    Failure = 'failure',
    Timeout = 'timeout',
}

export interface IWebhookActivityLog {
    id: string;
    status: WebhookActivityStatus;
    createdAt: string;
    updatedAt: string;

    event: IWebhookEvent;
    response: IWebhookActivityLogResponse;
}

export interface IWebhookActivityLogResponse {
    statusCode: number;
    response: string;
    responseType: string;
}
