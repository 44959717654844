import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, notification } from 'antd';

import { IAdminAppOverview } from 'models/appOverview';
import { IPublisher } from 'models/publisher';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

import { getAdminBundles } from 'api/admin';

import { AdminAppsTable } from '../AppsTable';

interface IAdminBundlesListViewProps extends RouteComponentProps, IWithPermissionProps { }

interface IAdminBundlesListViewState {
    loading: boolean;
    bundles: IAdminAppOverview[];
    publisher?: IPublisher;
}

class AdminBundlesListViewBase extends React.PureComponent<IAdminBundlesListViewProps, IAdminBundlesListViewState> {
    state: Readonly<IAdminBundlesListViewState> = {
        loading: true,
        bundles: [],
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IAdminBundlesListViewProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:bundles'].canRead) {
            console.log('user can not read the system:bundles');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Bundles. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const bundles = await getAdminBundles();

        this.setState({ bundles, loading: false, });
    }

    render() {
        return (
            <Layout className="applist">
                <Layout.Header className="applist-header">
                    <h2>Bundles</h2>
                </Layout.Header>
                <Layout.Content>
                    <AdminAppsTable
                        type="bundles"
                        onActionTaken={this.loadData}
                        tableProps={{
                            pagination: { hideOnSinglePage: true },
                            loading: this.state.loading,
                            dataSource: this.state.bundles,
                            rowKey: (record) => record.latest.id,
                        }}
                    />
                </Layout.Content>
            </Layout>
        )
    }
}

export const AdminBundlesListView = connectPermissions({ feature: 'system:bundles', system: true })(withRouter(AdminBundlesListViewBase));
