import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Badge, Button, Col, Descriptions, Layout, notification, Row, Spin, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IWebhookEndpoint } from 'models/webhookEndpoint';
import { IWebhookActivityLog, WebhookActivityStatus, IWebhookActivityLogResponse } from 'models/webhookActivityLog';

import { getWebhookActivties, getWebhookById, resendWebhookActivity } from 'api/webhooks';
import { SimpleDateWithTime } from 'components/dates/simpleDate';
import { WebhookEventPayloadViewerModal } from './payloadViewerModal';
import { WebhookEventServerRawResponse } from './rawResponseModal';

interface IWebhookViewProps extends RouteComponentProps<{ id: string }> {}

interface IWebhookViewState {
    loading: boolean;
    loadingActivities: boolean;
    webhook?: IWebhookEndpoint;
    webhookActivties: IWebhookActivityLog[];
    total: number;
    page: number;
    pageSize: number;
    payloadModalVisible: boolean;
    selectedWebhookActivity?: IWebhookActivityLog;
    rawResponseModalVisible: boolean;
    selectedWebhookActivityResponse?: IWebhookActivityLogResponse;
}

class WebhookViewBase extends React.PureComponent<IWebhookViewProps, IWebhookViewState> {
    state: Readonly<IWebhookViewState> = {
        loading: true,
        loadingActivities: true,
        webhookActivties: [],
        total: 0,
        page: 0,
        pageSize: 25,
        payloadModalVisible: false,
        rawResponseModalVisible: false,
    };

    columns: ColumnProps<IWebhookActivityLog>[] = [
        { title: 'Event ID', dataIndex: 'event.id', key: 'eventId' },
        { title: 'Event', dataIndex: 'event.type', key: 'eventType', render: (event: string) =>  <Typography.Text code>{ event }</Typography.Text>},
        { title: 'Status', dataIndex: 'status', key: 'status', className: 'title-caps', render: (value: WebhookActivityStatus) => <Badge status={value === WebhookActivityStatus.Delivered ? 'success' : 'error'} text={value} /> },
        { title: 'Status Code', dataIndex: 'response.statusCode', key: 'statusCode', render: (statusCode: number) => statusCode ? statusCode : '-' },
        { title: 'When', dataIndex: 'createdAt', key: 'createdAt', render: (value: string) => value ? <SimpleDateWithTime date={value} /> : '-' },
        { title: 'Action', key: 'action', render: (nothing: void, log: IWebhookActivityLog) => <Button size="small" onClick={() => this.viewPayload(log)}>View Payload</Button> },
        { title: 'Server response', dataIndex: 'response', key: 'response', render: (response: IWebhookActivityLogResponse) => <Button size="small" onClick={() => this.viewServerRawResponse(response)}>View server Response</Button> },
        { title: 'Resend', dataIndex: 'resent', key: 'resent', render: (resent: boolean, log: IWebhookActivityLog) => resent ? '-' : <Button size="small" onClick={() => this.onResendClick(log)}>Resend</Button> },
    ];

    viewPayload = (activityLog: IWebhookActivityLog) => {
        this.setState({ payloadModalVisible: true, selectedWebhookActivity: activityLog });
    }

    closePayloadModal = () => {
        this.setState({ payloadModalVisible: false, selectedWebhookActivity: undefined });
    }

    viewServerRawResponse = (response: IWebhookActivityLogResponse) => {
        this.setState({ rawResponseModalVisible: true, selectedWebhookActivityResponse: response });
    }

    closeServerRawResponse = () => {
        this.setState({ rawResponseModalVisible: false, selectedWebhookActivityResponse: undefined });
    }

    onResendClick = (activityLog: IWebhookActivityLog) => {
        this.setState({ loading: true }, async () => {
            if (!this.state.webhook) {
                return;
            }

            try {
                await resendWebhookActivity(this.state.webhook.id, activityLog.id);

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                console.warn('failed to load admin app activity logs:', e);

                if (e?.error && e?.code) {
                    notification.error({ message: `${e.error} (${e.code})` });

                    return;
                }

                notification.error({ message: 'The Marketplace Server failed to respond to the resend request.' });
            } finally {
                this.setState({ loading: false });
                this.fetchActivities();
            }
        });
    }

    componentDidMount() {
        this.loadInformation();
    }

    loadInformation = async () => {
        const { id } = this.props.match.params;

        const webhook = await getWebhookById(id);

        this.setState({ loading: false, webhook }, this.fetchActivities);
    }

    onPaginationChange = (page: number, pageSize?: number) => {
        this.setState({ page, pageSize: pageSize ? pageSize : this.state.pageSize }, this.fetchActivities);
    };

    fetchActivities = () => {
        if (!this.state.webhook) {
            return;
        }

        this.setState({ loadingActivities: true }, async () => {
            try {
                let page = this.state.page;
                if (page > 0) {
                    page = page - 1;
                }

                const res = await getWebhookActivties(this.props.match.params.id, this.state.pageSize, page*this.state.pageSize);
                if (!res.meta) {
                    throw new Error('invalid response');
                }

                this.setState((prevState) => {
                    return {
                        ...prevState,
                        total: res.meta?.total || 0,
                        webhookActivties: res.data,
                    };
                });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                console.warn('failed to load admin app activity logs:', e);

                if (e?.error && e?.code) {
                    notification.error({ message: `${e.error} (${e.code})` });

                    return;
                }

                notification.error({ message: 'The Marketplace Server did not respond when trying to list the webhook activity logs' });
            } finally {
                this.setState({ loadingActivities: false });
            }
        });
    }

    get webhookHeader() {
        const { webhook } = this.state;
        if (!webhook) {
            return null;
        }

        return (
            <React.Fragment>
                <Row>
                    <Col span={8}>
                        <Typography.Title level={2}>Webhook Endpoint: {webhook.label}</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Descriptions bordered>
                            <Descriptions.Item key="status" label="Status" span={1}><Badge status={webhook.enabled ? 'processing' : 'error'} text={webhook.enabled ? 'Enabled' : 'Disabled'} /></Descriptions.Item>
                            <Descriptions.Item key="url" label="Url" span={2}>{webhook.url}</Descriptions.Item>
                            <Descriptions.Item key="for" label="Events" span={3}>{Array.isArray(webhook.for) ? webhook.for.join(', ') : '-'}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    render() {
        return (
            <Spin size="large" spinning={this.state.loading}>
                <Layout>
                    {this.webhookHeader}

                    <Layout.Content>
                        <Row>
                            <Col span={4}>
                                <Typography.Title level={3}>Activity Logs</Typography.Title>
                            </Col>

                            <Col span={24}>
                                <Table
                                    columns={this.columns}
                                    loading={this.state.loadingActivities}
                                    dataSource={this.state.webhookActivties}
                                    rowKey="id"
                                    pagination={{
                                        hideOnSinglePage: true,
                                        pageSize: this.state.pageSize,
                                        total: this.state.total,
                                        current: this.state.page,
                                        showSizeChanger: true,
                                        onChange: this.onPaginationChange,
                                        onShowSizeChange: this.onPaginationChange,
                                    }}
                                />
                            </Col>
                        </Row>
                    </Layout.Content>
                </Layout>

                <WebhookEventPayloadViewerModal
                    visible={this.state.payloadModalVisible}
                    activityLog={this.state.selectedWebhookActivity}
                    close={this.closePayloadModal}
                />

                <WebhookEventServerRawResponse
                    visible={this.state.rawResponseModalVisible}
                    response={this.state.selectedWebhookActivityResponse}
                    close={this.closeServerRawResponse}
                />
            </Spin>
        );
    }
}

export const WebhookView = withRouter(WebhookViewBase);
