import React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { Avatar, Table, Menu, Icon, Dropdown, Badge, Tooltip, Tag, Modal, Input, message } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { ClickParam } from 'antd/lib/menu';

import { IAppOverview, ISimpleBundleInfo } from 'models/appOverview';
import { AppStatus, IAppInfo } from 'models/appInfo';
import { postAdminAppStatusUpdate } from 'api/apps';

import { DiagnosticsViewerModal } from 'components/jobs/diagnosticsViewer';
import { statusToColor, capitalizeFirstLetter } from 'utils/appStatus';

interface IAppsTableProps extends RouteComponentProps {
    type: 'versions' | 'apps' | 'bundle';
    tableProps: TableProps<IAppOverview>;
    canUpdate: boolean;
    onActionTaken?: () => void;
}

interface IAppsTableState {
    diagnosticsViewerVisible: boolean;
    selectedApp?: IAppInfo;
}

class AppsTableBase extends React.PureComponent<IAppsTableProps, IAppsTableState> {
	state: Readonly<IAppsTableState> = { diagnosticsViewerVisible: false, selectedApp: undefined };

	renderBundleBadges = (bundles?: ISimpleBundleInfo[]) => {
		if (!bundles || bundles.length === 0) {
			return null;
		}

		return bundles.map((b) =>
			<Tag key={"bundleTag-" + b.bundleId} color="geekblue">
				<Link to={{ pathname: `/publisher/bundles/${ b.bundleId }` }}>{b.bundleName}</Link>
			</Tag>
		);
	};

	renderName = (name: void, app: IAppOverview) => (
		<div className='app-td-wrapper'>
			<Avatar className='app-avatar' size='small' shape='square'
				src={`data:image/jpg;base64,${app.latest.iconFileData}`} />
			<span className='app-name table-column-name'> {app.latest.name} {this.renderBundleBadges(app.bundledIn)}</span>
		</div>
	);

	renderStatus = (status: void, app: IAppOverview) => (
		<div className='app-status'>
			<Badge color={statusToColor[app.latest.status]} text={capitalizeFirstLetter(app.latest.status)} />
			{
				app.latest.status === 'rejected' ?
					<Tooltip placement="top" arrowPointAtCenter title={app.latest.rejectionNote}>
						&nbsp;<Icon type="alert" theme="twoTone" twoToneColor="#f00" />
					</Tooltip>
				: null
			}
			{
				app.latest.status === 'reviewed' || app.latest.status === 'compiler-error' ?
					<Tooltip placement="top" arrowPointAtCenter title={app.latest.reviewedNote}>
						&nbsp;<Icon type="alert" theme="twoTone" twoToneColor="#ff9d00" />
					</Tooltip>
				: null
			}
		</div>
	)

	renderVersion = (version: void, app: IAppOverview) => {
		if (!app.latest.changesNote) {
			return <span>v{app.latest.version}</span>;
		}

		return (
			<Tooltip placement="top" arrowPointAtCenter title={app.latest.changesNote}>
				<span>v{app.latest.version}</span>&nbsp;<Icon type="info-circle" theme="twoTone" twoToneColor="#cccc00" />
			</Tooltip>
		);
	}

	onRejectClick = (app: IAppOverview): Promise<{ reject: boolean, reason?: string }> => {
		let value = '';

		return new Promise((resolve) => {
			Modal.confirm({
				icon: <Icon type="warning" />,
				title: `Reject ${app.latest.name} v${app.latest.version}?`,
				content: (
					<div>
						<p>Are you sure you want to reject {app.latest.name} v{app.latest.version}? Rejecting the App prevents the you from making it public and replacing this specific version.</p>
						<Input.TextArea rows={4} placeholder="Rejection reason" onChange={(e) => value = e.target.value} />
					</div>
				),
				onOk() {
					resolve({ reject: true, reason: value });
				},
				onCancel() {
					resolve({ reject: false });
				},
			});
		});
	}

	onActionClick = (app: IAppOverview) => async ({ key }: ClickParam) => {
		const appId = app.latest.id;
		const versionId = app.latest.internalId!;

		switch (key) {
			// info and uploadUpdate are only on the apps list page, not the app information page
			case 'info':
				if (app.isBundle) {
					this.props.history.push({ pathname: `/publisher/bundles/${ app.latest.id }` });
					return;
				}

				this.props.history.push({ pathname: `/publisher/apps/${ app.latest.id }` });
				return;
			case 'uploadUpdate':
				this.props.history.push({ pathname: `/publisher/update-extended/app/${ app.appId }` });
				return;
			case 'reject': {
				const rejectResult = await this.onRejectClick(app);
				if (rejectResult.reject) {
					await postAdminAppStatusUpdate(appId, versionId, AppStatus.Rejected, { appId, versionId, rejectionReason: rejectResult.reason });
					message.success(`${app.latest.name} v${app.latest.version} is rejected!`);
				}
				break;
            }
			case 'uploadRevision':
                this.props.history.push({ pathname: `/publisher/update-extended/app/${ app.appId }/${ app.latest.internalId }` });
				return;
			case 'publish':
				message.info(`Publishing the app ${ app.latest.name } v${ app.latest.version }`);
				await postAdminAppStatusUpdate(appId, versionId, AppStatus.Published, { appId, versionId });
				message.info('Published!');
				break;
			case 'unpublish':
				message.info(`Unpublishing the app ${ app.latest.name } v${ app.latest.version }`);
				await postAdminAppStatusUpdate(appId, versionId, AppStatus.Unpublished, { appId, versionId });
				message.info('Unpublished!');
				break;
			case 'compiler-error-view':
				this.setState({ diagnosticsViewerVisible: true, selectedApp: app.latest });
				return;
            case 'edit':
                this.props.history.push(`/publisher/new-extended/app/${ app.appId }/${ app.latest.internalId! }/1`);
                return;
            case 'edit-details':
                this.props.history.push(`/publisher/edit-details/app/${ app.appId }/${ app.latest.internalId! }`);
                return
			default:
				message.info(`Click on item ${key}`);
				break;
		}

		this.props.onActionTaken && this.props.onActionTaken();
	};

	onDiagnosticsViewerClose = () => {
		this.setState({ diagnosticsViewerVisible: false, selectedApp: undefined });
	}

	getAppsListMenuItems = (app: IAppOverview) => (
		<Menu onClick={this.onActionClick(app)}>
			<Menu.Item key="info">
				<Icon type="info-circle" /> View Details
			</Menu.Item>
			{!app.isBundle ?
				<Menu.Item key="uploadUpdate" disabled={!this.props.canUpdate}>
					<Icon type="cloud-upload" /> Upload Update
				</Menu.Item>
			: null}
		</Menu>
	)

	getAppVersionMenuItems = (app: IAppOverview) => {
		if (app.latest.status === 'compiling') {
			return null;
		}

		return (
			<Menu onClick={this.onActionClick(app)}>
                {app.latest.status === 'draft' ?
                    <Menu.Item key="edit" disabled={!this.props.canUpdate}>
                        <Icon type="edit" /> Edit
                    </Menu.Item>
                : null}
				{app.latest.status === 'submitted' || app.latest.status === 'author-approved' || app.latest.status === 'compiled' ?
					<Menu.Item key="reject" disabled={!this.props.canUpdate}>
						<Icon type="stop" /> Reject
					</Menu.Item>
				: null}
				{(app.latest.status === 'reviewed' || app.latest.status === 'compiler-error') && !app.isBundle ?
					<Menu.Item key="uploadRevision" disabled={!this.props.canUpdate}>
						<Icon type="cloud-upload" /> Upload Revision
					</Menu.Item>
				: null}
				{app.latest.status === 'rejected' ?
					<Menu.Item key="nothing" disabled>
						<Icon type="safety" /> Publish
					</Menu.Item>
				: null}
				{app.latest.status === 'approved' || app.latest.status === 'unpublished' ?
					<Menu.Item key="publish" disabled={!this.props.canUpdate}>
						<Icon type="safety" /> Publish
					</Menu.Item>
				: null}
				{app.latest.status === 'published' ?
					<Menu.Item key="unpublish" disabled={!this.props.canUpdate}>
						<Icon type="eye-invisible" /> Unpublish
					</Menu.Item>
				: null}

                {app.latest.status !== 'draft' ?
					<Menu.Item key="edit-details" disabled={!this.props.canUpdate}>
						<Icon type="edit" /> Edit info
					</Menu.Item>
				: null}

				{!app.isBundle && app.latest.status === 'compiler-error' ?
					<Menu.Item key="compiler-error-view">
						<Icon type="monitor" /> View Diagnostics
					</Menu.Item>
				: null}
			</Menu>
		);
	}

	renderActions = (action: void, app: IAppOverview) => {
		let overlay;

		switch(this.props.type) {
			case 'apps':
			case 'bundle':
				overlay = this.getAppsListMenuItems(app);
				break;
			default:
				overlay = this.getAppVersionMenuItems(app);
				break;
		}

		if (!overlay) {
			return null;
		}

		return (
			<Dropdown overlay={overlay} trigger={['hover']}>
				<span className="ant-dropdown-link">
					Actions <Icon type="down" />
				</span>
			</Dropdown>
		);
	}

	//#region columns
	versionsColumns: ColumnProps<IAppOverview>[] = [
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'name',
			sorter: (a, b) => a.latest.name.localeCompare(b.latest.name),
			render: this.renderName,
			width: '40%'
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			sorter: (a, b) => a.latest.status.localeCompare(b.latest.status),
			render: this.renderStatus,
			width: '15%'
		},
		{
			title: 'Version',
			key: 'version',
			dataIndex: 'version',
			sorter: (a, b) => a.latest.version.localeCompare(b.latest.version),
			render: this.renderVersion,
			width: '10%'
		},
		{
			title: 'Modified At', key: 'modifiedDate',
			dataIndex: 'latest.modifiedDate', width: '15%',
			render: (value: string) => new Date(value).toLocaleDateString(),
			sorter: (a, b) => {
				const ad = new Date(a.latest.modifiedDate);
				const bd = new Date(b.latest.modifiedDate);

				return ad.getTime() - bd.getTime();
			},
		},
		{
			title: 'Actions', key: 'actions', width: '10%',
			render: this.renderActions,
		}
	];

	columns: ColumnProps<IAppOverview>[] = [
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'name',
			sorter: (a, b) => a.latest.name.localeCompare(b.latest.name),
			render: this.renderName,
			width: '50%'
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			sorter: (a, b) => a.latest.status.localeCompare(b.latest.status),
			render: this.renderStatus,
			width: '15%'
		},
		{
			title: 'Version',
			key: 'version',
			dataIndex: 'version',
			sorter: (a, b) => a.latest.version.localeCompare(b.latest.version),
			render: this.renderVersion,
			width: '10%'
		},
		{
			title: 'Modified At', key: 'modifiedAt', dataIndex: 'modifiedAt', width: '15%',
			render: (value: string) => new Date(value).toLocaleDateString(),
			sorter: (a, b) => {
				const ad = new Date(a.modifiedAt);
				const bd = new Date(b.modifiedAt);

				return ad.getTime() - bd.getTime();
			},
		},
		{
			title: 'Actions', key: 'actions', width: '10%',
			render: this.renderActions,
		}
	]
	//#endregion columns

	render() {
		return (
			<React.Fragment>
				<Table<IAppOverview>
					{...this.props.tableProps}
					columns={this.props.type === 'versions' ? this.versionsColumns : this.columns}
					scroll={{ x: 100 }}
					className="apps-table"
				/>

				<DiagnosticsViewerModal
					visible={this.state.diagnosticsViewerVisible}
					appInfo={this.state.selectedApp}
					close={this.onDiagnosticsViewerClose}
				/>
			</React.Fragment>
		);
	}
}

export default withRouter(AppsTableBase);
