import React from 'react';
import { Drawer, Row, Col, Form, Alert, Divider, Typography, Select, Button, InputNumber, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { DiscardChangesConfirmation } from 'components/discardChanges';

import { IPublisherAppOverview } from 'models/appOverview';
import { AppPurchaseType } from 'models/purchaseRecord';
import { IPrice } from 'models/appPrice';

import { createAppPrice } from 'api/apps';

import { NewPriceSubscriptionDrawer } from './newPriceSubscriptionDrawer';
import { ChangePurchaseTypeToFreeConfirmation } from './changeTypeToFree';
import { SubscriptionName } from './subscriptionName';

interface IFormValues {
    purchaseType: AppPurchaseType;
    pricingPlanId: string;
    price: number;
}

interface IChangePriceDrawerProps extends FormComponentProps<IFormValues> {
    prices: IPrice[];
    app?: IPublisherAppOverview;
    isVisible: boolean;
    close: (changed: boolean) => Promise<void>;
}

interface IChangePriceDrawerState {
    newPricingPlanVisible: boolean;
    displayConfirmDisregard: boolean;
    displayChangeToFree: boolean;
    toFreeConfirmed: boolean;
    changed: boolean;
    saving: boolean;
}

class ChangePriceDrawerBase extends React.PureComponent<IChangePriceDrawerProps, IChangePriceDrawerState> {
    state: Readonly<IChangePriceDrawerState> = {
        newPricingPlanVisible: false,
        displayConfirmDisregard: false,
        displayChangeToFree: false,
        toFreeConfirmed: false,
        changed: false,
        saving: false,
    };

    onClose = () => {
        if (this.state.changed) {
            this.setState({ displayConfirmDisregard: true });
            return;
        }

        this.props.close(false);
        this.props.form.resetFields();
        this.setState({ displayConfirmDisregard: false, changed: false, saving: false });
    }

    onSaveClick = () => {
        if (!this.state.changed || !this.props.app) {
            return;
        }

        if (this.isMovingToFreeFromSubscription() && !this.state.toFreeConfirmed) {
            this.setState({ displayChangeToFree: true });
            return;
        }

        this.setState({ saving: true }, async () => {
            try {
                const price: Partial<IPrice> = {
                    type: this.props.form.getFieldValue('purchaseType'),
                };

                if (price.type === AppPurchaseType.Buy) {
                    const buyPrice = this.props.form.getFieldValue('price');
                    price.buyPrice = typeof buyPrice === 'number' && buyPrice > 0 ? buyPrice : 0;
                }

                if (this.props.form.getFieldValue('purchaseType') === 'free') {
                    price.type = AppPurchaseType.Buy;
                }

                await createAppPrice(this.props.app!.appId, price);

                notification.success({ message: 'New Price Created Successfully', description: `The new price for ${ this.props.app!.latest.name } was created successfully.` });

                await this.props.close(true);
                this.props.form.resetFields();
                this.setState({ displayConfirmDisregard: false, changed: false });
            } catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (e && (e as any).error) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    notification.error({ message: 'Saving Price Failed', description: (e as any).error });
                }

                console.warn('failed to save the new pricing:', e);
            } finally {
                this.setState({ saving: false });
            }
        });
    }

    onChange = () => {
        setTimeout(() => {
            if (!this.props.app) {
                return;
            }

            let initialType: AppPurchaseType | 'free' = AppPurchaseType.Subscription;
            if (this.props.app.purchaseType === AppPurchaseType.Buy && this.props.app.price === 0) {
                initialType = 'free';
            } else {
                initialType = this.props.app.purchaseType;
            }

            let changed = false;
            if (initialType !== this.props.form.getFieldValue('purchaseType')) {
                changed = true;
            }

            if (initialType === AppPurchaseType.Buy && this.props.form.getFieldValue('purchaseType') === AppPurchaseType.Buy) {
                if (this.props.app.price !== this.props.form.getFieldValue('price')) {
                    changed = true;
                }
            }

            if (initialType === AppPurchaseType.Subscription && this.props.form.getFieldValue('purchaseType') === AppPurchaseType.Subscription) {
                if (this.props.app.currentPricingId !== this.props.form.getFieldValue('pricingPlanId')) {
                    changed = true;
                }
            }

            this.setState({ changed });
        }, 500);
    }

    confirmChangeToFreeTypeClick = () => {
        this.setState({ displayChangeToFree: false, toFreeConfirmed: true }, () => this.onSaveClick);
    }

    cancelChangeToFreeTypeClick = () => {
        this.setState({ displayChangeToFree: false });
    }

    isMovingToFreeFromSubscription = (): boolean => {
        // we only care to show the subscription to free warning if the original was subscription
        if (!this.props.app || !this.props.app.currentPricingId || this.props.prices.length === 0 || this.props.app.purchaseType !== AppPurchaseType.Subscription) {
            return false;
        }

        if (this.props.form.getFieldValue('purchaseType') !== 'free') {
            return false;
        }

        const price = this.props.prices.find((p) => p.active);
        if (!price) {
            return false;
        }

        return true;
    }

    getSubscriptionToFreeWarning = (): { validateStatus: "" | "error" | "success" | "warning" | "validating", help?: React.ReactNode } => {
        const price = this.props.prices.find((p) => p.active);
        if (!price || !this.isMovingToFreeFromSubscription() || price.actives === 0) {
            return { validateStatus: '' };
        }

        return {
            validateStatus: 'error',
            help: `All ${ price.actives } active subscription${ price.actives === 1 ? '' : 's' } will be automatically cancelled with this change.`,
        };
    }

    get purchaseType() {
        const { getFieldDecorator } = this.props.form;

        let initialType: AppPurchaseType | 'free' = AppPurchaseType.Subscription;
        if (this.props.app) {
            if (this.props.app.purchaseType === AppPurchaseType.Buy && this.props.app.price === 0) {
                initialType = 'free';
            } else {
                initialType = this.props.app.purchaseType;
            }
        }

        const subToFreeWarning = this.getSubscriptionToFreeWarning();

        return (
            <Form.Item label="Purchase type" validateStatus={subToFreeWarning.validateStatus} help={subToFreeWarning.help}>
                {getFieldDecorator('purchaseType', {
                    initialValue: initialType,
                    preserve: true,
                    rules: [{ required: true, message: 'You must select the Purchase Type.' }],
                })(
                    <Select<AppPurchaseType> onChange={this.onChange}>
                        <Select.Option key="free" value="free">Free</Select.Option>
                        <Select.Option key="buy" value={AppPurchaseType.Buy}>One-time</Select.Option>
                        <Select.Option key="sub" value={AppPurchaseType.Subscription}>Subscription</Select.Option>
                    </Select>
                )}
            </Form.Item>
        );
    }

    get oneTimePurchaseArea() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        if (getFieldValue('purchaseType') !== AppPurchaseType.Buy) {
            return null;
        }

        return (
            <React.Fragment>
                <Divider />

                <Row>
                    <Col>
                        <Form.Item label="Price">
                            {getFieldDecorator('price', {
                                initialValue: this.props.app && this.props.app.price > 0 ? this.props.app.price : 0,
                                preserve: true,
                                rules: [
                                    { required: getFieldValue('purchaseType') === AppPurchaseType.Buy, message: 'Please set the price for the one-time purchase.' },
                                    { type: 'number', min: 0.01, message: 'Purchase price must be a number and must be greater than zero.' },
                                ],
                            })(
                                <InputNumber
                                    prefix="$"
                                    min={0.01}
                                    style={{ width: '100%' }}
                                    onChange={this.onChange}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    get pricingPlans() {
        const { getFieldDecorator } = this.props.form;

        const subscriptionPrices = this.props.prices.filter((p) => p.type === AppPurchaseType.Subscription);

        return (
            <Form.Item label="Pricing plan">
                {getFieldDecorator('pricingPlanId', {
                    initialValue: subscriptionPrices.length !== 0 && this.props.app ? this.props.app.currentPricingId : '',
                    preserve: true,
                    rules: [{ required: true, message: 'You must select an active pricing plan.' }],
                })(
                    <Select onChange={this.onChange}>
                        {subscriptionPrices.map((p) => (
                            <Select.Option key={p.id} value={p.id}>
                                <SubscriptionName price={p} isActive={this.props.app ? this.props.app.currentPricingId === p.id : false} />
                            </Select.Option>
                        ))}

                        { subscriptionPrices.length === 0 ?
                            <Select.Option key="no-item" value="">No subscription plans</Select.Option>
                        : null}
                    </Select>
                )}
            </Form.Item>
        );
    }

    onNewPricingPlansClick = () => {
        this.setState({ newPricingPlanVisible: true });
    }

    closeNewPricingPlan = async (saved: boolean) => {
        if (saved) {
            await this.props.close(true);
        }

        this.setState({ newPricingPlanVisible: false });
    }

    get subscriptionPricingArea() {
        if (this.props.form.getFieldValue('purchaseType') !== AppPurchaseType.Subscription) {
            return null;
        }

        return (
            <React.Fragment>
                <Divider />

                <Row>
                    <Col>{this.pricingPlans}</Col>
                    <Col style={{ marginTop: '-25px' }}>
                        <Button size="small" icon="plus" onClick={this.onNewPricingPlansClick}>New pricing plan</Button>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    onDisregardGoBackClick = () => {
        this.setState({ displayConfirmDisregard: false });
    }

    onDisregardDeletePlanClick = () => {
        this.props.close(false);
        this.props.form.resetFields();

        this.setState({ displayConfirmDisregard: false, changed: false });
    }

    get subscriptionToOneTimeWarning() {
        if (!this.props.app || this.props.app.purchaseType !== AppPurchaseType.Subscription) {
            return null;
        }

        if (this.props.form.getFieldValue('purchaseType') !== AppPurchaseType.Buy) {
            return null;
        }

        return (
            <Row>
                <Col>
                    <Alert
                        showIcon
                        type="error"
                        message="Subscription to One-time"
                        description="Converting from a subscription to one-time purchase is not allowed at this time."
                    />
                </Col>
            </Row>
        );
    }

    get saveDisabled() {
        if (!this.props.app) {
            return true;
        }

        if (this.props.app.purchaseType === AppPurchaseType.Subscription && this.props.form.getFieldValue('purchaseType') === AppPurchaseType.Buy) {
            return true;
        }

        return !this.state.changed || this.state.saving;
    }

    render() {
        return (
            <React.Fragment>
                <Drawer
                    title="Change Pricing"
                    width={380}
                    onClose={this.onClose}
                    visible={this.props.isVisible}
                >
                    <Form
                        layout="vertical"
                        hideRequiredMark
                    >
                        <Row>
                            <Col>
                                <Typography.Paragraph>An app can have only one active price at a time. After changing the price, the status of the previous price becomes legacy.</Typography.Paragraph>
                            </Col>
                        </Row>

                        <Row>
                            <Col>{this.purchaseType}</Col>
                        </Row>

                        {this.oneTimePurchaseArea}
                        {this.subscriptionPricingArea}
                        {this.subscriptionToOneTimeWarning}
                    </Form>

                    <DiscardChangesConfirmation
                        visible={this.state.displayConfirmDisregard}
                        onGoBackClick={this.onDisregardGoBackClick}
                        onDisregardClick={this.onDisregardDeletePlanClick}
                    />

                    <ChangePurchaseTypeToFreeConfirmation
                        visible={this.state.displayChangeToFree}
                        price={this.props.prices.find((p) => p.active)}
                        onCancelClick={this.cancelChangeToFreeTypeClick}
                        onChangeClick={this.confirmChangeToFreeTypeClick}
                    />

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <Button onClick={this.onClose} style={{ flex: 1, marginRight: '8px' }} disabled={this.state.saving}>Cancel</Button>
                        <Button
                            type="primary"
                            onClick={this.onSaveClick}
                            disabled={this.saveDisabled}
                            loading={this.state.saving}
                            style={{ flex: 1 }}
                        >Save</Button>
                    </div>
                </Drawer>

                <NewPriceSubscriptionDrawer visible={this.state.newPricingPlanVisible} app={this.props.app} close={this.closeNewPricingPlan} />
            </React.Fragment>
        );
    }
}

export const ChangePriceDrawer = Form.create<IChangePriceDrawerProps>()(ChangePriceDrawerBase);
