import React from 'react';
import { Form, Row, Col, Spin, Button, Input, Tooltip, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IPublisherAppOverview } from 'models/appOverview';
import { setAppUpdateChangelogs } from 'api/apps';

interface FormValues {
    publicChanges: string;
    internalChanges: string;
}

interface IUpdateStep2Props extends FormComponentProps<FormValues> {
    nextText: React.ReactNode;
    goNext: () => void;
    goBack?: () => void;
    setApp: (app: IPublisherAppOverview) => Promise<void>;
    app?: IPublisherAppOverview;
}

interface IUpdateStep2State {
    loading: boolean;
    submitting: boolean;
}

class UpdateAppStepTwoViewBase extends React.PureComponent<IUpdateStep2Props, IUpdateStep2State> {
    state: Readonly<IUpdateStep2State> = {
        loading: false,
        submitting: false,
    };

    get publicChangelogLabel() {
        return (
            <span>
                Public Changelog&nbsp;
                <Tooltip title="Markdown is supported">
                    <Icon type="info-circle" />
                </Tooltip>
            </span>
        );
    }

    get publicChangelogInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={this.publicChangelogLabel} extra="Let your end users know what has changed in this version and what they need to know about updating to this version.">
                {getFieldDecorator('publicChanges', {
                    initialValue: this.props.app?.latest ? this.props.app.latest.changesNote : '',
                    rules: [{ required: true, message: 'Please provide an adequate changelog for this new version of your App to show to the users.' }],
                })(
                    <Input.TextArea
                        rows={4}
                        disabled={this.state.submitting}
                    />
                )}
            </Form.Item>
        );
    }

    get internalChangelogLabel() {
        return (
            <span>
                Internal Changelog&nbsp;
                <Tooltip title="NO markdown support">
                    <Icon type="info-circle" />
                </Tooltip>
            </span>
        );
    }

    get internalChangelogInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={this.internalChangelogLabel} extra="Please indicate any major changes that our review team needs to know and be aware of while reviewing. These notes will not be made public.">
                {getFieldDecorator('internalChanges', {
                    initialValue: this.props.app?.latest ? this.props.app.latest.internalChangesNote : '',
                    rules: [{ required: true, message: 'Please provide the Rocket.Chat Marketplace notes about your update to aid in their review of this update.' }],
                })(
                    <Input.TextArea
                        rows={4}
                        disabled={this.state.submitting}
                    />
                )}
            </Form.Item>
        );
    }

    onNextClick = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            if (!this.props.app) {
                return;
            }

            this.setState({ submitting: true }, async () => {
                try {
                    const res = await setAppUpdateChangelogs(this.props.app!.appId, this.props.app!.latest.internalId!, values);
                    await this.props.setApp(res);

                    this.props.goNext();
                } catch (e) {
                    //TODO: handle errors
                    this.setState({ submitting: false });
                }
            });
        });
    }

    get buttons() {
        const publicChanges = this.props.form.getFieldValue('publicChanges');
        const internalChanges = this.props.form.getFieldValue('internalChanges');

        const nextDisabled = this.state.submitting || this.state.loading ||
            (!publicChanges || typeof publicChanges !== 'string') ||
            (!internalChanges || typeof internalChanges !== 'string');

        return (
            <Row className="button-group">
                <Col span={24}>
                    { typeof this.props.goBack === 'function' ?
                        <Button
                            type="default"
                            size="large"
                            onClick={this.props.goBack}
                            disabled={this.state.submitting || this.state.loading}
                        >
                            Back
                        </Button> : null
                    }

                    <Button
                        type="primary"
                        size="large"
                        onClick={this.onNextClick}
                        loading={this.state.submitting}
                        disabled={nextDisabled}
                    >
                        { this.props.nextText }
                    </Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Form hideRequiredMark colon={false}>
                <Spin spinning={this.state.loading}>
                    {this.publicChangelogInput}
                    {this.internalChangelogInput}

                    {this.buttons}
                </Spin>
            </Form>
        );
    }
}

export const UpdateAppStepTwoView = Form.create<IUpdateStep2Props>()(UpdateAppStepTwoViewBase);
