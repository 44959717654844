import React from 'react';
import { Row, Col, Typography, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IJob } from 'models/compiler/job';
import { AppInfoVersionMapByInternalId } from 'models/appInfo';

import { SimpleDateWithTime } from '../dates/simpleDate';

interface IJobsTableProps {
    jobs?: IJob[];
    versionById: AppInfoVersionMapByInternalId;
}

interface IJobsTableState {}

export class JobsTable extends React.PureComponent<IJobsTableProps, IJobsTableState> {
    state: Readonly<IJobsTableState> = {};

    columns: ColumnProps<IJob>[] = [
        {
            title: 'Name', key: 'name',
            render: (_, job) => {
                const app = this.props.versionById[job.internalAppId];

                if (!app) {
                    return '-';
                }

                return `${app.name} v${app.version}`;
            },
        },
        {
            title: 'TS Version', dataIndex: 'typeScriptVersion', key: 'typeScriptVersion',
            render: (value) => value ? `v${ value }` : '-',
        },
        {
            title: 'Engine Version', dataIndex: 'engineVersion', key: 'engineVersion',
            render: (value) => value ? `v${ value }` : '-',
        },
        {
            title: 'Status', dataIndex: 'status', key: 'status', className: 'title-caps',
            render: (value) => value.replace('-', ' '),
        },
        {
            title: 'Compile Duration', dataIndex: 'duration', key: 'duration',
            render: (value) => value ? `${ value/1000 }s` : '-',
        },
        {
            title: 'Requested At', dataIndex: 'requestedAt', key: 'requestedAt',
            render: (value) => <SimpleDateWithTime date={value} simplier />,
        },
        {
            title: 'Finished At', dataIndex: 'finishedAt', key: 'finishedAt',
            render: (value) => <SimpleDateWithTime date={value} simplier />,
        },
    ]

    render() {
        return (
            <React.Fragment>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={4}>
                        <Typography.Title level={3}>Jobs</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table<IJob>
                            dataSource={this.props.jobs || []}
                            columns={this.columns}
                            rowKey={(job) => job.id}
                            pagination={{ hideOnSinglePage: true }}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
