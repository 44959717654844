import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Spin, Layout } from 'antd';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

interface IAdminDashboardViewProps extends RouteComponentProps, IWithPermissionProps {}

interface IAdminDashboardViewState {}

class AdminDashboardViewBase extends React.PureComponent<IAdminDashboardViewProps, IAdminDashboardViewState> {
    state: Readonly<IAdminDashboardViewState> = {};

    render() {
        return (
            <Spin spinning={!this.props.permissions.loaded}>
                <Layout className='applist'>
                    <Layout.Header className='applist-header'>
                        <h2>Admin Dashboard</h2>
                    </Layout.Header>
                    <Layout.Content>
                        Coming soon&trade;
                    </Layout.Content>
                </Layout>
            </Spin>
        );
    }
}

export const AdminDashboardView = connectPermissions()(withRouter(AdminDashboardViewBase));
