import React from 'react';
import { Modal, Form, Select, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { postAdminUpdateDeveloperRole } from 'api/admin';
import { DeveloperRole, IDeveloper } from 'models/developer';

interface IAdminChangeDeveloperRoleModalProps extends FormComponentProps {
    visible: boolean;
    developer?: IDeveloper;
    onClose: (saved: boolean) => Promise<void>;
}

interface IIAdminChangeDeveloperRoleModalState {
    saving: boolean;
}

class AdminChangeDeveloperRoleModalBase extends React.PureComponent<IAdminChangeDeveloperRoleModalProps, IIAdminChangeDeveloperRoleModalState>  {
    state: Readonly<IIAdminChangeDeveloperRoleModalState> = {
        saving: false,
    };

    get roleSelector() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Role">
                {getFieldDecorator('role', {
                    initialValue: this.props.developer ? this.props.developer.role : '',
                    rules: [{ required: true, message: 'Please select what role the new user should be.' }],
                })(
                    <Select disabled={this.state.saving}>
                        <Select.Option value={DeveloperRole.Viewer}>Viewer</Select.Option>
                        <Select.Option value={DeveloperRole.Developer}>Developer</Select.Option>
                        <Select.Option value={DeveloperRole.Owner}>Owner</Select.Option>
                    </Select>
                )}
            </Form.Item>
        );
    }

    saveRoleChange = () => {
        this.setState({ saving: true }, async () => {
            const { developer } = this.props;
            if (!developer) {
                return;
            }

            try {
                await postAdminUpdateDeveloperRole(developer.publisherId, developer._id, this.props.form.getFieldValue('role'));

                this.props.onClose(true);
                this.props.form.resetFields();
            } catch (e) {
                console.error('error while changing a developer\'s role:', e);
                notification.error({
                    message: 'An Error Occured',
                    description: (<span>
                        An error occured processing your request.<br />Code: { e.code}<br />Error: { e.error}
                    </span>),
                });
            } finally {
                this.setState({ saving: false });
            }
        });
    }

    cancelRoleChange = () => {
        this.props.onClose(false);
    }

    render() {
        const { developer } = this.props;

        return (
            <Modal
                title={`Change ${developer && developer.firstName ? developer.firstName + ' ' + developer.lastName : '???'}'s Role`}
                visible={this.props.visible}
                onCancel={this.cancelRoleChange}
                onOk={this.saveRoleChange}
                closable={!this.state.saving}
                maskClosable={!this.state.saving}
                keyboard={!this.state.saving}
                okText="Save"
                okButtonProps={{ disabled: this.state.saving, loading: this.state.saving }}
                cancelButtonProps={{ disabled: this.state.saving }}
            >
                <Form layout="vertical">
                    {this.roleSelector}
                </Form>
            </Modal>
        );
    }
}

export const AdminChangeDeveloperRoleModal = Form.create<IAdminChangeDeveloperRoleModalProps>()(AdminChangeDeveloperRoleModalBase);
