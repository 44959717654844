import React from 'react';
import { Form, Row, Col, Spin, Button, Select, Avatar, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { getCategories, updateAppVersionDetails } from 'api/apps';

import { languages } from 'utils/intl';
import { IPublisherAppOverview } from 'models/appOverview';

interface FormValues {
    categories: string[];
    languages: string[];
}

interface IStep1Props extends FormComponentProps<FormValues> {
    nextText: React.ReactNode;
    goNext: () => void;
    goBack: () => void;
    setApp: (app: IPublisherAppOverview) => Promise<void>;
    app?: IPublisherAppOverview;
}

interface IStep1State {
    loading: boolean;
    submitting: boolean;
    categories: string[];
}

class NewAppStepOneViewBase extends React.PureComponent<IStep1Props, IStep1State> {
    state: Readonly<IStep1State> = {
        loading: true,
        submitting: false,
        categories: [],
    };

    async componentDidMount() {
        const categories = await getCategories().then((r) => r.map((c) => c.title));

        this.setState({ categories, loading: false });
    }

    get appInfo() {
        return (
            <Row className="app-info">
                <Col span={3}>
                    <Avatar shape="square" size={66} src={this.props.app && this.props.app.latest ? `data:image/png;base64,${ this.props.app.latest.iconFileData }` : ''} />
                </Col>
                <Col span={10}>
                    <Form.Item label="App Name">
                        <Input value={this.props.app ? this.props.app.latest.name : '-'} disabled />
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get categorySelection() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { categories } = this.state;
        const selectedCategories = getFieldValue('categories') as string[];

        let filteredOptions = [...categories];
        if (selectedCategories && Array.isArray(selectedCategories) && selectedCategories.length > 0) {
            filteredOptions = categories.filter(o => !selectedCategories.includes(o) && o !== 'Enterprise');
        }

        return (
            <Form.Item label="Categories">
                {getFieldDecorator('categories', {
                    preserve: true,
                    initialValue: this.props.app && this.props.app.latest ? this.props.app.latest.categories : [],
                    rules: [{ required: true, message: 'Please select at least one category.' }],
                })(
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Categories"
                    >
                        {filteredOptions.map((item) => (
                            <Select.Option key={item}>
                                {item}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        );
    }

    get languageSelection() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const selectedLanguages = getFieldValue('languages') as string[];

        let filteredOptions = [...languages];
        if (selectedLanguages && Array.isArray(selectedLanguages) && selectedLanguages.length > 0) {
            filteredOptions = languages.filter(o => !selectedLanguages.includes(o.key));
        }

        return (
            <Form.Item label="Language(s)">
                {getFieldDecorator('languages', {
                    preserve: true,
                    initialValue: this.props.app && this.props.app.latest && Array.isArray(this.props.app.latest.languages) ? this.props.app.latest.languages : [],
                    rules: [{ required: true, message: 'Please indicate which language(s) your app currently supports.' }],
                })(
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Select language(s)"
                    >
                        {filteredOptions.map((item) => (
                            <Select.Option key={item.key}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        );
    }

    onNextClick = () => {
        this.props.form.validateFields((errors, values) => {
            if (errors) {
                return;
            }

            if (!this.props.app) {
                return;
            }

            if (!this.props.form.isFieldsTouched()) {
                console.log('nothing touched and no errors, going next');
                this.props.goNext();
                return;
            }

            this.setState({ submitting: true }, async () => {
                try {
                    const app = await updateAppVersionDetails(this.props.app!.appId, this.props.app!.latest.internalId!, { categories: values.categories, languages: values.languages });
                    await this.props.setApp(app);

                    this.props.goNext();
                } catch (e) {
                    console.error('error submitting app details:', e);
                    notification.warn({ message: 'Failed to update the App Details.' });
                    this.setState({ submitting: false });
                }
            });
        });

    }

    get buttons() {
        const categories = this.props.form.getFieldValue('categories');
        const languages = this.props.form.getFieldValue('languages')

        const nextDisabled = this.state.submitting || this.state.loading ||
            (!categories || !Array.isArray(categories) || categories.length === 0) ||
            (!languages || !Array.isArray(languages) || languages.length === 0);

        return (
            <Row className="button-group">
                <Col span={24}>
                    <Button
                        type="default"
                        size="large"
                        onClick={this.props.goBack}
                        disabled={this.state.submitting || this.state.loading}
                    >
                        Back
                    </Button>

                    <Button
                        type="primary"
                        size="large"
                        onClick={this.onNextClick}
                        loading={this.state.submitting}
                        disabled={nextDisabled}
                    >
                        { this.props.nextText }
                    </Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Form hideRequiredMark>
                <Spin spinning={this.state.loading}>
                    {this.appInfo}
                    {this.categorySelection}
                    {this.languageSelection}

                    {this.buttons}
                </Spin>
            </Form>
        );
    }
}

export const NewAppStepOneView = Form.create<IStep1Props>()(NewAppStepOneViewBase);
