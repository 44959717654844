import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Layout, Row, Col, Steps, Result, Typography, Spin } from 'antd';

import { IPublisherAppOverview } from 'models/appOverview';

import { NewAppStepZeroView } from './step0';
import { NewAppStepOneView } from './step1';
import { UpdateAppStepTwoView } from './step2-update';
import { NewAppStepThreeView } from './step3';
import { NewAppStepFourView } from './step4';

import { getApp, getAppVersion } from 'api/apps';

import './newappextended.less';

interface IUpdateAppExtendedViewRouteProps {
    appId: string;
    versionId?: string;
    step?: string;
}

interface IUpdateAppExtendedViewState {
    loading: boolean;
    currentStep: number;
    app?: IPublisherAppOverview;
}

class UpdateAppExtendedViewBase extends React.PureComponent<RouteComponentProps<IUpdateAppExtendedViewRouteProps>, IUpdateAppExtendedViewState> {
    state: Readonly<IUpdateAppExtendedViewState> = {
        loading: true,
        currentStep: 0,
    };

    async componentDidMount() {
        if (this.props.match.params.appId && this.props.match.params.versionId) {
            console.log('loading a specific version of the app')
            try {
                const app = await getAppVersion(this.props.match.params.appId, this.props.match.params.versionId);

                let nextStep = 0;
                if (this.props.match.params.step) {
                    nextStep = parseInt(this.props.match.params.step);
                }

                this.setState({ app, loading: false, currentStep: nextStep });
            } catch (e) {
                //TODO: display error
            }
        } else if (this.props.match.params.appId) {
            console.log('loading the latest version');
            try {
                const app = await getApp(this.props.match.params.appId);

                this.setState({ loading: false, app });
            } catch (e) {
                //TODO: display error
            }
        } else {
            this.setState({ loading: false });
        }
    }

    get header() {
        if (!this.state.app) {
            return null;
        }

        let version = '';
        if (typeof this.props.match.params.versionId === 'string' && this.props.match.params.versionId.includes('-')) {
            version = this.state.app.latest.version;
        }

        return (
            <Layout.Header className="newapp-header">
                <h2>Upload an Update for: { this.state.app.latest.name } { version ? `v${ version }` : ''}</h2>
            </Layout.Header>
        )
    }

    get steps() {
        return (
            <Steps size="small" current={this.state.currentStep}>
                <Steps.Step title="Upload" />
                <Steps.Step title="Details" />
                <Steps.Step title="Changes" />
                <Steps.Step title="Screenshots" />
                <Steps.Step title="Submission" />
            </Steps>
        );
    }

    setApp = (app: IPublisherAppOverview) => {
        return new Promise<void>((resolve) => {
            this.setState({ app }, resolve);
        });
    }

    goNext = () => {
        //last step is step 5 which is index 0 based, so 4
        if (this.state.currentStep === 4) {
            return;
        }

        const nextStep = this.state.currentStep + 1;

        this.setState({ currentStep: nextStep });

        if (this.state.app) {
            this.props.history.push(`/publisher/update-extended/app/${ this.state.app.appId }/${ this.state.app.latest.internalId }/${ nextStep }`);
        }
    }

    goBack = () => {
        //can not go back past step 0 (aka step 1)
        if (this.state.currentStep === 0) {
            return;
        }

        const nextStep = this.state.currentStep - 1;

        this.setState({ currentStep: nextStep });

        if (this.state.app) {
            this.props.history.push(`/publisher/update-extended/app/${ this.state.app.appId }/${ this.state.app.latest.internalId }/${ nextStep }`);
        }
    }

    get stepContent() {
        switch (this.state.currentStep) {
            case 0:
                return <NewAppStepZeroView app={this.state.app} goNext={this.goNext} isUpdate setApp={this.setApp} />;
            case 1:
                return <NewAppStepOneView app={this.state.app} nextText="Next" goNext={this.goNext} goBack={this.goBack} setApp={this.setApp} />;
            case 2:
                return <UpdateAppStepTwoView app={this.state.app} nextText="Next" goNext={this.goNext} goBack={this.goBack} setApp={this.setApp} />;
            case 3:
                return <NewAppStepThreeView app={this.state.app} nextText="Next" goNext={this.goNext} goBack={this.goBack} setApp={this.setApp} />;
            case 4:
                return <NewAppStepFourView app={this.state.app} goBack={this.goBack} isUpdate />;
            default:
                return (
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, you seemed to have arrived at a step which does not exist. Huh!"
                    />
                );
        }
    }

    get stepTitle() {
        switch (this.state.currentStep) {
            case 0:
                return 'Upload App Package';
            case 1:
                return 'App Details';
            case 2:
                return 'Change Log';
            case 3:
                return 'App Info';
            case 4:
                return 'Submit for Review';
            default:
                return '???';
        }
    }

    render() {
        return (
            <Layout className="newapp-extended">
                <Spin spinning={this.state.loading}>
                    {this.header}

                    <Row>
                        <Col xs={24} lg={20} xl={18}>
                            {this.steps}
                        </Col>
                    </Row>

                    <Row>
                        <Col className="new-extended-app-content" xs={24} lg={13}>
                            <Typography.Title level={4}>{ this.stepTitle }</Typography.Title>

                            {this.stepContent}
                        </Col>
                    </Row>
                </Spin>
            </Layout>
        );
    }
}

export const UpdateAppExtendedView = withRouter(UpdateAppExtendedViewBase);
