import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Table, Button, notification } from 'antd';

import { IAppCategory } from 'models/category';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import { SimpleDateWithTime } from 'components/dates/simpleDate';

import { getAdminCategories } from 'api/admin';

import { NewCategoryModal } from './newModal';
import { ColumnProps } from 'antd/lib/table';

interface ICategoriesListProps extends RouteComponentProps, IWithPermissionProps {}

interface ICategoriesListState {
    loading: boolean;
    categories: IAppCategory[];
    newModalVisible: boolean;
}

class CategoriesListBase extends React.PureComponent<ICategoriesListProps, ICategoriesListState> {
    state: Readonly<ICategoriesListState> = {
        categories: [],
        loading: true,
        newModalVisible: false,
    };

    columns: ColumnProps<IAppCategory>[] = [
        {
            title: 'Title', key: 'title', dataIndex: 'title',
        },
        {
            title: 'Created At', key: 'createdDate', dataIndex: 'createdDate',
            render: (value) => <SimpleDateWithTime date={value} simplier />,
        },
        {
            title: 'Modified At', key: 'modifiedDate', dataIndex: 'modifiedDate',
            render: (value) => <SimpleDateWithTime date={value} simplier />,
        },
    ];

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: ICategoriesListProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:categories'].canRead) {
            console.log('user can not read the system:categories');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Categories. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        this.setState({ loading: true }, async () => {
            const categories = await getAdminCategories();

            this.setState({ categories, loading: false, });
        });
    }

    onNewCategoryClick = () => {
        this.setState({ newModalVisible: true });
    }

    onCloseModal = async (created: boolean) => {
        if (created) {
            this.loadData();
        }

        this.setState({ newModalVisible: false });
    }

    render() {
        return (
            <Layout>
                <Layout.Header className='applist-header'>
                    <h2>Categories</h2>

                    { this.props.permissions.features['system:categories'].canCreate ?
                        <Button type="primary" icon="plus" onClick={this.onNewCategoryClick} style={{ marginRight: '15px' }}>New</Button>
                    : null}
                </Layout.Header>
                <Layout.Content>
                    <Table<IAppCategory>
                        loading={this.state.loading}
                        pagination={{ hideOnSinglePage: true }}
                        columns={this.columns}
                        dataSource={this.state.categories}
                        rowKey={(c) => c.id}
                    />

                    <NewCategoryModal visible={this.state.newModalVisible} close={this.onCloseModal} />
                </Layout.Content>
            </Layout>
        );
    }
}

export const AdminCategoriesList = connectPermissions({ feature: 'system:categories', system: true })(withRouter(CategoriesListBase));

