import React from 'react';
import { Button, Input, Modal, Row, Typography } from 'antd';
import { IWebhookActivityLogResponse } from 'models/webhookActivityLog';


interface IWebhookServerRawResponse {
    visible: boolean;
    response?: IWebhookActivityLogResponse;
    close: () => void;
}

export const WebhookEventServerRawResponse: React.FC<IWebhookServerRawResponse> = (props) => {
    let data = props.response?.response;
    let type = props.response?.responseType;

    if (!data) {
        data = 'no data';
    }

    if (!type) {
        type = 'unknown';
    }

    return (
        <Modal
            visible={props.visible}
            title={'Raw server response'}
            onCancel={props.close}
            footer={<Button type="default" onClick={props.close}>Close</Button>}
        >
            <Row style={{ marginBottom: '8px' }}>
                <Typography.Text strong>Content-Type: </Typography.Text>
                <Typography.Text>{type}</Typography.Text>
            </Row>

            <Row>
                <Input.TextArea
                    readOnly
                    value={data}
                    rows={15}
                />
            </Row>
        </Modal>
    );
}
