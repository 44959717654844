import React from 'react';
import { Row, Col, Spin, Button, Typography } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { IPublisherAppOverview } from 'models/appOverview';
import { submitAppVersion } from 'api/apps';

interface IStep4Props extends RouteComponentProps {
    goBack: () => void;
    app?: IPublisherAppOverview;
    isUpdate?: boolean;
}

interface IStep4State {
    loading: boolean;
    submitting: boolean;
}

class NewAppStepFourViewBase extends React.PureComponent<IStep4Props, IStep4State> {
    state: Readonly<IStep4State> = {
        loading: false,
        submitting: false,
    };

    get pageText() {
        if (!this.props.app) {
            return null;
        }

        return (
            <Typography>
                <Typography.Paragraph>Your App{ this.props.isUpdate ? ' update,' : ','} <Typography.Text strong>{ this.props.app.latest.name } v{ this.props.app.latest.version }</Typography.Text>, is almost ready to submit for review. Once you click <Typography.Text code>Submit for Review</Typography.Text> our team will review your code and get touch in with you via email as soon as possible. Thank you for your contribution!</Typography.Paragraph>
                <Typography.Paragraph>For more information please contact us at <a href={`mailto:support@rocket.chat?bcc=marketplace@rocket.chat&subject=App%20Submission%20Question&body=Hi%20Rocket.Chat%20support%20%20I%20am%20trying%20to%20submit%20my%20App%20${ this.props.app.latest.name }%20v${ this.props.app.latest.version }`}>support@rocket.chat</a>.</Typography.Paragraph>
            </Typography>
        );
    }

    onNextClick = () => {
        this.setState({ submitting: true }, async () => {
            console.log('submitting');

            if (!this.props.app) {
                this.setState({ submitting: false });
                return;
            }

            try {
                const res = await submitAppVersion(this.props.app.appId, this.props.app.latest.internalId!);

                setTimeout(() => this.props.history.push(`/publisher/apps/${ res.appId }`), 1500);
            } catch (e) {
                //TODO: error handling
                this.setState({ submitting: false });
            }
        });
    }

    get buttons() {
        const nextDisabled = false;

        return (
            <Row className="button-group">
                <Col span={24}>
                    <Button
                        type="default"
                        size="large"
                        onClick={this.props.goBack}
                        disabled={this.state.submitting || this.state.loading}
                    >
                        Back to Screenshots
                    </Button>

                    <Button
                        type="primary"
                        size="large"
                        onClick={this.onNextClick}
                        loading={this.state.submitting}
                        disabled={nextDisabled}
                    >
                        Submit for Review
                    </Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                { this.pageText }

                {this.buttons}
            </Spin>
        );
    }
}

export const NewAppStepFourView = withRouter(NewAppStepFourViewBase);
