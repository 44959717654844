import React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { Layout, Row, Col, Badge, Avatar, Descriptions, Typography, Button, Modal, List, Tag, notification } from 'antd';

import { IAdminAppOverview } from 'models/appOverview';
import { AppInfoVersionMapByInternalId } from 'models/appInfo';
import { IPrice } from 'models/appPrice';
import { IAppStatistics } from 'models/appStatus';
import { IJob } from 'models/compiler/job';

import Loading from 'containers/Loading';
import { JobsTable } from 'components/jobs';
import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import { PricesTable } from 'components/prices/table';

import { AdminAppsTable } from '../AppsTable';

import { statusToColor, capitalizeFirstLetter } from 'utils/appStatus';
import { getAdminApp, getAdminAppVersions, getAdminAppStats, getAdminAppJobs, getAdminAppPrices } from 'api/admin';

import { AdminAppUsageTable } from './usageTable';
import { AdminAppActivityLogsTable } from './activityLogs';

import './app.less';

interface IAdminAppViewProps extends RouteComponentProps<{ id: string }>, IWithPermissionProps { }

interface IAdminAppViewState {
    loading: boolean;
    app?: IAdminAppOverview;
    stats?: IAppStatistics;
    versions: IAdminAppOverview[];
    jobs: IJob[];
    prices: IPrice[];
    versionById: AppInfoVersionMapByInternalId;
}

class AdminAppViewBase extends React.PureComponent<IAdminAppViewProps, IAdminAppViewState> {
    state: Readonly<IAdminAppViewState> = {
        loading: true,
        versions: [],
        jobs: [],
        prices: [],
        versionById: {},
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IAdminAppViewProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:apps'].canRead) {
            console.log('user can not read the system:apps');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Apps. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const [app, versionInfos, stats, jobs, prices] = await Promise.all([
            getAdminApp(this.props.match.params.id),
            getAdminAppVersions(this.props.match.params.id),
            getAdminAppStats(this.props.match.params.id),
            getAdminAppJobs(this.props.match.params.id),
            getAdminAppPrices(this.props.match.params.id),
        ]);

        const versionById: AppInfoVersionMapByInternalId = {};
        const versions: IAdminAppOverview[] = versionInfos.map((v) => {
            versionById[v.internalId!] = v;

            return {
                ...app,
                latest: v,
            };
        });

        this.setState({
            loading: false,
            app,
            versions,
            stats,
            jobs,
            prices,
            versionById,
        });
    }

    onStatsClick = () => {
        if (!this.state.app || !this.state.stats) {
            return;
        }

        const { stats } = this.state;
        const { purchaseType, latest } = this.state.app;

        const content = [<List.Item key='totalDownloads'>Total Downloads: {stats.totalDownloads}</List.Item>];
        if (purchaseType === 'buy') {
            content.push(<List.Item key='purchases'>Purchases: {stats.purchases}</List.Item>);
        } else {
            content.push(<List.Item key='activeSubscriptions'>Active Subscriptions: {stats.activeSubscriptions}</List.Item>);
            content.push(<List.Item key='inactiveSubscriptions'>Inactive Subscriptions: {stats.inactiveSubscriptions}</List.Item>);
        }

        Modal.info({
            title: latest.name + '\'s Stats',
            content: (
                <Layout>
                    <List>{content}</List>

                    <h3>Downloads</h3>
                    <List
                        dataSource={Object.keys(stats.appVersions)}
                        renderItem={(v, index) => {
                            const stat = stats.appVersions[v];

                            return (
                                <List.Item key={'version-' + v + '-' + index}>v{v}: {stat.downloads}</List.Item>
                            );
                        }}
                    />
                </Layout>
            ),
        });
    };

    get appBundleHeaderDescription() {
        if (!this.state.app) {
            return null;
        }

        const { bundledIn } = this.state.app;

        return bundledIn?.map((b) =>
            <Tag key={"bundleTag-" + b.bundleId} color="geekblue">
                <Link to={{ pathname: `/publisher/admin/bundles/${b.bundleId}` }}>{b.bundleName}</Link>
            </Tag>
        );
    }

    get appHeader() {
        const { app } = this.state;
        if (!app) {
            return null;
        }

        const descriptions = [
            <Descriptions.Item key="shortDescription" label="Short Description" span={3}>{app.latest.shortDescription || '-'}</Descriptions.Item>,
            <Descriptions.Item key="longDescription" label="Long Description" span={3}>{app.latest.description}</Descriptions.Item>,
            <Descriptions.Item key="categories" label="Categories" span={3}>{app.latest.categories.join(', ')}</Descriptions.Item>,
            <Descriptions.Item key="status" label="Status">
                <Badge color={statusToColor[app.latest.status]} text={capitalizeFirstLetter(app.latest.status)} />
            </Descriptions.Item>,
        ];

        if (app?.bundledIn?.length !== 0) {
            descriptions.push(<Descriptions.Item key="bundledIn" label="Bundled In" span={2}>{this.appBundleHeaderDescription}</Descriptions.Item>);
        }

        descriptions.push(<Descriptions.Item key="purchaseType" label="Purchase Type">{capitalizeFirstLetter(app.purchaseType)}</Descriptions.Item>);
        descriptions.push(
            <Descriptions.Item key="appStats" label="Stats">
                <Button type="dashed" onClick={this.onStatsClick}>View Stats</Button>
            </Descriptions.Item>
        );

        if (app.isEnterpriseOnly) {
            descriptions.push(
                <Descriptions.Item key="enterpriseOnly" label="Premium Only">
                    <Badge color="green" text="Enabled" />
                </Descriptions.Item>
            );
        }

        if (app.isUsageBased) {
            descriptions.push(
                <Descriptions.Item key="usageBased" label="Usage Based">
                    <Badge status="processing" text="Enabled" />
                </Descriptions.Item>
            );
        }

        return (
            <Layout.Content>
                <Row type="flex" justify="space-between">
                    <Col span={8}>
                        <Typography.Title level={2}>{app.latest.name}</Typography.Title>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={2}>
                        <Avatar className="avatar" size="large" shape="square" src={`data:image/jpg;base64,${app.latest.iconFileData}`} />
                    </Col>
                    <Col span={22}>
                        <Descriptions bordered>
                            {descriptions}
                        </Descriptions>
                    </Col>
                </Row>
            </Layout.Content>
        )
    }

    get usageTable() {
        if (!this.state.app || !this.state.app.appId || !this.state.app.isUsageBased) {
            return null;
        }

        return (
            <AdminAppUsageTable appId={this.state.app.appId} />
        );
    }

    get activityLogs() {
        if (!this.state.app || !this.state.app.appId) {
            return null;
        }

        return (
            <AdminAppActivityLogsTable appId={this.state.app.appId} />
        )
    }

    render() {
        if (this.state.loading) {
            return (
                <Loading />
            );
        }

        return (
            <Layout>
                {this.appHeader}

                <Layout.Content>
                    <PricesTable prices={this.state.prices} disallowChange />

                    <Row style={{ marginTop: '25px' }}>
                        <Col span={4}>
                            <Typography.Title level={3}>Versions</Typography.Title>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24}>
                            <AdminAppsTable
                                type="adminVersions"
                                onActionTaken={this.loadData}
                                tableProps={{
                                    loading: this.state.loading,
                                    dataSource: this.state.versions,
                                    rowKey: (record) => record.latest.version,
                                    pagination: { hideOnSinglePage: true },
                                }}
                            />
                        </Col>
                    </Row>

                    <JobsTable jobs={this.state.jobs} versionById={this.state.versionById} />
                    { this.usageTable }
                    { this.activityLogs }
                </Layout.Content>
            </Layout>
        );
    }
}


export const AdminAppView = connectPermissions({ feature: 'system:apps', system: true })(withRouter(AdminAppViewBase));
