import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Form, Layout, Row, Col, Input, Checkbox, Button, message, Divider, Select, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IDeveloper } from 'models/developer';
import { IPublisher } from 'models/publisher';

import { store } from 'store';
import { loadAccessControl } from 'store/slices/accessControl';

import { getMyInfo } from 'api/profile';
import { isPublisherSignUpEnabled, associateCloudAccount, getPublisher } from 'api/publisher';

import { countries } from 'utils/intl';

interface IRegisterViewProps extends FormComponentProps, RouteComponentProps { }

interface IRegisterViewState {
    enabled: boolean;
    isLoading: boolean;
    isSaving: boolean;
    publisher?: IPublisher;
    developer?: IDeveloper;
}

class RegisterViewBase extends React.PureComponent<IRegisterViewProps, IRegisterViewState> {
    state: Readonly<IRegisterViewState> = {
        enabled: false,
        isLoading: true,
        isSaving: false,
    }

    ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
    einRegex = /^[1-9]\d?-\d{7}$/;

    async componentDidMount() {
        const result = await isPublisherSignUpEnabled();

        let publisher: IPublisher | undefined;
        let developer: IDeveloper | undefined;
        try {
            publisher = await getPublisher();
            developer = await getMyInfo();
        } catch (e) {
            //404 errors are expected. We do not expect any other errors though.
            if (e !== 404) {
                console.log('caught an error loading the publisher and developer information:', e);
                return;
            }
        }

        this.setState({ enabled: result.enabled, publisher, developer, isLoading: false });
    }

    handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { form } = this.props;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ isSaving: true }, async () => {
                await associateCloudAccount(values);
                await store.dispatch(loadAccessControl());

                message.success('Publisher and developer account created. Welcome to the Rocket.Chat Marketplace!');

                this.props.history.push({ pathname: '/publisher/apps' });
            });
        });

    }

    get header() {
        return (
            <Layout.Header className="profile-header">
                <h2>Publisher Sign Up</h2>
            </Layout.Header>
        )
    }

    //#region fields
    get publisherNameInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col>
                    <Form.Item label="Publisher Name" colon={false} extra="Please ensure this is your legal entity name (if you're an individual, please use your full name). It will be used for tax documents, reports, and will be shown publicly.">
                        {getFieldDecorator('name', {
                            initialValue: this.state.publisher ? this.state.publisher.name : '',
                            rules: [{ required: true, message: 'Your publisher name is required.' }]
                        })(
                            <Input disabled={this.state.isSaving} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get publisherCity() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col>
                    <Form.Item label="City" colon={false} extra="Provide us the city where the legal entity name (and if you're an individual) is located.">
                        {getFieldDecorator('publisherCity', {
                            initialValue: this.state.publisher ? this.state.publisher.city : '',
                            rules: [{ required: true, message: 'Your publisher city is required.' }]
                        })(
                            <Input disabled={this.state.isSaving} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get publisherState() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col>
                    <Form.Item label="State" colon={false} extra="Provide us the state where the legal entity name (and if you're an individual) is located.">
                        {getFieldDecorator('publisherState', {
                            initialValue: this.state.publisher ? this.state.publisher.state : '',
                            rules: [{ required: true, message: 'Your publisher state is required.' }]
                        })(
                            <Input disabled={this.state.isSaving} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get publisherCountry() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col>
                    <Form.Item label="Country" colon={false} extra="Your legal entity's country of establishment (or country of citizenship for individuals) must be provided.">
                        {getFieldDecorator('publisherCountry', {
                            initialValue: this.state.publisher ? this.state.publisher.country : '',
                            rules: [{ required: true, message: 'You must select the country.' }]
                        })(
                            <Select disabled={this.state.isSaving}>
                                {countries.map((c) => (
                                    <Select.Option key={c.code} value={c.code}>{c.name}</Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
        );
    }

    get rocketchatUsernameInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col>
                    <Form.Item label="Rocket.Chat Username" colon={false} extra="Please provide us with your open.rocket.chat username. Why do we need this? In the event we need to contact you or we decide to do real time alerts.">
                        {getFieldDecorator('rocketchatUsername', {
                            initialValue: this.state.developer ? this.state.developer.rocketChatUsername : '',
                            rules: [{ required: true, message: 'We need your open.rocket.chat username so that we can contact you.' }]
                        })(
                            <Input disabled={this.state.isSaving} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    get tosLinkInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col>
                    <Form.Item label="TOS Link" colon={false} extra="Please provide a link to your Terms of Service document. This link must be publicly accessible as users must be able to read it.">
                        {getFieldDecorator('tosLink', {
                            initialValue: this.state.publisher ? this.state.publisher.tosLink : '',
                            rules: [
                                { required: true, message: 'You must provide the TOS link.' },
                                { pattern: /(^https:\/\/)/, message: 'Invalid url, it must start with "https://".' }
                            ]
                        })(
                            <Input disabled={this.state.isSaving} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    get privacyLinkInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Row>
                <Col>
                    <Form.Item label="Privacy Link" colon={false} extra="Please provide a link to your Privacy document. This link must be publicly accessible as users must be able to read it.">
                        {getFieldDecorator('privacyLink', {
                            initialValue: this.state.publisher ? this.state.publisher.privacyLink : '',
                            rules: [
                                { required: true, message: 'You must provide the Privacy link.' },
                                { pattern: /(^https:\/\/)/, message: 'Invalid url, it must start with "https://".' }
                            ]
                        })(
                            <Input disabled={this.state.isSaving} />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    get agreeToOurTOSAndPrivacyLink() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item wrapperCol={{ offset: 4 }}>
                {getFieldDecorator('agreement', {
                    valuePropName: 'checked',
                    rules: [
                        {
                            required: true,
                            validator: (rule, value, cb) => {
                                if (value) {
                                    cb();
                                    return;
                                }

                                cb(new Error('You must agree to the TOS and Privacy Agreement.'));
                            },
                        },
                    ],
                })(
                    <Checkbox disabled={this.state.isSaving}>
                        I have read the <a href="https://rocket.chat/terms" target="_blank" rel="noopener noreferrer">terms of service</a> and the <a href="https://rocket.chat/privacy" target="_blank" rel="noopener noreferrer">privacy agreement</a>.
                    </Checkbox>
                )}
            </Form.Item>
        );
    }
    //#endregion fields

    get signUpButton() {
        return (
            <Form.Item wrapperCol={{ span: 12, offset: 4 }}>
                <Button htmlType="submit" type="primary" icon="audit" loading={this.state.isSaving}>
                    Sign Up
                </Button>
            </Form.Item>
        );
    }

    get signUpForm() {
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 9 },
        };

        return (
            <Form {...formItemLayout} onSubmit={this.handleSubmit} id="register-form">
                <Divider orientation="left">Publisher Information</Divider>
                {this.publisherNameInput}
                {this.publisherCity}
                {this.publisherState}
                {this.publisherCountry}
                {this.tosLinkInput}
                {this.privacyLinkInput}
                <Divider orientation="left">Your Personal Information</Divider>
                {this.rocketchatUsernameInput}
                {this.agreeToOurTOSAndPrivacyLink}
                {this.signUpButton}
            </Form>
        );
    }

    get content() {
        if (this.state.enabled) {
            return this.signUpForm;
        }

        if (this.state.isLoading) {
            return (
                <Spin />
            );
        }

        return (
            <p>Sign ups are disabled. If you feel you got here in error, please contact us at: <a href="mailto:support@rocket.chat">support@rocket.chat</a></p>
        );
    }

    render() {
        return (
            <Layout>
                {this.header}

                <Layout.Content>
                    { this.content }
                </Layout.Content>
            </Layout>
        );
    }
}

export const RegisterView = withRouter(Form.create<IRegisterViewProps>()(RegisterViewBase));
