import React from 'react';
import moment from 'moment';

interface ISimpleDateProps {
    date: string;
    simplier?: boolean;
}

export const SimpleDate: React.FC<ISimpleDateProps> = (props) => {
    const d = moment(props.date);

    if (!d.isValid() || d.year() === 0) {
        return (
            <span>'-'</span>
        );
    }

    return (
        <span>{ d.format(props.simplier ? 'l' : 'L') }</span>
    );
}

export const SimpleDateWithTime: React.FC<ISimpleDateProps> = (props) => {
    const d = moment(props.date);

    if (!d.isValid() || d.year() === 0) {
        return (
            <span>-</span>
        );
    }

    return (
        <span>{ d.format(props.simplier ? 'l LTS' : 'L LTS') }</span>
    );
}
