import React from 'react';
import { Form, Row, Col, Spin, Button, Upload, Icon, Input, List, Avatar, Modal, notification, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DraggerProps } from 'antd/lib/upload';

import { IPublisherAppOverview } from 'models/appOverview';

import { getPublisherAppScreenshots, removeAppScreenshot, setAppVersionInfo, uploadAppVersionScreenshots } from 'api/apps';
import { IScreenshot } from 'models/screenshot';

interface FormValues {
    shortDescription: string;
    description: string;
    privacyPolicySummary: string;
    documentationUrl: string;
}

interface IStep3Props extends FormComponentProps<FormValues> {
    nextText: React.ReactNode;
    goNext: () => void;
    goBack: () => void;
    setApp: (app: IPublisherAppOverview) => Promise<void>;
    app?: IPublisherAppOverview;
}

interface IStep3State {
    loading: boolean;
    submitting: boolean;
    screenshots?: IScreenshot[];
}

class NewAppStepThreeViewBase extends React.PureComponent<IStep3Props, IStep3State> {
    state: Readonly<IStep3State> = {
        loading: true,
        submitting: false,
        screenshots: [],
    };

    async componentDidMount() {
        if (!this.props.app) {
            return;
        }

        const screenshots = await getPublisherAppScreenshots(this.props.app.appId, this.props.app.latest.internalId!);
        this.setState({ screenshots, loading: false });
    }

    removeScreenshot = (screenshot: IScreenshot) => {
        this.setState({ loading: true }, async () => {
            try {
                await removeAppScreenshot(screenshot.appId, screenshot.id);
                this.setState({ screenshots: this.state?.screenshots?.filter((s) => s.id !== screenshot.id) });
            } catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                if (e && (e as any).error) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    notification.error({ message: (e as any).error });
                }
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    get screenshotList() {
        if (!this.state?.screenshots?.length) {
            return null;
        }

        return (
            <List<IScreenshot>
                dataSource={this.state?.screenshots}
                renderItem={(screenshot) => (
                    <List.Item key={screenshot.id} extra={[ <Button key="remove" type="danger" size="small" onClick={() => this.removeScreenshot(screenshot)}>Delete</Button> ]}>
                        <List.Item.Meta
                            avatar={<Avatar src={screenshot.thumbnailUrl} shape="square" />}
                            title="Screenshot"
                            description={screenshot.fileName}
                        />
                    </List.Item>
                )}
            />
        );
    }

    get uploadScreenshotsInput() {
        const uploadProps: DraggerProps = {
            accept: 'image/png,image/jpeg',
            multiple: true,
            showUploadList: true,
            disabled: this.state.submitting,
            listType: 'text',
            fileList: [],
            beforeUpload: (file: File) => {
                this.setState({ submitting: true }, async () => {
                    const data = new FormData();
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    data.set('screenshots', file);

                    try {
                        const res = await uploadAppVersionScreenshots(this.props.app!.appId, this.props.app!.latest.internalId!, data);
                        this.setState({ screenshots: this.state?.screenshots?.concat(res) });
                    } catch (e) {
                        console.error('error while uploading screenshot:', e);

                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if (e && (e as any).error) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            notification.error({ message: (e as any).error });
                        }
                    } finally {
                        this.setState({ submitting: false });
                    }
                });

                return false;
            },
        };

        return (
            <Form.Item label="Screenshots">
                <Spin spinning={this.state.submitting}>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Click here or drag and drop the screenshots here</p>
                        <p className="ant-upload-hint">The aspect ratio should be 16:9 or 960x540 pixels.</p>
                    </Upload.Dragger>


                    {this.screenshotList}
                </Spin>
            </Form.Item>
        );
    }

    get shortDescriptionLabel() {
        return (
            <span>
                Short description&nbsp;
                <Tooltip title="Not included in app package">
                    <Icon type="info-circle" />
                </Tooltip>
            </span>
        );
    }

    get shortDescription() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={this.shortDescriptionLabel} extra="Concisely describe the primary value your app provides. This description is visible on the Marketplace app listing and on the app listing detail.">
                {getFieldDecorator('shortDescription', {
                    initialValue: this.props.app?.latest?.shortDescription ? this.props.app.latest.shortDescription : '',
                    rules: [
                        { required: true, message: 'Please provide a concise short description (max of 45 characters).' },
                        { min: 10, message: 'While the short description must be short, it needs to provide value to the customer (more than 10 characters).' },
                        { max: 45, message: 'The short description must be no more than 45 characters.' },
                    ],
                })(
                    <Input.TextArea
                        rows={2}
                        disabled={this.state.submitting}
                        maxLength={50}
                    />
                )}
            </Form.Item>
        );
    }

    get descriptionLabel() {
        return (
            <span>
                Description&nbsp;
                <Tooltip title="Initial value provided via app.json file">
                    <Icon type="info-circle" />
                </Tooltip>
            </span>
        );
    }

    get descriptionInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={this.descriptionLabel} extra="Give a more in-depth explanation of what your app does, how it works and value it provides. This description is visible on the Marketplace on the app listing detail only.">
                {getFieldDecorator('description', {
                    initialValue: this.props.app && this.props.app.latest ? this.props.app.latest.description : '',
                    rules: [{ required: true, message: 'Please provide an adequate description of what your app does for the end user.' }],
                })(
                    <Input.TextArea
                        rows={4}
                        placeholder="Write a general description explaining what the app does for the end user."
                        disabled={this.state.submitting}
                    />
                )}
            </Form.Item>
        );
    }

    get privacyAndDataHandlingInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Privacy Policy Summary" extra="Privacy policies are typically long in length. Please provide your App users a brief summary of the privacy and data handling policies for this App. Plaintext only, markdown is not supported.">
                {getFieldDecorator('privacyPolicySummary', {
                    initialValue: this.props.app && this.props.app.latest ? this.props.app.latest.privacyPolicySummary : '',
                    rules: [{ required: true, message: 'Please provide a summary privacy and data policy.' }],
                })(
                    <Input.TextArea
                        rows={4}
                        placeholder="Enter privacy rules and data handling agreement."
                        disabled={this.state.submitting}
                    />
                )}
            </Form.Item>
        );
    }

    get documentationUrlLabel() {
        return (
            <span>
                Documentation URL&nbsp;
                <Tooltip title="Provide a link to app documentation">
                    <Icon type="info-circle" />
                </Tooltip>
            </span>
        );
    }

    get documentationUrl() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={this.documentationUrlLabel}>
                {getFieldDecorator('documentationUrl', {
                    initialValue: this.props.app?.latest?.documentationUrl || '',
                    rules: [{ required: true, message: 'You must provide a url to your app\'s documentation.'}]
                })(
                    <Input
                        className="app-docs-url"
                        prefix="https://"
                        disabled={this.state.submitting}
                    />
                )}
            </Form.Item>
        );
    }

    confirmNoScreenshotsModal = () => {
        return new Promise<boolean>((resolve) => {
            Modal.confirm({
                title: 'No Screenshots Provided',
                content: 'There are currently no screenshots for this App. Are you sure you want to continue without any screenshots?',
                onCancel: () => resolve(false),
                cancelText: 'No',
                onOk: () => resolve(true),
                okText: 'Yes',
            });
        });
    }

    onNextClick = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            if (!this.props.app) {
                return;
            }

            if (!this.state?.screenshots?.length && !await this.confirmNoScreenshotsModal()) {
                return;
            }

            this.setState({ submitting: true }, async () => {
                console.log('submitting', values);

                try {
                    const res = await setAppVersionInfo(this.props.app!.appId, this.props.app!.latest.internalId!, values);
                    await this.props.setApp(res);

                    this.props.goNext();
                } catch (e) {
                    //TODO: handle errors
                    this.setState({ submitting: false });
                }
            });
        });
    }

    get buttons() {
        const shortDescription = this.props.form.getFieldValue('shortDescription');
        const description = this.props.form.getFieldValue('description');
        const privacyPolicy = this.props.form.getFieldValue('privacyPolicySummary');
        const docsUrl = this.props.form.getFieldValue('documentationUrl');

        const nextDisabled = this.state.submitting || this.state.loading ||
            (!shortDescription || typeof shortDescription !== 'string') || (shortDescription.length < 10 || shortDescription.length > 45) ||
            (!description || typeof description !== 'string') ||
            (!privacyPolicy || typeof privacyPolicy !== 'string') ||
            (!docsUrl || typeof docsUrl !== 'string');

        return (
            <Row className="button-group">
                <Col span={24}>
                    <Button
                        type="default"
                        size="large"
                        onClick={this.props.goBack}
                        disabled={this.state.submitting || this.state.loading}
                    >
                        Back
                    </Button>

                    <Button
                        type="primary"
                        size="large"
                        onClick={this.onNextClick}
                        loading={this.state.submitting}
                        disabled={nextDisabled}
                    >
                        { this.props.nextText }
                    </Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Form hideRequiredMark colon={false}>
                <Spin spinning={this.state.loading}>
                    {this.uploadScreenshotsInput}
                    {this.shortDescription}
                    {this.descriptionInput}
                    {this.privacyAndDataHandlingInput}
                    {this.documentationUrl}

                    {this.buttons}
                </Spin>
            </Form>
        );
    }
}

export const NewAppStepThreeView = Form.create<IStep3Props>()(NewAppStepThreeViewBase);
