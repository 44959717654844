import React from 'react';
import moment from 'moment';
import { Drawer, Row, Col, Form, Button, Select, DatePicker, InputNumber, Switch, message, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IPurchaseRecord } from 'models/purchaseRecord';
import { PurchaseSubscriptionStatus } from 'models/purchaseSubscription';
import { IAppOverview } from 'models/appOverview';

import { postAdminPurchaseByID } from 'api/admin';

interface IEditSubscriptionDrawerProps extends FormComponentProps {
    visible: boolean;
    purchaseRecord?: IPurchaseRecord;
    app?: IAppOverview;
    close: (saved: boolean) => void;
}

interface IEditSubscriptionDrawerState {
    isSaving: boolean;
    prId: string;
}

class EditSubscriptionDrawerBase extends React.PureComponent<IEditSubscriptionDrawerProps, IEditSubscriptionDrawerState> {
    state: Readonly<IEditSubscriptionDrawerState> = {
        isSaving: false,
        prId: '',
    };

    componentDidUpdate() {
        if (this.props.purchaseRecord && this.props.purchaseRecord._id && this.props.purchaseRecord._id !== this.state.prId) {
            this.props.form.resetFields();
            this.setState({ prId: this.props.purchaseRecord._id });
        }
    }

    reset = () => {
        setTimeout(() => {
            this.props.form.resetFields();
            this.setState({ prId: '', isSaving: false });
        }, 500);
    };

    onClose = () => {
        this.props.close(false);
        this.reset();
    };

    handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        const { form } = this.props;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ isSaving: true }, async () => {
                try {
                    const payload = Object.assign(values, { periodEnd: values.periodEnd.toDate() });
                    await postAdminPurchaseByID(this.props.purchaseRecord!._id, payload);

                    this.props.close(true);
                    this.reset();
                } catch (e) {
                    console.warn('purchase record update failure:', e);

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (e && (e as any).error) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        message.warn((e as any).error);
                    } else {
                        message.warn('failed to update the purchase record');
                    }
                } finally {
                    this.setState({ isSaving: false });
                }
            });
        });
    }

    get statusDropdown() {
        const { purchaseRecord } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;

        let disabled = false;
        if (purchaseRecord && purchaseRecord.subscriptionInfo && (purchaseRecord.subscriptionInfo.externallyManaged || getFieldValue('externallyManaged'))) {
            disabled = true;
        }

        return (
            <Form.Item label="Status">
                {getFieldDecorator('status', {
                    initialValue: this.props.purchaseRecord && this.props.purchaseRecord.subscriptionInfo ? this.props.purchaseRecord.subscriptionInfo.status : '',
                    rules: [{ required: true, message: 'Please select the subscription status' }],
                })(
                    <Select placeholder="Please set a status" disabled={disabled}>
                        <Select.Option value={PurchaseSubscriptionStatus.Trialing}>Trialing</Select.Option>
                        <Select.Option value={PurchaseSubscriptionStatus.Cancelled}>Cancelled</Select.Option>
                        <Select.Option value={PurchaseSubscriptionStatus.Cancelling}>Cancelling</Select.Option>
                        <Select.Option value={PurchaseSubscriptionStatus.Active} disabled>Active</Select.Option>
                        <Select.Option value={PurchaseSubscriptionStatus.PastDue} disabled>Past Due</Select.Option>
                    </Select>
                )}
            </Form.Item>
        );
    }

    disabledPeriodEnd = (current: moment.Moment | null) => {
        return moment().add(-1, 'days') >= (current || moment());
    }

    get isPeriodEndDisabled() {
        const { purchaseRecord, app } = this.props;
        const { getFieldValue } = this.props.form;

        if (app && app.isBundle) {
            return true;
        }

        //if externally managed was false but now it is true, return false
        if (purchaseRecord && purchaseRecord.subscriptionInfo && (purchaseRecord.subscriptionInfo.externallyManaged || getFieldValue('externallyManaged'))) {
            return false;
        }

        return getFieldValue('status') !== 'trialing';
    }

    get periodEndPicker() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Period End">
                {getFieldDecorator('periodEnd', {
                    initialValue: this.props.purchaseRecord && this.props.purchaseRecord.subscriptionInfo ? moment(this.props.purchaseRecord.subscriptionInfo.periodEnd) : moment(),
                    rules: [{ required: true, message: 'Please select the period end date' }],
                })(
                    <DatePicker
                        showTime
                        showToday
                        disabledDate={this.disabledPeriodEnd}
                        disabled={this.isPeriodEndDisabled}
                    />
                )}
            </Form.Item>
        );
    }

    get seatsInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Seats">
                {getFieldDecorator('seats', {
                    initialValue: this.props.purchaseRecord && this.props.purchaseRecord.subscriptionInfo ? this.props.purchaseRecord.subscriptionInfo.seats : 1,
                    rules: [{ required: true, message: 'Please input the number of seats' }],
                })(
                    <InputNumber
                        min={1}
                        style={{ width: '100%' }}
                        disabled={this.props.purchaseRecord && this.props.purchaseRecord.subscriptionInfo ? !this.props.purchaseRecord.subscriptionInfo.isSeatBased : false}
                    />
                )}
            </Form.Item>
        );
    }

    onEnableExternallyManagedToggled = (checked: boolean) => {
        // should it not have been externally managed and we are making it externally managed
        // we need to visually change the status to active
        // and the opposite is true, if it was not externally managed and they made it but then flipped it back
        // we need to set the status back to what it was
        if (!this.props.purchaseRecord || !this.props.purchaseRecord.subscriptionInfo || this.props.purchaseRecord.subscriptionInfo.externallyManaged) {
            return;
        }

        this.props.form.setFieldsValue({ status: checked ? PurchaseSubscriptionStatus.Active :  this.props.purchaseRecord.subscriptionInfo.status });
    }

    get enableExternallyManagedToggle() {
        const { getFieldDecorator } = this.props.form;

        let initialValue = false;
        if (this.props.purchaseRecord?.subscriptionInfo) {
            initialValue = this.props.purchaseRecord.subscriptionInfo.externallyManaged;
        }

        return (
            <Form.Item label="Make Externally Managed">
                {getFieldDecorator('externallyManaged', {
                    initialValue,
                    valuePropName: 'checked',
                })(
                    <Switch disabled={initialValue} onChange={this.onEnableExternallyManagedToggled} />
                )}
            </Form.Item>
        );
    }

    get editSubscriptionText() {
        const { purchaseRecord, app } = this.props;

        if (!purchaseRecord || !purchaseRecord.subscriptionInfo || !purchaseRecord.subscriptionInfo.externallyManaged) {
            return null;
        }

        let description = 'Externally managed apps can only have the period end changed.'
        if (app && app.isBundle) {
            description = 'Externally managed bundle subscriptions can not be changed.';
        }

        return (
            <Row gutter={16} style={{ marginBottom: '25px' }}>
                <Col>
                    <Alert showIcon message="Information" type="info" description={description} />
                </Col>
            </Row>
        );
    }

    get makeExternallyManagedWarning() {
        const { purchaseRecord } = this.props;
        const { getFieldValue, isFieldTouched } = this.props.form;

        if (!purchaseRecord || !purchaseRecord.subscriptionInfo || purchaseRecord.subscriptionInfo.externallyManaged) {
            return null;
        }

        if (!getFieldValue('externallyManaged')) {
            return null;
        }

        return (
            <React.Fragment>
                <Row gutter={16} style={{ marginTop: '25px' }}>
                    <Col>
                        <Alert
                            showIcon
                            message="Information"
                            type="info"
                            description="Converting a Purchase Record to externally managed is unable to be reversed. Doing so will make the status active until the end of the period. Please ensure this is what you want to accomplish before you click save."
                        />
                    </Col>
                </Row>
                { !isFieldTouched('periodEnd') ?
                    <Row gutter={16} style={{ marginTop: '25px' }}>
                        <Col>
                            <Alert
                                showIcon
                                message="Warning"
                                type="warning"
                                description="When converting to externally managed, the Period End date must be updated."
                            />
                        </Col>
                    </Row>
                : null }
            </React.Fragment>
        );
    }

    get saveDisabled() {
        const { getFieldValue, isFieldTouched } = this.props.form;

        if (getFieldValue('externallyManaged') && !isFieldTouched('periodEnd')) {
            return true;
        }

        return this.state.isSaving;
    }

    render() {
        return (
            <Drawer
                title="Edit Subscription"
                width={500}
                onClose={this.onClose}
                visible={this.props.visible}
                closable={!this.state.isSaving}
                maskClosable={!this.state.isSaving}
            >
                <Form layout="vertical">
                    { this.editSubscriptionText }
                    <Row gutter={16}>
                        <Col span={12}>{this.statusDropdown}</Col>
                        <Col span={12}>{this.periodEndPicker}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{this.seatsInput}</Col>
                        <Col span={12}>{this.enableExternallyManagedToggle}</Col>
                    </Row>
                    { this.makeExternallyManagedWarning }
                </Form>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} disabled={this.state.isSaving} style={{ marginRight: 8 }}>Cancel</Button>
                    <Button onClick={this.handleSubmit} type="primary" disabled={this.saveDisabled} loading={this.state.isSaving}>Save</Button>
                </div>
            </Drawer>
        );
    }
}

export const EditSubscriptionDrawer = Form.create<IEditSubscriptionDrawerProps>()(EditSubscriptionDrawerBase);
