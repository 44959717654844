import React from 'react';
import { Table, Row, Col, Typography, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IAppActivityLog } from 'models/appActivityLog';
import { getAdminAppAcitivityLogs } from 'api/admin';
import { IRestResponse } from 'models/results/rest';
import { SimpleDateWithTime } from 'components/dates/simpleDate';


interface IAdminActivityLogTableProps {
    appId: string;
}

interface IAdminActivityLogTableState {
    loading: boolean;
    acitivityLogs: IAppActivityLog[];
    total: number;
    page: number;
}

export class AdminAppActivityLogsTable extends React.PureComponent<IAdminActivityLogTableProps, IAdminActivityLogTableState> {
    state: Readonly<IAdminActivityLogTableState> = {
        loading: true,
        acitivityLogs: [],
        total: 0,
        page: 0,
    };

    columns: ColumnProps<IAppActivityLog>[] = [
        { title: 'User', dataIndex: 'userExtra', key: 'userExtra' },
        { title: 'Action', dataIndex: 'action', key: 'action' },
        { title: 'When', dataIndex: 'when', key: 'when', render: (value) => value ? <SimpleDateWithTime date={value} simplier /> : '-' },
        { title: 'Action Extra', dataIndex: 'actionExtra', key: 'actionExtra', ellipsis: true },
    ];

    componentDidMount() {
        this.fetchActivityLogs();
    }

    onPaginationChange = (page: number) => {
        this.setState({ page }, this.fetchActivityLogs);
    };

    fetchActivityLogs = () => {
        if (!this.props.appId) {
            return;
        }

        this.setState({ loading: true }, async () => {
            try {
                let page = this.state.page;
                if (page > 0) {
                    page = page - 1;
                }

                const acitivities: IRestResponse<IAppActivityLog[]> = await getAdminAppAcitivityLogs(this.props.appId, 5, page*5);

                this.setState((prevState) => {
                    return {
                        ...prevState,
                        total: acitivities?.meta?.total || 0,
                        acitivityLogs: acitivities.data,
                    };
                });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (e: any) {
                console.warn('failed to load admin app activity logs:', e);

                if (e?.error && e?.code) {
                    notification.error({ message: `${e.error} (${e.code})` });

                    return;
                }

                notification.error({ message: 'Could not load server response when trying to list app activity logs' });
            } finally {
                this.setState({ loading: false });
            }
        });
    };

    render() {
        return (
            <React.Fragment>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={4}>
                        <Typography.Title level={3}>Activity Logs</Typography.Title>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Table<IAppActivityLog>
                            dataSource={this.state.acitivityLogs}
                            columns={this.columns}
                            rowKey="id"
                            pagination={{
                                hideOnSinglePage: true,
                                pageSize: 5,
                                total: this.state.total,
                                current: this.state.page,
                                onChange: this.onPaginationChange
                            }}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
