import { DeveloperRole, DeveloperSystemRole } from 'models/developer';
import { PurchaseSubscriptionStatus } from 'models/purchaseSubscription';

export interface IAdminAppVersionActionPayload {
    action: AdminAppAction;
    review?: boolean;
    reviewNote?: string;
    publish?: boolean;
    unpublish?: boolean;
    approved?: boolean;
    rejected?: boolean;
    rejectionReason?: string;
}

export enum AdminAppAction {
    MakeDraft = 'make-draft',
    Approve = 'approve',
    Reject = 'reject',
    Review = 'review',
    Unpublish = 'unpublish',
    Publish = 'publish',
    Compile = 'compile',
}

export interface IAdminUpdatePurchaseRecordPayload {
    status: PurchaseSubscriptionStatus;
    periodEnd: string;
    seats: number;
}

export interface IAdminCreatePurchaseRecordPayload {
    appId: string;
    workspaceId: string;
    externallyManaged: boolean;
    planId: string;
    seats: number;
    addonTierId: string;
    periodEnd: string;
}

export interface IAdminUpdateDeveloperRolePayload {
    role: DeveloperRole;
}

export interface IAdminUpdateDeveloperSystemRolePayload {
    role: DeveloperSystemRole;
}

export interface IAdminEmailPublisherPayload {
    layout: string;
    subject: string;
    content: string;
    relatedAppId: string;
    replyTo: string;
}

export interface IAdminPayoutPublisherPayload {
    amount: number;
}

export interface IAdminMakeAppFeaturedPayload {
    appId: string;
    sectionSlug: string;
}
