import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Row, Col, Steps, Result, Typography, Spin } from 'antd';

import { NewAppStepOneView } from '../NewAppExtended/step1';
import { UpdateAppStepTwoView } from '../NewAppExtended/step2-update';
import { NewAppStepThreeView } from '../NewAppExtended/step3';
import { IPublisherAppOverview } from 'models/appOverview';

import { getAppVersion } from 'api/apps';

interface IEditDetailsViewRouteProps extends RouteComponentProps<{ appId: string, versionId: string, step: string }> {
    appId?: string;
    versionId?: string;
    step?: string;
}

interface IEditDetailsViewState {
    loading: boolean;
    currentStep: number;
    app?: IPublisherAppOverview;
}

class EditDetailsViewBase extends React.PureComponent<IEditDetailsViewRouteProps, IEditDetailsViewState> {
    state: Readonly<IEditDetailsViewState> = {
        loading: true,
        currentStep: 0,
    };

    async componentDidMount() {
        if (this.props.match.params.appId && this.props.match.params.versionId) {
            try {
                const app = await getAppVersion(this.props.match.params.appId, this.props.match.params.versionId);

                let nextStep = 0;
                if (this.props.match.params.step) {
                    nextStep = parseInt(this.props.match.params.step);
                }

                this.setState({ app, loading: false, currentStep: nextStep });
            } catch (e) {
               this.setState({ loading: false });
            }
        } else {
            this.setState({ loading: false });
        }
    }

    setApp = (app: IPublisherAppOverview) => {
        return new Promise<void>((resolve) => {
            this.setState({ app }, resolve);
        });
    }

    next = () => {
        if (this.state.currentStep === 2) {
            this.props.history.push(`/publisher/apps/${this.props.match.params.appId}`);
            return;
        }

        const nextStep = this.state.currentStep + 1;

        this.setState({ currentStep: nextStep });

        if (this.state.app) {
            this.props.history.push(`/publisher/edit-details/app/${this.state.app.appId}/${this.state.app.latest.internalId}/${nextStep}`);
        }
    }

    back = () => {
        if (this.state.currentStep === 0) {
            return;
        }

        const nextStep = this.state.currentStep - 1;

        this.setState({ currentStep: nextStep });

        if (this.state.app) {
            this.props.history.push(`/publisher/edit-details/app/${this.state.app.appId}/${this.state.app.latest.internalId}/${nextStep}`);
        }
    }

    get content() {
        switch (this.state.currentStep) {
            case 0:
                return <UpdateAppStepTwoView app={this.state.app} nextText="Save and continue" goNext={this.next} setApp={this.setApp} />;
            case 1:
                return <NewAppStepOneView app={this.state.app} nextText="Save and continue" goNext={this.next} goBack={this.back} setApp={this.setApp} />;
            case 2:
                return <NewAppStepThreeView app={this.state.app} nextText="Save and finish" goNext={this.next} goBack={this.back} setApp={this.setApp} />;
            default:
                return (
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, you seemed to have arrived at a step which does not exist. Huh!"
                    />
                );
        }
    }

    get currentTitle() {
        switch (this.state.currentStep) {
            case 0:
                return 'Changes';
            case 1:
                return 'Details';
            case 2:
                return 'App Info';
            default:
                return '';
        }
    }

    render() {
        return (
            <Layout className="newapp-extended">
                <Spin spinning={this.state.loading}>
                    <Layout.Header className="newapp-header">
                        <h2>Edit app details</h2>
                    </Layout.Header>

                    <Row>
                        <Col xs={24} lg={20} xl={18}>
                            <Steps size="small" current={this.state.currentStep}>
                                <Steps.Step title="Changes" />
                                <Steps.Step title="Details" />
                                <Steps.Step title="App info" />
                            </Steps>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="new-extended-app-content" xs={24} lg={13}>
                            <Typography.Title level={4}> {this.currentTitle} </Typography.Title>

                            {this.content}
                        </Col>
                    </Row>
                </Spin>
            </Layout>
        )
    }
}

export const EditDetailsView = withRouter(EditDetailsViewBase);
