import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Layout, Row, Col, Steps, Result, Typography, Spin } from 'antd';

import { IPublisherAppOverview } from 'models/appOverview';

import { NewAppStepZeroView } from './step0';
import { NewAppStepOneView } from './step1';
import { NewAppStepTwoView } from './step2';
import { NewAppStepThreeView } from './step3';
import { NewAppStepFourView } from './step4';

import { getAppVersion } from 'api/apps';

import './newappextended.less';

interface INewAppExtendedViewRouteProps {
    appId?: string;
    versionId?: string;
    step?: string;
}

interface INewAppExtendedViewState {
    loading: boolean;
    currentStep: number;
    app?: IPublisherAppOverview;
}

class NewAppExtendedViewBase extends React.PureComponent<RouteComponentProps<INewAppExtendedViewRouteProps>, INewAppExtendedViewState> {
    state: Readonly<INewAppExtendedViewState> = {
        loading: true,
        currentStep: 0,
    };

    async componentDidMount() {
        console.log('path params:', this.props.match.params);

        if (this.props.match.params.appId && this.props.match.params.versionId) {
            try {
                const app = await getAppVersion(this.props.match.params.appId, this.props.match.params.versionId);

                let nextStep = 1;
                if (this.props.match.params.step) {
                    nextStep = parseInt(this.props.match.params.step);
                }

                this.setState({ app, loading: false, currentStep: nextStep });
            } catch (e) {
                //TODO: display error
            }
        } else {
            this.setState({ loading: false });
        }
    }

    componentDidUpdate() {
        console.log('component updated with path params:', this.props.match.params);
    }

    get header() {
        return (
            <Layout.Header className="newapp-header">
                <h2>Publish a New App</h2>
            </Layout.Header>
        )
    }

    get steps() {
        return (
            <Steps size="small" current={this.state.currentStep}>
                <Steps.Step title="Upload" />
                <Steps.Step title="Details" />
                <Steps.Step title="Pricing" />
                <Steps.Step title="Screenshots" />
                <Steps.Step title="Submission" />
            </Steps>
        );
    }

    setApp = (app: IPublisherAppOverview) => {
        return new Promise<void>((resolve) => {
            this.setState({ app }, resolve);
        });
    }

    goNext = () => {
        //last step is step 5 which is index 0 based, so 4
        if (this.state.currentStep === 4) {
            return;
        }

        const nextStep = this.state.currentStep + 1;

        this.setState({ currentStep: nextStep });

        if (this.state.app) {
            this.props.history.push(`/publisher/new-extended/app/${ this.state.app.appId }/${ this.state.app.latest.internalId }/${ nextStep }`);
        }
    }

    goBack = () => {
        //can not go back past step 0 (aka step 1)
        if (this.state.currentStep === 0) {
            return;
        }

        const nextStep = this.state.currentStep - 1;

        this.setState({ currentStep: nextStep });

        if (this.state.app) {
            this.props.history.push(`/publisher/new-extended/app/${ this.state.app.appId }/${ this.state.app.latest.internalId }/${ nextStep }`);
        }
    }

    get stepContent() {
        switch (this.state.currentStep) {
            case 0:
                return <NewAppStepZeroView goNext={this.goNext} setApp={this.setApp} />;
            case 1:
                return <NewAppStepOneView app={this.state.app} nextText="Next" goNext={this.goNext} goBack={this.goBack} setApp={this.setApp} />;
            case 2:
                return <NewAppStepTwoView app={this.state.app} goNext={this.goNext} goBack={this.goBack} setApp={this.setApp} />;
            case 3:
                return <NewAppStepThreeView app={this.state.app} nextText="Next" goNext={this.goNext} goBack={this.goBack} setApp={this.setApp} />;
            case 4:
                return <NewAppStepFourView app={this.state.app} goBack={this.goBack} />;
            default:
                return (
                    <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, you seemed to have arrived at a step which does not exist. Huh!"
                    />
                );
        }
    }

    get stepTitle() {
        switch (this.state.currentStep) {
            case 0:
                return 'Upload App Package';
            case 1:
                return 'App Details';
            case 2:
                return 'Pricing Information';
            case 3:
                return 'App Info';
            case 4:
                return 'Submit for Review';
            default:
                return '???';
        }
    }

    render() {
        return (
            <Layout className="newapp-extended">
                <Spin spinning={this.state.loading}>
                    {this.header}

                    <Row>
                        <Col xs={24} lg={20} xl={18}>
                            {this.steps}
                        </Col>
                    </Row>

                    <Row>
                        <Col className="new-extended-app-content" xs={24} lg={13}>
                            <Typography.Title level={4}>{ this.stepTitle }</Typography.Title>

                            {this.stepContent}
                        </Col>
                    </Row>
                </Spin>
            </Layout>
        );
    }
}

export const NewAppExtendedView = withRouter(NewAppExtendedViewBase);
