import React from 'react';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';
import { Layout, Table, Tag, Button, notification } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { ColumnProps } from 'antd/lib/table';

import { IPurchaseRecord } from 'models/purchaseRecord';
import { AdminAppMap } from 'models/appOverview';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import { ViewPurchaseRecordModal } from 'components/purchaseInfoModal';
import { debounce } from 'utils/debounce';

import { getAdminPurchases, getAdminApps, getAdminBundles } from 'api/admin';

import { CreatePurchaseRecordModal } from './createPurchaseRecord';

interface IPurchasesListProps extends RouteComponentProps<{ purchaseId: string }>, IWithPermissionProps {}

interface IPurchasesListState {
    loading: boolean;
    purchases: IPurchaseRecord[];
    pagination: PaginationProps;
    appsById: AdminAppMap;
    createVisible: boolean;
}

class PurchasesListBase extends React.PureComponent<IPurchasesListProps, IPurchasesListState> {
    state: Readonly<IPurchasesListState> = {
        loading: true,
        purchases: [],
        pagination: { hideOnSinglePage: true },
        appsById: {},
        createVisible: false,
    };

    constructor(props: IPurchasesListProps) {
        super(props);

        this.setTableItemsLimit = debounce(this.setTableItemsLimit, 300);
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            pagination: {
                ...this.state.pagination,
                pageSize: this.tableItemsLimit,
                onChange: this.onPageChange
            },
        });

        window.addEventListener('resize', this.setTableItemsLimit);

        this.loadData();
    }

    componentDidUpdate(prevProps: IPurchasesListProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setTableItemsLimit);
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:purchaseRecords'].canRead) {
            console.log('user can not read the system:purchaseRecords');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Purchase Records. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const apps = await getAdminApps();
        const bundles = await getAdminBundles();

        const appsById: AdminAppMap = {};
        for(let i = 0; i < apps.length; i++) {
            const app = apps[i];
            appsById[app.appId] = app;
        }

        for(let i = 0; i < bundles.length; i++) {
            const bundle = bundles[i];
            appsById[bundle.appId] = bundle;
        }

        this.setState({ appsById });

        this.refreshPurchases();
    }

    refreshPurchases = () => {
        this.setState({ loading: true }, async () => {
            const { pageSize, current } = this.state.pagination;

            let offset = 0;
            if (current && current > 0) {
                offset = (pageSize ? pageSize : 25) * current;
            }

            const { data, meta } = await getAdminPurchases(pageSize, offset);

            this.setState({
                purchases: data,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    total: meta ? meta.total : 25,
                }
            });
        });
    }

    setTableItemsLimit = () => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                pageSize: this.tableItemsLimit,
            }
        }, this.refreshPurchases);
    }

    get tableItemsLimit() {
        const windowHeight = window.innerHeight;
        const headerEl = document.querySelector('.applist-header');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const headerHeight = headerEl && (headerEl as any).offsetHeight ? (headerEl as any).offsetHeight : 0;
        const contentStyles = window.getComputedStyle(document.querySelector('.ant-layout-content')!);
        const contentPaddings = Number(contentStyles.paddingTop.replace('px', '')) * 2;

        return Math.floor((windowHeight - headerHeight - contentPaddings) / 40 - 2);
    }

    onPageChange(page: number) {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: page,
            },
        }, this.refreshPurchases);
    }

    getPriceColumn = (text: void, pr: IPurchaseRecord) => {
        const app = this.state.appsById[pr.appId];

        if (!app) {
            return 'n/a';
        }

        if (pr.purchaseType === 'buy' || !pr.subscriptionInfo) {
            if (app.price > 0) {
                return `$${ app.price.toFixed(2) }`;
            }

            return 'FREE';
        }

        const { subscriptionInfo } = pr;

        const periodEnd = new Date(subscriptionInfo.periodEnd);
        if (subscriptionInfo.externallyManaged) {
            return `Externally managed (${ periodEnd.toLocaleString() })`;
        }

        return (
            <div>
                {subscriptionInfo.status === 'active' ? <Tag color="green">active</Tag> : <Tag color="magenta">{ subscriptionInfo.status }</Tag>}
                { subscriptionInfo.isSeatBased ? <Tag>{ `${ subscriptionInfo.seats }/${ subscriptionInfo.maxSeats } seats` }</Tag> : null }
                <Tag>{ `${ periodEnd.getMonth() + 1 }/${ periodEnd.getDate() }/${ periodEnd.getFullYear() }` }</Tag>
            </div>
        );
    }

    columns: ColumnProps<IPurchaseRecord>[] = [
        {
            key: 'name', title: 'App Name',
            render: (text, record) => this.state.appsById[record.appId] ? this.state.appsById[record.appId].latest.name : 'n/a',
        },
        {
            key: 'date', title: 'Created', dataIndex: 'date', defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            key: 'price', title: 'Pricing Info',
            render: this.getPriceColumn,
        },
        {
            key: 'details', title: 'View Details',
            render: (nothing, record) => <Link to={`/publisher/admin/purchases/${ record._id }`}>Details</Link>,
        },
    ]

    toggleCreationModal = () => {
        this.refreshPurchases();

        this.setState({ createVisible: !this.state.createVisible });
    }

    closeDetailsModal = () => {
        this.refreshPurchases();

        this.props.history.push({ pathname: '/publisher/admin/purchases' });
    }

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Purchase Records { this.state.loading ? null : `(${ this.state.pagination.total })` }</h2>

                    <Button.Group>
                        { this.props.permissions.has('system:purchaseRecords', 'create', 'system') ? <Button type="dashed" icon="plus" onClick={this.toggleCreationModal}>Create</Button> : null }
                        <Button type="primary" icon="cloud-sync" onClick={this.refreshPurchases} style={{marginRight: '15px'}}>Refresh</Button>
                    </Button.Group>
                </Layout.Header>
                <Layout.Content>
                    <Table
                        columns={this.columns}
                        pagination={this.state.pagination}
                        dataSource={this.state.purchases}
                        rowKey={(p) => p._id}
                        loading={this.state.loading}
                    />

                    <ViewPurchaseRecordModal visible={typeof this.props.match.params.purchaseId === 'string' && this.props.match.params.purchaseId !== ''} purchaseRecordId={this.props.match.params.purchaseId} onClose={this.closeDetailsModal} />
                    <CreatePurchaseRecordModal visible={this.state.createVisible} close={this.toggleCreationModal} />
                </Layout.Content>
            </Layout>
        )
    }
}

export const AdminPurchasesListView = connectPermissions({ feature: 'system:purchaseRecords', system: true })(withRouter(PurchasesListBase));
