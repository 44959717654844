import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Button, notification } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import { IAppOverview } from 'models/appOverview';
import { IPublisher } from 'models/publisher';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

import AppsTable from '../AppsList/AppsTable';

import { getBundles } from 'api/apps';
import { getPublisher } from 'api/publisher';

interface IBundlesListProps extends RouteComponentProps, IWithPermissionProps { }

interface IBundlesListState {
    loading: boolean;
    pagination: PaginationProps;
    bundles: IAppOverview[];
    publisher?: IPublisher;
}

class BundlesListBase extends React.PureComponent<IBundlesListProps, IBundlesListState> {
    state: Readonly<IBundlesListState> = {
        loading: true,
        pagination: {
            hideOnSinglePage: true,
        },
        bundles: [],
    };

    async componentDidMount() {
        const publisher = await getPublisher();
        if (!publisher || !publisher.canCreateBundles) {
            notification.error({
                message: 'Bundle Creation not Available',
                description: 'Bundle creation has not yet been enabled for you. Please contact Rocket.Chat Support if you are interested in creating bundles.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const bundles = await getBundles();

        this.setState({
            bundles,
            publisher,
            loading: false,
        });
    }

    onNewBundleClick = () => {
        if (!this.state.publisher || !this.state.publisher.canCreateBundles) {
            notification.error({
                message: 'Bundle Creation not Available',
                description: 'Bundle creation has not yet been enabled for you. Please contact Rocket.Chat Support if you are interested in creating bundles.',
            });

            return;
        }

        this.props.history.push({ pathname: '/publisher/new/bundle' });
    }

    get headerButton() {
        if (!this.props.permissions.features.bundle.canCreate) {
            return null;
        }

        return (
            <div className="applist-header-buttons">
                <Button type="default" icon="plus" onClick={this.onNewBundleClick} disabled={!this.state.publisher || !this.state.publisher.canCreateBundles}>New Bundle</Button>
            </div>
        );
    }

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Bundles</h2>

                    {this.headerButton}
                </Layout.Header>
                <Layout.Content>
                    <AppsTable
                        type="bundle"
                        tableProps={{
                            loading: this.state.loading,
                            dataSource: this.state.bundles,
                            rowKey: (record) => record.latest.id,
                        }}
                        canUpdate={this.props.permissions.features.bundle.canUpdate}
                    />
                </Layout.Content>
            </Layout>
        )
    }
}

export const BundlesListView = connectPermissions({ feature: 'bundle' })(withRouter(BundlesListBase));
