import React from 'react';
import { Button, Input, Modal } from 'antd';

import { IWebhookActivityLog } from 'models/webhookActivityLog';

interface IWebhookEventPayloadViewerModalProps {
    visible: boolean;
    activityLog?: IWebhookActivityLog;
    close: () => void;
}

export const WebhookEventPayloadViewerModal: React.FC<IWebhookEventPayloadViewerModalProps> = (props) => {
    let data = 'no data?';
    if (props.activityLog && props.activityLog.event) {
        data = JSON.stringify(props.activityLog.event, null, 2);
    }

    return (
        <Modal
            visible={props.visible}
            title={props.activityLog ? `Payload for: ${ props.activityLog.event.id }` : 'Payload Viewer'}
            onCancel={props.close}
            footer={<Button type="default" onClick={props.close}>Close</Button>}
        >
            <Input.TextArea
                readOnly
                value={data}
                rows={15}
            />
        </Modal>
    );
}
