import React from "react";
import { Modal, Form, Select, Spin, notification } from "antd";
import { FormComponentProps } from "antd/lib/form";

import { ILookup } from "models/common/lookup";

import { getPublisherUsers, addPublisherUser } from "api/publisher";

interface IAddDeveloperModalProps extends FormComponentProps {
    visible: boolean;
    onCancel: () => void;
    onSave: () => void;
}

interface IAddDeveloperModalState {
    loading: boolean;
    saving: boolean;
    users: ILookup[];
}

class AddDeveloperModalBase extends React.PureComponent<
    IAddDeveloperModalProps,
    IAddDeveloperModalState
> {
    state: Readonly<IAddDeveloperModalState> = {
        loading: true,
        saving: false,
        users: [],
    };

    async componentDidUpdate(prevProps: IAddDeveloperModalProps) {
        if (!prevProps.visible && this.props.visible) {
            try {
                const users = await getPublisherUsers();

                this.setState({
                    loading: false,
                    saving: false,
                    users,
                });
            } catch (e) {
                console.error("error while getting the publisher users:", e);
                notification.error({
                    message: "An Error Occured",
                    description: (
                        <span>
                            An error occured processing your request.
                            <br />
                            Code: {e.code}
                            <br />
                            Error: {e.error}
                        </span>
                    ),
                });

                this.props.onCancel();
            }
        }
    }

    get userSelector() {
        const { getFieldDecorator } = this.props.form;

        const notAdded = this.state.users.filter(
            (u) => u.id === "" || u.id === "000000000000000000000000"
        );
        const added = this.state.users.filter(
            (u) => u.id !== "" && u.id !== "000000000000000000000000"
        );

        return (
            <Form.Item label="User">
                {getFieldDecorator("userCloudId", {
                    initialValue: "",
                    rules: [
                        {
                            required: true,
                            message: "Please select the user to add.",
                        },
                    ],
                })(
                    <Select disabled={this.state.loading}>
                        <Select.OptGroup label="Not Added Yet">
                            {notAdded.map((u) => (
                                <Select.Option
                                    key={u.cloudId}
                                    value={u.cloudId}
                                >
                                    {u.label}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>

                        <Select.OptGroup label="Already Added Users">
                            {added.map((u) => (
                                <Select.Option
                                    key={u.cloudId}
                                    value={u.cloudId}
                                    disabled
                                >
                                    {u.label}
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    </Select>
                )}
            </Form.Item>
        );
    }

    get roleSelector() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Role">
                {getFieldDecorator("role", {
                    initialValue: "",
                    rules: [
                        {
                            required: true,
                            message:
                                "Please select what role the new user should be.",
                        },
                    ],
                })(
                    <Select disabled={this.state.loading}>
                        <Select.Option value="viewer">Viewer</Select.Option>
                        <Select.Option value="developer">
                            Developer
                        </Select.Option>
                        <Select.Option value="owner">Owner</Select.Option>
                    </Select>
                )}
            </Form.Item>
        );
    }

    saveSelection = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ saving: true }, async () => {
                console.log("form values:", values);

                try {
                    await addPublisherUser(values);
                    notification.success({ message: "Successfully added!" });

                    this.props.onSave();
                    this.props.form.resetFields();
                } catch (e) {
                    this.setState({ saving: false });

                    console.error(
                        "error while adding the user to the publisher:",
                        e
                    );
                    notification.error({
                        message: "An Error Occured",
                        description: (
                            <span>
                                An error occured processing your request.
                                <br />
                                Code: {e.code}
                                <br />
                                Error: {e.error}
                            </span>
                        ),
                    });
                }
            });
        });
    };

    render() {
        return (
            <Modal
                title="Add a User"
                visible={this.props.visible}
                onOk={this.saveSelection}
                onCancel={this.props.onCancel}
                closable={!this.state.loading}
                maskClosable={!this.state.loading || !this.state.saving}
                keyboard={!this.state.loading || !this.state.saving}
                okText="Add"
                okButtonProps={{
                    disabled: this.state.loading || this.state.saving,
                    loading: this.state.saving,
                }}
                cancelButtonProps={{
                    disabled: this.state.loading || this.state.saving,
                }}
            >
                <Form layout="vertical">
                    <Spin spinning={this.state.loading}>
                        {this.userSelector}

                        {this.roleSelector}
                    </Spin>
                </Form>
            </Modal>
        );
    }
}

export const AddDeveloperModal = Form.create<IAddDeveloperModalProps>()(
    AddDeveloperModalBase
);
