import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Layout, Table, Tag } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';
import { ColumnProps } from 'antd/lib/table';

import { IPurchaseRecord } from 'models/purchaseRecord';
import { PublisherAppMap } from 'models/appOverview';

import { getPurchases } from 'api/publisher';
import { getApps } from 'api/apps';

import { debounce } from 'utils/debounce';

interface IPurchasesListProps extends RouteComponentProps {}

interface IPurchasesListState {
    loading: boolean;
    purchases: IPurchaseRecord[];
    pagination: PaginationProps;
    appsById: PublisherAppMap;
    createVisible: boolean;
}

class PurchasesListBase extends React.PureComponent<IPurchasesListProps, IPurchasesListState> {
    state: Readonly<IPurchasesListState> = {
        loading: true,
        purchases: [],
        pagination: {},
        appsById: {},
        createVisible: false,
    };

    constructor(props: IPurchasesListProps) {
        super(props);

        this.setTableItemsLimit = debounce(this.setTableItemsLimit, 300);
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            pagination: {
                ...this.state.pagination,
                pageSize: this.tableItemsLimit,
                onChange: this.onPageChange
            },
        });

        window.addEventListener('resize', this.setTableItemsLimit);

        this.loadData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setTableItemsLimit);
    }

    loadData = async () => {
        const apps = await getApps();

        const appsById: PublisherAppMap = {};
        for(let i = 0; i < apps.length; i++) {
            const app = apps[i];
            appsById[app.appId] = app;
        }

        this.setState({ appsById });

        this.refreshPurchases();
    }

    getPriceColumn = (text: void, pr: IPurchaseRecord) => {
        const app = this.state.appsById[pr.appId];

        if (!app) {
            return 'n/a';
        }

        if (pr.purchaseType === 'buy' || !pr.subscriptionInfo) {
            if (app.price > 0) {
                return `$${ app.price.toFixed(2) }`;
            }

            return 'FREE';
        }

        const { subscriptionInfo } = pr;

        const periodEnd = new Date(subscriptionInfo.periodEnd);
        if (subscriptionInfo.externallyManaged) {
            return `Externally managed (${ periodEnd.toLocaleString() })`;
        }

        return (
            <div>
                {subscriptionInfo.status === 'active' ? <Tag color="green">active</Tag> : <Tag color="magenta">{ subscriptionInfo.status }</Tag>}
                { subscriptionInfo.isSeatBased ? <Tag>{ `${ subscriptionInfo.seats }/${ subscriptionInfo.maxSeats } seats` }</Tag> : null }
                <Tag>{ `${ periodEnd.getMonth() + 1 }/${ periodEnd.getDate() }/${ periodEnd.getFullYear() }` }</Tag>
            </div>
        );
    }

    columns: ColumnProps<IPurchaseRecord>[] = [
        {
            key: 'name', title: 'App Name',
            render: (text, record) => this.state.appsById[record.appId] ? this.state.appsById[record.appId].latest.name : 'n/a',
        },
        {
            key: 'date', title: 'Created', dataIndex: 'date', defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            key: 'price', title: 'Pricing Info',
            render: this.getPriceColumn,
        },
        //TODO: improve this; sadly we can't do it until we have an api for workspaces
        // {
        //     key: 'details', title: 'View Details',
        //     render: (nothing, record) => <Button type="link" onClick={() => this.setViewingPurchaseRecord(record)}>Details</Button>,
        // },
    ]

    refreshPurchases = () => {
        this.setState({ loading: true }, async () => {
            const { pageSize, current } = this.state.pagination;

            let offset = 0;
            if (current && current > 0) {
                offset = (pageSize ? pageSize : 25) * current;
            }

            const { data, meta } = await getPurchases(pageSize, offset);

            this.setState({
                purchases: data,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    total: meta ? meta.total : 25,
                }
            });
        });
    }

    setTableItemsLimit = () => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                pageSize: this.tableItemsLimit,
            }
        }, this.refreshPurchases);
    }

    get tableItemsLimit() {
        const windowHeight = window.innerHeight;
        const headerEl = document.querySelector('.applist-header');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const headerHeight = headerEl && (headerEl as any).offsetHeight ? (headerEl as any).offsetHeight : 0;
        const contentStyles = window.getComputedStyle(document.querySelector('.ant-layout-content')!);
        const contentPaddings = Number(contentStyles.paddingTop.replace('px', '')) * 2;

        return Math.floor((windowHeight - headerHeight - contentPaddings) / 40 - 2);
    }

    onPageChange(page: number) {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: page,
            },
        }, this.refreshPurchases);
    }

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Purchase Records { this.state.loading ? null : `(${ this.state.pagination.total })` }</h2>

                    <Button.Group>
                        {/* <Button type="dashed" icon="plus" onClick={this.toggleCreationModal}>Create</Button> */}
                        <Button type="primary" icon="cloud-sync" onClick={this.refreshPurchases} style={{marginRight: '15px'}}>Refresh</Button>
                    </Button.Group>
                </Layout.Header>
                <Layout.Content>
                    <Table
                        columns={this.columns}
                        pagination={this.state.pagination}
                        dataSource={this.state.purchases}
                        rowKey={(p) => p._id}
                        loading={this.state.loading}
                    />

                    {/* <CreatePurchaseRecordModal visible={this.state.createVisible} close={this.toggleCreationModal} /> */}
                </Layout.Content>
            </Layout>
        );
    }
}

export const PurchasesListView = withRouter(PurchasesListBase);
