import React from 'react';
import { Button, Icon, Typography } from 'antd';

import { IPrice } from 'models/appPrice';

interface IChangePurchaseTypeToFreeConfirmationProps {
    visible: boolean;
    price?: IPrice;
    onCancelClick: () => void;
    onChangeClick: () => void;
}

export const ChangePurchaseTypeToFreeConfirmation: React.FC<IChangePurchaseTypeToFreeConfirmationProps> = (props) => {
    if (!props.visible) {
        return null;
    }

    return (
        <React.Fragment>
            <div
                style={{
                    position: 'absolute',
                    top: '55px',
                    left: '0px',
                    backgroundColor: 'white',
                    width: '100%',
                    height: 'calc(100% - 112px)',
                    padding: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    zIndex: 10000,
                }}
            >
                <Icon type="warning" style={{ fontSize: '36px' }} />
                <Typography.Title level={3}>Are you sure you want to change purchase type to free?</Typography.Title>
                <Typography.Paragraph>This change will automatically cancel all { props.price ? props.price.actives : 0 } <br /> current subscriptions.</Typography.Paragraph>
            </div>

            <div
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    zIndex: 10000,
                }}
            >
                <Button onClick={props.onCancelClick} style={{ flex: 1, marginRight: '8px' }}>Cancel</Button>
                <Button type="danger" onClick={props.onChangeClick} style={{ flex: 1 }}>Yes, change now</Button>
            </div>
        </React.Fragment>
    );
}
