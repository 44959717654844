import { configureStore } from '@reduxjs/toolkit';

import accessControlReducer from './slices/accessControl';

export const store = configureStore({
  reducer: {
    accessControl: accessControlReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
