import React from 'react';

import './index.less';

const NewRocketChatLogo: React.FC = () => (
    <svg height="33" viewBox="0 0 768 221" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M687.178 68.3765H699.452V85.2808H710.808V96.4023H699.452V126.607H710.193V137.528C708.453 137.935 706.305 138.141 703.849 138.141C692.7 138.141 687.174 132.326 687.174 120.692V68.3765H687.178Z" fill="#F5455C" />
        <path d="M663.995 92.7303V85.2809H676.268V136.708H663.995V129.259C662.255 134.259 656.017 137.83 647.526 137.83C640.265 137.83 634.123 135.28 629.111 130.179C624.2 124.973 621.744 118.65 621.744 110.994C621.744 103.339 624.2 97.0159 629.111 91.9107C634.123 86.7048 640.26 84.1545 647.526 84.1545C656.013 84.1591 662.255 87.7305 663.995 92.7303ZM660.309 122.221C663.38 119.263 664.913 115.486 664.913 110.999C664.913 106.512 663.38 102.735 660.309 99.7722C657.344 96.8145 653.557 95.2806 649.16 95.2806C644.763 95.2806 641.183 96.8099 638.213 99.7722C635.349 102.73 633.917 106.507 633.917 110.999C633.917 115.491 635.349 119.263 638.213 122.221C641.178 125.179 644.758 126.713 649.16 126.713C653.562 126.713 657.344 125.179 660.309 122.221Z" fill="#F5455C" />
        <path d="M209.724 136.708V85.2808H221.896V92.9317C224.352 87.5243 229.979 84.2598 237.24 84.2598C238.672 84.2598 239.903 84.3605 240.922 84.5665V96.4023C239.388 96.0955 237.649 95.8941 235.808 95.8941C227.317 95.8941 221.896 101.201 221.896 109.671V136.713H209.724V136.708Z" fill="#F5455C" />
        <path d="M243.992 110.995C243.992 103.339 246.755 96.8099 252.176 91.604C257.597 86.3981 264.248 83.7471 272.124 83.7471C280 83.7471 286.651 86.3981 292.072 91.604C297.493 96.8099 300.256 103.339 300.256 110.995C300.256 118.645 297.493 125.179 292.072 130.38C286.651 135.586 280 138.237 272.124 138.237C264.248 138.237 257.597 135.586 252.176 130.38C246.755 125.179 243.992 118.645 243.992 110.995ZM283.479 122.524C286.55 119.36 288.083 115.582 288.083 110.995C288.083 106.402 286.55 102.625 283.479 99.5662C280.409 96.4024 276.627 94.8731 272.124 94.8731C267.52 94.8731 263.738 96.4024 260.667 99.5662C257.702 102.629 256.165 106.402 256.165 110.995C256.165 115.587 257.698 119.36 260.667 122.524C263.738 125.587 267.52 127.116 272.124 127.116C276.627 127.116 280.409 125.587 283.479 122.524Z" fill="#F5455C" />
        <path d="M351.3 88.852V101.095C346.903 96.9104 341.583 94.7676 335.446 94.7676C330.641 94.7676 326.647 96.2969 323.48 99.36C320.309 102.423 318.776 106.301 318.776 110.889C318.776 115.481 320.309 119.359 323.48 122.418C326.652 125.481 330.641 127.01 335.446 127.01C341.689 127.01 347.004 124.868 351.3 120.683V132.926C347.004 136.397 341.482 138.132 334.73 138.132C326.753 138.132 320.102 135.582 314.682 130.376C309.261 125.17 306.599 118.741 306.599 110.884C306.599 103.027 309.256 96.5991 314.682 91.3932C320.102 86.1873 326.753 83.637 334.73 83.637C341.377 83.6461 346.903 85.3814 351.3 88.852Z" fill="#F5455C" />
        <path d="M360.407 136.708V68.3765H372.58V107.217L389.971 85.2762H403.883L384.344 109.868L405.517 136.703H391.197L372.58 112.927V136.703H360.407V136.708Z" fill="#F5455C" />
        <path d="M405.824 110.994C405.824 102.931 408.28 96.4023 413.292 91.5031C418.304 86.5033 424.749 84.0537 432.524 84.0537C439.992 84.0537 446.028 86.5033 450.631 91.5031C455.336 96.4023 457.691 102.524 457.691 109.767C457.691 111.297 457.59 112.83 457.484 114.154H417.997C418.405 122.317 424.441 127.523 433.548 127.523C441.63 127.523 447.767 125.586 452.063 121.603V133.031C447.051 136.502 440.708 138.237 433.038 138.237C424.955 138.237 418.309 135.788 413.297 130.994C408.284 126.095 405.829 119.566 405.829 111.503V110.994H405.824ZM445.206 105.486C445.206 102.528 443.98 99.8729 441.525 97.7301C439.069 95.5873 436.104 94.4655 432.524 94.4655C428.737 94.4655 425.465 95.5873 422.706 97.7301C419.943 99.8729 418.511 102.423 418.309 105.486H445.206Z" fill="#F5455C" />
        <path d="M466.944 68.3765H479.218V85.2808H490.573V96.4023H479.218V126.607H489.958V137.528C488.218 137.935 486.07 138.141 483.615 138.141C472.466 138.141 466.939 132.326 466.939 120.692V68.3765H466.944Z" fill="#F5455C" />
        <path d="M502.713 123.215C498.642 123.215 495.342 126.493 495.342 130.536C495.342 134.579 498.642 137.857 502.713 137.857C506.785 137.857 510.085 134.579 510.085 130.536C510.085 126.493 506.785 123.215 502.713 123.215Z" fill="#F5455C" />
        <path d="M558.22 88.852V101.095C553.823 96.9104 548.503 94.7676 542.366 94.7676C537.561 94.7676 533.567 96.2969 530.4 99.36C527.229 102.423 525.696 106.301 525.696 110.889C525.696 115.481 527.229 119.359 530.4 122.418C533.572 125.481 537.561 127.01 542.366 127.01C548.604 127.01 553.924 124.868 558.22 120.683V132.926C553.924 136.397 548.402 138.132 541.65 138.132C533.673 138.132 527.022 135.582 521.601 130.376C516.181 125.17 513.519 118.741 513.519 110.884C513.519 103.027 516.176 96.5991 521.601 91.3932C527.022 86.1873 533.673 83.637 541.65 83.637C548.297 83.6461 553.823 85.3814 558.22 88.852Z" fill="#F5455C" />
        <path d="M567.326 136.708V68.3765H579.499V92.4234C581.853 87.5243 587.476 84.2597 594.535 84.2597C606.4 84.2597 613.359 92.1167 613.359 104.873V136.708H601.186V106.603C601.186 99.5615 597.399 95.1752 590.854 95.1752C584.203 95.1752 579.499 100.074 579.499 107.112V136.703H567.326V136.708Z" fill="#F5455C" />
        <path d="M174.643 91.8055C171.307 86.6179 166.629 82.0255 160.75 78.152C149.39 70.6796 134.463 66.5635 118.72 66.5635C113.46 66.5635 108.277 67.0213 103.247 67.9279C100.126 64.9197 96.4767 62.2138 92.612 60.0755C78.273 52.9191 65.6323 55.5885 59.2477 57.8824C57.1501 58.6378 56.5029 61.298 58.0543 62.896C62.557 67.5479 70.0065 76.7418 68.1751 85.1023C61.0515 92.3824 57.1914 101.155 57.1914 110.289C57.1914 119.598 61.0515 128.37 68.1705 135.646C70.0019 144.006 62.5525 153.205 58.0497 157.857C56.5029 159.455 57.1455 162.11 59.2431 162.866C65.6277 165.164 78.2638 167.834 92.6074 160.677C96.4721 158.539 100.121 155.833 103.242 152.825C108.273 153.731 113.455 154.189 118.715 154.189C134.463 154.189 149.39 150.078 160.745 142.605C166.625 138.732 171.302 134.144 174.639 128.952C178.357 123.174 180.239 116.951 180.239 110.468C180.243 103.801 178.357 97.5883 174.643 91.8055ZM118.077 143.397C111.27 143.397 104.78 142.518 98.8635 140.93L94.5398 145.092C92.1897 147.353 89.4358 149.4 86.5625 151.012C82.7574 152.875 78.9982 153.896 75.2804 154.203C75.4915 153.823 75.6843 153.438 75.8908 153.054C80.2237 145.087 81.3942 137.926 79.3975 131.575C72.3107 126.003 68.0604 118.87 68.0604 111.1C68.0604 93.266 90.4547 78.8067 118.077 78.8067C145.699 78.8067 168.098 93.266 168.098 111.1C168.094 128.943 145.699 143.397 118.077 143.397Z" fill="#F5455C" />
        <path d="M94.1449 103.678C90.0736 103.678 86.7734 106.956 86.7734 110.999C86.7734 115.042 90.0736 118.32 94.1449 118.32C98.2162 118.32 101.516 115.042 101.516 110.999C101.516 106.956 98.2162 103.678 94.1449 103.678Z" fill="#F5455C" />
        <path d="M117.875 103.678C113.804 103.678 110.504 106.956 110.504 110.999C110.504 115.042 113.804 118.32 117.875 118.32C121.947 118.32 125.247 115.042 125.247 110.999C125.247 106.956 121.947 103.678 117.875 103.678Z" fill="#F5455C" />
        <path d="M141.605 103.678C137.534 103.678 134.233 106.956 134.233 110.999C134.233 115.042 137.534 118.32 141.605 118.32C145.676 118.32 148.976 115.042 148.976 110.999C148.976 106.956 145.672 103.678 141.605 103.678Z" fill="#F5455C" />
    </svg>
);

const RocketChatLogoContainer: React.FC<{ style: React.CSSProperties }> = (props) => (
    <div className='rocketchat-logo' style={props.style}>
        <NewRocketChatLogo />
        <span className='rocketchat-logo-badge' style={{ backgroundColor: '#2F343D' }}>Marketplace</span>
    </div>
)

export default RocketChatLogoContainer;
