import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, notification } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import { IAdminAppOverview } from 'models/appOverview';

import { getAdminApps } from 'api/admin';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import { debounce } from 'utils/debounce';

import { AdminAppsTable } from '../AppsTable';

import '../../AppsList/appslist.less';

interface IAdminAppsListProps extends RouteComponentProps, IWithPermissionProps {}

interface IAdminAppsListState {
    loading: boolean;
    pagination: PaginationProps;
    apps: IAdminAppOverview[];
}

class AdminAppsListBase extends React.PureComponent<IAdminAppsListProps, IAdminAppsListState> {
    state: Readonly<IAdminAppsListState> = {
        loading: true,
        pagination: {
            hideOnSinglePage: true,
        },
        apps: [],
    };

    constructor(props: IAdminAppsListProps) {
        super(props);

        this.setTableItemsLimit = debounce(this.setTableItemsLimit.bind(this), 300);
    }

    componentDidMount() {
        this.loadData();

        window.addEventListener('resize', this.setTableItemsLimit);
    }

    componentDidUpdate(prevProps: IAdminAppsListProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setTableItemsLimit);
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:apps'].canRead) {
            console.log('user can not read the system:apps');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Apps. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const pageSize = this.tableItemsLimit;
        const apps = await getAdminApps();

        this.setState({
            apps,
            loading: false,
            pagination: {
                ...this.state.pagination,
                total: apps.length,
                pageSize,
            },
        });
    }

    setTableItemsLimit() {
        this.setState({
            pagination: { ...this.state.pagination, pageSize: this.tableItemsLimit },
        });
    }

    get tableItemsLimit() {
        const windowHeight = window.innerHeight;
        const headerEl = document.querySelector('.applist-header');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const headerHeight: number = headerEl && (headerEl as any).offsetHeight ? (headerEl as any).offsetHeight : 0;
        const contentStyles = window.getComputedStyle(document.querySelector('.ant-layout-content')!);
        const contentPaddings = Number(contentStyles.paddingTop.replace('px', '')) * 2;

        return Math.floor((windowHeight - headerHeight - contentPaddings) / 40 - 2);
    }

    onActionTaken = async () => {
        const apps = await getAdminApps();

        this.setState({
            apps: apps.map((app) => ({ ...app, key: app.appId, id: app.appId })),
        });
    }

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Apps</h2>
                </Layout.Header>
                <Layout.Content>
                    <AdminAppsTable
                        type="adminApps"
                        tableProps={{
                            loading: this.state.loading,
                            pagination: this.state.pagination,
                            dataSource: this.state.apps,
                            rowKey: (record) => record.latest.id,
                        }}
                        onActionTaken={this.onActionTaken}
                    />
                </Layout.Content>
            </Layout>
        );
    }
}


export const AdminAppsView = connectPermissions({ feature: 'system:apps', system: true })(withRouter(AdminAppsListBase));
