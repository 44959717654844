import React from 'react';
import { Modal, Button, Spin, Result, List } from 'antd';

import { getAdminAppJobByID } from 'api/admin'
import { getAppJobByID } from 'api/apps';

import { IAppInfo } from 'models/appInfo';
import { IJob } from 'models/compiler/job';
import { IDiagnostics } from 'models/compiler/diagnostics';

interface IDiagnosticsViewerModalProps {
    visible: boolean;
    isAdmin?: boolean;
    appInfo?: IAppInfo;
    close: () => void;
}

interface IDiagnosticsViewerModalState {
    loading: boolean;
    error: boolean;
    job?: IJob;
}

export class DiagnosticsViewerModal extends React.PureComponent<IDiagnosticsViewerModalProps, IDiagnosticsViewerModalState> {
    state: Readonly<IDiagnosticsViewerModalState> = { job: undefined, loading: true, error: false };

    componentDidUpdate(prevProps: IDiagnosticsViewerModalProps) {
        //if there's no current app information, it is being hidden
        if (!this.props.appInfo) {
            return;
        }

        if (this.props.appInfo && !prevProps.appInfo) {
            this.fetchJob();
        }
    }

    async fetchJob() {
        if (!this.props.appInfo) {
            return;
        }

        try {
            if (this.props.isAdmin) {
                const adminJob = await getAdminAppJobByID(this.props.appInfo.id, this.props.appInfo.compileJobId!);
                this.setState({ job: adminJob });
            } else {
                const job = await getAppJobByID(this.props.appInfo.id, this.props.appInfo.compileJobId!);
                this.setState({ job });
            }

            this.setState({ error: false });
        } catch {
            this.setState({ error: true });
        } finally {
            this.setState({ loading: false });
        }
    }

    close = () => {
        if (typeof this.props.close === 'function') {
            this.props.close();
        }

        this.setState({ job: undefined, loading: true, error: false });
    }

    get modalTitle() {
        const app = this.props.appInfo;
        if (!app) {
            return null;
        }

        return `${app.name} v${app.version}'s Diagnostic Messages`
    }

    get errorDisplay() {
        return (
            <Result
                status="500"
                title="500"
                subTitle="Sorry, something went wrong."
            />
        );
    }

    diagnosticRender = (diagnostic: IDiagnostics, index: number) => {
        if (!this.props.appInfo) {
            return null;
        }

        return (
            <List.Item key={`${ index }-${ this.props.appInfo.internalId}_${ diagnostic.line}_${ diagnostic.character }`}>
                <List.Item.Meta
                    title={`Diagnostic ${index+1}`}
                    description={diagnostic.message}
                />
            </List.Item>
        );
    }

    get diagnostics() {
        if (!this.state.job) {
            return null;
        }

        return (
            <List<IDiagnostics>
                dataSource={this.state.job.diagnostics}
                renderItem={this.diagnosticRender}
            />
        );
    }

    render() {
        return (
            <Modal
                title={this.modalTitle}
                visible={this.props.visible || false}
                onCancel={this.close}
                footer={<Button type="default" onClick={this.close}>Close</Button>}
            >
                <Spin spinning={this.state.loading}>
                    {this.state.error ? this.errorDisplay : this.diagnostics}
                </Spin>
            </Modal>
        );
    }
}
