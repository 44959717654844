import React from 'react';
import { Provider } from 'react-redux';
import { message } from 'antd';
import { BrowserRouter, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { store } from './store';

import MainLayout from './layout/MainLayout';

import { OAuthCallbackView } from './views/oAuth/cloud';

import './theme/index.less';
import './App.less';

// lets move this
import { jwtTokenKey, jwtExpiresAtKey } from './api/index';

const RedirectToWebsite = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).location = 'https://rocket.chat/marketplace';

  return <p>Redirecting to Rocket.Chat Marketplace</p>
};

const SignOut: React.FC<RouteComponentProps> = (props) => {
  window.localStorage.removeItem('redux');
  window.localStorage.removeItem(jwtTokenKey);
  window.localStorage.removeItem(jwtExpiresAtKey);
  message.success('Successfully Signed out!');

  props.history.push({ pathname: '/login' });

  return null;
}

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Redirect exact from='/' to='/redirect' />

        <Route exact path='/oauth/callback' render={() => <OAuthCallbackView />} />
        <Route path='/sign-out' render={(history) => SignOut(history)} />
        <Route path='/redirect' render={() => RedirectToWebsite()} />

        <Route path="/publisher" component={MainLayout} />
      </Switch>
    </BrowserRouter>
  </Provider>
)

export default App;
