import React from 'react';
import { Layout } from 'antd';
import { LoadingIcon } from 'icons';

import './loading.less';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default () => (
  <Layout className='loading'>
    <LoadingIcon className='loading-icon' />
  </Layout>
)
