export const permissionActions = ['read', 'create', 'update', 'delete'];

 export function serializeEnforceRequest(feature: string, permission: string, developerId: string, group?: string) {
    if (!group) {
        group = 'system';
    }

    return `${ developerId }-${ group }-${ feature }-${ permission }`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function areObjectsEquivalent(obj1: any, obj2: any) {
    const o1k = Object.keys(obj1);
    const o2k = Object.keys(obj2);

    if (o1k.length !== o2k.length) {
        return false;
    }

    return !o1k.map((k) => obj1[k] === obj2[k]).includes(false);
}
