import React from 'react';
import { Modal, Form, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IAdminAppOverview } from 'models/appOverview';

import { postAdminAppMakeUsageBased } from 'api/admin';

interface FormValues {
    priceOverride: boolean;
}

interface IMakeAppUsageBasedProps extends FormComponentProps<FormValues> {
    visible: boolean;
    app?: IAdminAppOverview;
    onClose: () => void;
}

interface IMakeAppUsageBasedState {
    loading: boolean;
}

class MakeAppUsageBasedModalBase extends React.PureComponent<IMakeAppUsageBasedProps, IMakeAppUsageBasedState> {
    state: Readonly<IMakeAppUsageBasedState> = {
        loading: false,
    };

    onSaveClick = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err || !this.props.app) {
                return;
            }

            this.setState({ loading: true }, async () => {
                try {
                    await postAdminAppMakeUsageBased(this.props.app!.appId, values.priceOverride);
                    this.props.onClose();
                } catch (e) {
                    console.log('failed to make an app usage based:', e);
                } finally {
                    this.setState({ loading: false });
                    this.props.form.resetFields();
                }
            });
        });
    }

    get allowPriceOverride() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Enable Price Override">
                {getFieldDecorator('priceOverride', {
                    valuePropName: 'checked',
                    initialValue: true,
                    rules: [{ required: true, message: 'Please indicate whether the price is overridable.' }],
                })(
                    <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
                )}
            </Form.Item>
        );
    }

    render() {
        return (
            <Modal
                visible={this.props.visible && typeof this.props.app !== 'undefined'}
                title={`Make ${this.props.app ? this.props.app.latest.name : '???'} Usage Based?`}
                okText="Yes"
                okButtonProps={{ disabled: this.state.loading, loading: this.state.loading }}
                cancelButtonProps={{ disabled: this.state.loading }}
                closable={!this.state.loading}
                maskClosable={!this.state.loading}
                onOk={this.onSaveClick}
                onCancel={this.props.onClose}
            >
                <Form>
                    {`Are you sure you want to make the app ${this.props.app ? this.props.app.latest.name : '???'} usage based? Doing so is irreversible.`}
                    { this.allowPriceOverride }
                </Form>
            </Modal>
        );
    }
}

export const MakeAppUsageBasedModal = Form.create<IMakeAppUsageBasedProps>()(MakeAppUsageBasedModalBase);
