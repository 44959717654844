import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Table, notification } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { PaginationProps } from 'antd/lib/pagination';

import { IJob } from 'models/compiler/job';
import { AppInfoVersionMapByInternalId } from 'models/appInfo';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import { SimpleDateWithTime } from 'components/dates/simpleDate';

import { getAdminCompileJobs, getAdminApps, getAdminAppVersions } from 'api/admin';

interface IJobsListProps extends RouteComponentProps, IWithPermissionProps {}

interface IJobsListState {
    loading: boolean;
    jobs: IJob[];
    versionById: AppInfoVersionMapByInternalId;
    pagination: PaginationProps;
}

class JobsListBase extends React.PureComponent<IJobsListProps, IJobsListState> {
    state: Readonly<IJobsListState> = {
        loading: true,
        jobs: [],
        versionById: {},
        pagination: {
            hideOnSinglePage: true,
        },
    };

    columns: ColumnProps<IJob>[] = [
        {
            title: 'Name', key: 'name',
            render: (_, job) => {
                const app = this.state.versionById[job.internalAppId];

                if (!app) {
                    return job.internalAppId;
                }

                return `${app.name} v${app.version}`;
            },
        },
        {
            title: 'TS Version', key: 'typeScriptVersion', dataIndex: 'typeScriptVersion',
            render: (value) => value ? `v${value}` : '-',
        },
        {
            title: 'Engine Version', key: 'engineVersion', dataIndex: 'engineVersion',
            render: (value) => value ? `v${value}` : '-',
        },
        {
            title: 'Status', key: 'status', dataIndex: 'status', className: 'title-caps',
            render: (value) => value.replace('-', ' '),
        },
        {
            title: 'Compile Duration', key: 'duration', dataIndex: 'duration',
            render: (value) => value ? `${value / 1000}s` : '-',
        },
        {
            title: 'Requested At', key: 'requestedAt', dataIndex: 'requestedAt',
            render: (value) => <SimpleDateWithTime date={value} simplier />,
        },
        {
            title: 'Finished At', key: 'finishedAt', dataIndex: 'finishedAt',
            render: (value) => <SimpleDateWithTime date={value} simplier />,
        },
        {
            title: 'View App', key: 'viewApp', dataIndex: 'appId',
            render: (appId: string) => <Link to={`/publisher/admin/apps/${ appId }`}>View App</Link>,
        },
    ];

    componentDidMount() {
       this.loadData();
    }

    componentDidUpdate(prevProps: IJobsListProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:compileJobs'].canRead) {
            console.log('user can not read the system:compileJobs');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Compile Jobs. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const apps = await getAdminApps();
        const allAppsPromises = apps.map(async (a) => await getAdminAppVersions(a.appId));
        const versions = await Promise.all(allAppsPromises);

        const versionById: AppInfoVersionMapByInternalId = {};
        for (let i = 0; i < versions.length; i++) {
            const appVersions = versions[i];

            for (let vi = 0; vi < appVersions.length; vi++) {
                const ver = appVersions[vi];

                versionById[ver.internalId!] = ver;
            }
        }

        this.setState({
            versionById,
            pagination: {
                ...this.state.pagination,
                onChange: this.onPageChange,
            },
        });

        await this.refreshJobs();
    }

    onPageChange = (page: number) => {
        this.setState({
            pagination: {
                ...this.state.pagination,
                current: page,
            },
        }, this.refreshJobs);
    }

    refreshJobs = async () => {
        this.setState({ loading: true }, async () => {
            const { current } = this.state.pagination;
            let { pageSize } = this.state.pagination;

            if (!pageSize) {
                pageSize = 25;
            }

            let offset = 0;
            if (current && current > 0) {
                offset = pageSize * current;
            }

            const { data, meta } = await getAdminCompileJobs(pageSize, offset);

            this.setState({
                jobs: data,
                loading: false,
                pagination: {
                    ...this.state.pagination,
                    total: meta ? meta.total : data.length,
                }
            });
        });
    }

    render() {
        return (
            <Layout>
                <Layout.Header className='applist-header'>
                    <h2>Compile Jobs</h2>
                </Layout.Header>
                <Layout.Content>
                    <Table
                        loading={this.state.loading}
                        pagination={this.state.pagination}
                        columns={this.columns}
                        dataSource={this.state.jobs}
                        rowKey={(j) => j.id}
                    />
                </Layout.Content>
            </Layout>
        );
    }
}

export const AdminJobsList = connectPermissions({ feature: 'system:compileJobs', system: true })(withRouter(JobsListBase));
