import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal, Row, Col, Input, message } from 'antd';

import { getAdminWorkspaceByID } from 'api/admin';

interface IWorkspaceIDEntryModalProps extends RouteComponentProps {
    visible: boolean;
}

interface IWorkspaceIDEntryModalState {
    loading: boolean;
    id: string;
}

class WorkspaceIDEntryModalBase extends React.PureComponent<IWorkspaceIDEntryModalProps, IWorkspaceIDEntryModalState> {
    state: Readonly<IWorkspaceIDEntryModalState> = {
        loading: false,
        id: '',
    };

    onView = () => {
        if (!this.state.id) {
            message.warn('Invalid workspace id provided.');
            return;
        }

        this.setState({ loading: true }, async () => {
            try {
                const workspace = await getAdminWorkspaceByID(this.state.id);
                this.props.history.push(`/publisher/admin/workspace/${ this.state.id }`, { workspace });
            } catch {
                message.warn('Invalid workspace id provided.');
            }

            this.setState({ id: '', loading: false });
        });

    }

    onCancel = () => {
        this.props.history.push({ pathname: '/publisher/admin' });
    }

    onEntryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ id: event.target.value });
    }

    render() {
        return (
            <Modal
                title={`Please enter a workspace id`}
                visible={this.props.visible}
                onCancel={this.onCancel}
                onOk={this.onView}
                okText="View"
                okButtonProps={{ disabled: !this.state.id || this.state.loading, loading: this.state.loading }}
                cancelText="Cancel"
                cancelButtonProps={{ disabled: this.state.loading }}
                closable={!this.state.loading}
                maskClosable={!this.state.loading}
            >
                <Row>
                    <Col span={24}>
                        <Input value={this.state.id} onChange={this.onEntryChange} />
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export const WorkspaceIDEntryModal = withRouter(WorkspaceIDEntryModalBase);
