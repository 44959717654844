import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Button } from 'antd';
import { PaginationConfig } from 'antd/lib/table';

import { IAppOverview } from 'models/appOverview';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

import { debounce } from 'utils/debounce';
import { getApps } from 'api/apps';

import AppsTable from './AppsTable';

import './appslist.less';

interface IAppsListViewProps extends RouteComponentProps, IWithPermissionProps { }

interface IAppsListViewState {
    loading: boolean;
    pagination: PaginationConfig;
    apps: IAppOverview[];
    tableParams: {
        sort: string,
    };
}

class AppsListViewBase extends React.PureComponent<IAppsListViewProps, IAppsListViewState> {
    state: Readonly<IAppsListViewState> = {
        loading: true,
        pagination: {
            hideOnSinglePage: true,
        },
        apps: [],
        tableParams: {
            sort: 'name',
        },
    };

    constructor(props: IAppsListViewProps) {
        super(props);

        this.setTableItemsLimit = debounce(this.setTableItemsLimit.bind(this), 300);
        this.onPageChange = this.onPageChange.bind(this);
        this.onNewButtonClick = this.onNewButtonClick.bind(this);
    }

    async componentDidMount() {
        const pageSize = this.tableItemsLimit;
        const apps = await getApps();

        this.setState({
            apps,
            loading: false,
            pagination: {
                ...this.state.pagination,
                total: apps.length,
                pageSize,
                onChange: this.onPageChange,
            },
        });

        window.addEventListener('resize', this.setTableItemsLimit);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setTableItemsLimit);
    }

    setTableItemsLimit() {
        this.setState({
            pagination: { ...this.state.pagination, pageSize: this.tableItemsLimit },
        });
    }

    get tableItemsLimit() {
        const windowHeight = window.innerHeight;
        const headerEl = document.querySelector('.applist-header');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const headerHeight = headerEl && (headerEl as any).offsetHeight ? (headerEl as any).offsetHeight as number : 0;
        const contentStyles = window.getComputedStyle(document.querySelector('.ant-layout-content')!);
        const contentPaddings = Number(contentStyles.paddingTop.replace('px', '')) * 2;

        return Math.floor((windowHeight - headerHeight - contentPaddings) / 40 - 2);
    }

    onPageChange(page: number, pageSize: number | undefined) {
        const { pagination: { total }, apps } = this.state;
        const offset = apps.length;

        if (offset >= (total || 0)) {
            this.setState({
                pagination: {
                    ...this.state.pagination, ...{ current: page }
                },
            });

            return false;
        }

        if (typeof pageSize === 'undefined') {
            pageSize = 25;
        }

        const limit = pageSize * page - offset;

        this.setState({
            loading: true,
            tableParams: { ...this.state.tableParams, ...{ limit, offset } },
        }, async () => {
            const apps = await getApps();

            this.setState({
                apps: [...this.state.apps, ...apps],
                loading: false,
                pagination: { ...this.state.pagination, ...{ total, current: page } },
            });
        });
    }

    onNewButtonClick() {
        this.props.history.push({ pathname: '/publisher/new-extended/app' });
    }

    get headerButton() {
        if (!this.props.permissions.features['app'].canCreate) {
            return null;
        }

        return (
            <div className="applist-header-buttons">
                <Button type="primary" icon="plus" onClick={this.onNewButtonClick}>New App</Button>
            </div>
        );
    }

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Apps</h2>

                    {this.headerButton}
                </Layout.Header>
                <Layout.Content>
                    <AppsTable
                        type="apps"
                        tableProps={{
                            pagination: this.state.pagination,
                            loading: this.state.loading,
                            dataSource: this.state.apps,
                            rowKey: (record) => record.latest.id,
                        }}
                        canUpdate={this.props.permissions.features['app'].canUpdate}
                    />
                </Layout.Content>
            </Layout>
        )
    }
}

export const AppsListView = connectPermissions({ feature: 'app' })(withRouter(AppsListViewBase));
