import React from 'react';
import { Modal, Form, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IAdminAppOverview } from 'models/appOverview';

import { postAdminAppEnterpriseOnly } from 'api/admin';

interface FormValues {
    tierId?: string;
}

interface IMakeEnterpriseOnlyModalProps extends FormComponentProps<FormValues> {
    visible: boolean;
    app?: IAdminAppOverview;
    onClose: () => void;
}

interface IMakeEnterpriseOnlyModalState {
    loading: boolean;
}

class MakeEnterpriseOnlyModalBase extends React.PureComponent<IMakeEnterpriseOnlyModalProps, IMakeEnterpriseOnlyModalState> {
    state: Readonly<IMakeEnterpriseOnlyModalState> = {
        loading: false,
    };

    componentDidUpdate() {
        if (!this.props.app) {
            return;
        }
    }

    onOnClick = () => {
        const { app } = this.props;
        if (!app) {
            return;
        }

        this.setState({ loading: true }, async () => {
            message.info(`Making ${app.latest.name} premium only...`);
            await postAdminAppEnterpriseOnly(app.appId);
            this.props.onClose();
            message.success(`${app.latest.name} is now for Premium customers only!`);
        });

        this.props.form.validateFields((errors, values) => {
            if (errors || !values.tierId) {
                return;
            }

            this.setState({ loading: true }, async () => {
                message.info(`Making ${app.latest.name} premium only...`);
                await postAdminAppEnterpriseOnly(app.appId, values.tierId);
                this.props.onClose();
                message.success(`${app.latest.name} is now for Premium customers only!`);
            });
        });
    }

    render() {
        return (
            <Modal
                visible={this.props.visible && typeof this.props.app !== 'undefined'}
                title={`Make ${this.props.app ? this.props.app.latest.name : '???'} Premium Only?`}
                okText="Yes"
                onOk={this.onOnClick}
                okButtonProps={{ disabled: this.state.loading }}
                onCancel={this.props.onClose}
                cancelButtonProps={{ disabled: this.state.loading }}
            >
                <Form>
                    {`Are you sure you want to make the app ${this.props.app ? this.props.app.latest.name : '???'} premium only? It will also add it to the Premium Bundle. Doing so is irreversible.`}
                </Form>
            </Modal>
        );
    }
}

export const MakeEnterpriseOnlyModal = Form.create<IMakeEnterpriseOnlyModalProps>()(MakeEnterpriseOnlyModalBase);
