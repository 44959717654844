import React from 'react';
import { Modal, Form, Select, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IDeveloper } from 'models/developer';

import { changePublisherUserRole } from 'api/publisher';

interface IChangeDeveloperRoleModalProps extends FormComponentProps {
    visible: boolean;
    developer?: IDeveloper;
    onClose: (saved: boolean) => void;
}

interface IChangeDeveloperRoleModalState {
    saving: boolean;
}

class ChangeDeveloperRoleModalBase extends React.PureComponent<IChangeDeveloperRoleModalProps, IChangeDeveloperRoleModalState> {
    state = {
        saving: false,
    };

    get roleSelector() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Role">
                {getFieldDecorator('role', {
                    initialValue: this.props.developer ? this.props.developer.role : '',
                    rules: [{ required: true, message: 'Please select what role the new user should be.' }],
                })(
                    <Select disabled={this.state.saving}>
                        <Select.Option value="viewer">Viewer</Select.Option>
                        <Select.Option value="developer">Developer</Select.Option>
                        <Select.Option value="owner">Owner</Select.Option>
                    </Select>
                )}
            </Form.Item>
        );
    }

    saveRoleChange = () => {
        this.setState({ saving: true }, async () => {
            const { developer } = this.props;
            if (!developer) {
                return;
            }

            try {
                await changePublisherUserRole({ developerId: developer._id, role: this.props.form.getFieldValue('role') });

                this.props.onClose(true);
                this.props.form.resetFields();
            } catch (e) {
                this.setState({ saving: false });

                console.error('error while changing a developer\'s role:', e);
                notification.error({
                    message: 'An Error Occured',
                    description: (<span>
                        An error occured processing your request.<br />Code: { e.code}<br />Error: { e.error}
                    </span>),
                });
            }
        });
    }

    onCancel = () => {
        this.props.onClose(false);
    }

    render() {
        const { developer } = this.props;

        return (
            <Modal
                title={`Change ${developer ? developer.firstName + ' ' + developer.lastName : 'n/a'}'s Role`}
                visible={this.props.visible}
                onCancel={this.onCancel}
                onOk={this.saveRoleChange}
                closable={!this.state.saving}
                maskClosable={!this.state.saving}
                keyboard={!this.state.saving}
                okText="Save"
                okButtonProps={{ disabled: this.state.saving, loading: this.state.saving }}
                cancelButtonProps={{ disabled: this.state.saving }}
            >
                <Form layout="vertical">
                    {this.roleSelector}
                </Form>
            </Modal>
        );
    }
}

export const ChangeDeveloperRoleModal = Form.create<IChangeDeveloperRoleModalProps>()(ChangeDeveloperRoleModalBase);
