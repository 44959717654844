export const statusToColor = {
    draft: 'gray',
    submitted: 'gray',
    reviewed: 'orange',
    rejected: 'red',
    'author-rejected': 'red',
    'author-approved': '#d3d3d3',
    approved: 'blue',
    published: 'green',
    unpublished: 'purple',
    compiling: 'cyan',
    compiled: 'gray',
    'compiler-error': 'volcano',
};

const sortOrder = ['author-approved', 'draft', 'submitted', 'compiling', 'compiled', 'compiler-error', 'reviewed', 'approved', 'unpublished', 'published', 'author-rejected', 'rejected'];
export const statusSorter = (a: string, b: string) => sortOrder.indexOf(a) - sortOrder.indexOf(b);

export const capitalizeFirstLetter = (text?: string): string => {
    if (!text) {
        return '';
    }

    const value = text[0].toUpperCase() + text.substring(1);

    return value.split('-').join(' ');
};

export const pricingStrategyToSpaced = (strategy: string) => {
    switch(strategy) {
        case 'once':
            return 'Once';
        case 'monthly':
            return 'Monthly';
        case 'monthlyPerUser':
            return 'Monthly Per User';
        default:
            return capitalizeFirstLetter(strategy);
    }
};
