import React from 'react';
import { Form, Row, Col, Spin, Button, Radio, InputNumber, Select, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { NewPriceSubscriptionDrawer } from 'components/prices/newPriceSubscriptionDrawer';
import { SubscriptionName } from 'components/prices/subscriptionName';

import { IPublisher } from 'models/publisher';
import { IPublisherAppOverview } from 'models/appOverview';
import { AppPurchaseType } from 'models/purchaseRecord';
import { IPrice } from 'models/appPrice';

import { getPublisher } from 'api/publisher';
import { getAppPrice, createAppPrice, getAppPrices, getApp } from 'api/apps';

interface FormValues {
    purchaseType: string;
    price?: number;
    pricingPlanId?: string;
}

interface IStep2Props extends FormComponentProps<FormValues> {
    goNext: () => void;
    goBack: () => void;
    setApp: (app: IPublisherAppOverview) => Promise<void>;
    app?: IPublisherAppOverview;
}

interface IStep2State {
    loading: boolean;
    submitting: boolean;
    publisher?: IPublisher;
    activePrice?: IPrice;
    isSubscriptionPricePlanDrawerOpen: boolean;
    prices: IPrice[];
}

class NewAppStepTwoViewBase extends React.PureComponent<IStep2Props, IStep2State> {
    state: Readonly<IStep2State> = {
        loading: true,
        submitting: false,
        isSubscriptionPricePlanDrawerOpen: false,
        prices: [],
    };

    async componentDidMount() {
        if (!this.props.app) {
            return;
        }

        const publisher = await getPublisher();

        this.setState({ publisher });
        await this.loadData();
    }

    loadData = async () => {
        if (!this.props.app) {
            return;
        }

        const app = await getApp(this.props.app!.appId);
        await this.props.setApp(app);

        const price = await getAppPrice(this.props.app.appId, this.props.app.currentPricingId);
        const prices = await getAppPrices(this.props.app.appId);

        this.setState({ loading: false, activePrice: price, prices });
    }

    get purchaseType() {
        const { getFieldDecorator } = this.props.form;

        let initialValue = 'free';
        if (this.state.activePrice) {
            initialValue = this.state.activePrice.type;

            if (this.state.activePrice.type === AppPurchaseType.Buy && this.state.activePrice.buyPrice === 0) {
                initialValue = 'free';
            }
        }

        return (
            <React.Fragment>
                <Form.Item label="Purchase Type" extra="Define how users can purchase your app.">
                    {getFieldDecorator('purchaseType', {
                        initialValue,
                        rules: [{ required: true, message: 'Please select the purchase type.' }]
                    })(
                        <Radio.Group buttonStyle="solid" disabled={this.state.submitting || !this.state.publisher || !this.state.publisher.canCreatePaidApps}>
                            <Radio.Button value="buy">One-time purchase</Radio.Button>
                            <Radio.Button value="subscription">Subscription</Radio.Button>
                            <Radio.Button value="free">Free</Radio.Button>
                        </Radio.Group>
                    )}
                </Form.Item>

                { this.purchaseTypeDescription }
            </React.Fragment>
        );
    }

    get purchaseTypeDescription() {
        const { getFieldValue } = this.props.form;

        let text: React.ReactNode = '';
        switch (getFieldValue('purchaseType')) {
            case 'free':
                text = 'Your app will be available to install at no cost.';
                break;
            case 'buy':
                text = 'One-time purchases allow app usage and updates for a one-time cost.';
                break;
            case 'subscription':
                text = <React.Fragment>Subscriptions can have one active pricing plan at a time.<br />A pricing plan can have monthly and/or yearly options as well as a trial period.</React.Fragment>;
                break;
        }

        return (
            <div style={{ marginTop: '15px' }}>
                <p>{ text }</p>
            </div>
        );
    }

    get priceInput() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        if (getFieldValue('purchaseType') !== 'buy') {
            return null;
        }

        let initialValue = 5.99;
        if (this.state.activePrice) {
            initialValue = this.state.activePrice.buyPrice;
        }

        return (
            <Form.Item label="Price (USD)">
                {getFieldDecorator('price', {
                    initialValue,
                    rules: [{ required: true, message: 'Please set a price to charge.' }]
                })(
                    <InputNumber
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                        min={0}
                        disabled={this.state.submitting || !this.state.publisher || !this.state.publisher.canCreatePaidApps}
                    />
                )}
            </Form.Item>
        );
    }

    get pricingPlanArea() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        if (getFieldValue('purchaseType') !== 'subscription') {
            return null;
        }

        const plans: IPrice[] = this.state.prices.filter((p) => p.type === AppPurchaseType.Subscription);

        return (
            <React.Fragment>
                <Form.Item label="Pricing plan" style={{ marginBottom: '0' }}>
                    { getFieldDecorator('pricingPlanId', {
                        initialValue: this.state.activePrice && plans.length !== 0 ? this.state.activePrice.id : '',
                        rules: [{ required: true, message: 'A pricing plan is required.' }],
                    })(
                        <Select style={{ maxWidth: '400px' }}>
                            {
                                plans.length === 0 ? <Select.Option key="nothing" value="">No plans available</Select.Option> :
                                    plans.map((p) => <Select.Option key={p.id} value={p.id}><SubscriptionName price={p} /></Select.Option>)

                            }
                        </Select>
                    )}
                </Form.Item>

                <Button size="small" onClick={this.openNewPricingPlanDrawer}><Icon type="plus" />New pricing plan</Button>

                { this.state.activePrice ?
                    <div className="pricing-summary">{ this.state.activePrice.monthly.enabled ? `$${ this.state.activePrice.monthly.price } monthly` : null }{ this.state.activePrice.yearly.enabled ? ` / $${ this.state.activePrice.yearly.price } yearly` : null}</div>
                : null }
            </React.Fragment>
        );
    }

    openNewPricingPlanDrawer = () => {
        this.setState({ isSubscriptionPricePlanDrawerOpen: true });
    }

    closeNewPricingPlanDrawer = async (saved: boolean) => {
        if (saved) {
            await this.loadData();
        }

        this.setState({ isSubscriptionPricePlanDrawerOpen: false });
    }

    onNextClick = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            switch (values.purchaseType) {
                case 'free':
                    // when free, nothing we need to do
                    this.props.goNext();
                    break;
                case 'buy':
                    // when buy, create a new price with the amount configured
                    this.setState({ submitting: true }, async () => {
                        const price: Partial<IPrice> = {
                            type: AppPurchaseType.Buy,
                            buyPrice: values.price,
                        };

                        try {
                            await createAppPrice(this.props.app!.appId, price);
                            const app = await getApp(this.props.app!.appId);

                            await this.props.setApp(app);
                        } catch (e) {
                            //TODO: handle error
                            this.setState({ submitting: false });
                            return;
                        }

                        this.props.goNext();
                    });
                    break;
                case 'subscription':
                    // when subscription, ensure we have a valid subscription id
                    if (!values.pricingPlanId) {
                        return;
                    }

                    this.props.goNext();
                    break;
            }
        });
    }

    get buttons() {
        if (!this.state.publisher) {
            return null;
        }

        const purchaseType = this.props.form.getFieldValue('purchaseType');

        let nextDisabled = this.state.submitting || this.state.loading ||
            (!purchaseType || typeof purchaseType !== 'string');

        //if the purchase type is buy and the price field is empty, disable the next button
        if (purchaseType === 'buy' && !this.props.form.getFieldValue('price')) {
            nextDisabled = true;
        }

        return (
            <Row className="button-group">
                <Col span={24}>
                    <Button
                        type="default"
                        size="large"
                        onClick={this.props.goBack}
                        disabled={this.state.submitting || this.state.loading}
                    >
                        Back
                    </Button>

                    <Button
                        type="primary"
                        size="large"
                        onClick={this.onNextClick}
                        loading={this.state.submitting}
                        disabled={nextDisabled}
                    >
                        Next
                    </Button>
                </Col>
            </Row>
        );
    }

    render() {
        return (
            <Form hideRequiredMark colon={false}>
                <Spin spinning={this.state.loading}>
                    { this.purchaseType }
                    { this.priceInput }
                    { this.pricingPlanArea }

                    {this.buttons}
                </Spin>

                <NewPriceSubscriptionDrawer
                    app={this.props.app}
                    visible={this.state.isSubscriptionPricePlanDrawerOpen}
                    close={this.closeNewPricingPlanDrawer}
                />
            </Form>
        );
    }
}

export const NewAppStepTwoView = Form.create<IStep2Props>()(NewAppStepTwoViewBase);
