import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, notification, Table, Tag, Button, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IPurchaseRecord } from 'models/purchaseRecord';
import { IWorkspace } from 'models/fleetcommand/workspace';
import { AdminAppMap } from 'models/appOverview';
import { DeveloperSystemRole } from 'models/developer';

import { getAdminApps, getAdminBundles, getAdminWorkspaceByID, getAdminWorkspacePurchasesByID } from 'api/admin';
import { getMyInfo } from 'api/profile';

import { WorkspaceIDEntryModal } from './workspaceIdModal';

import { ViewPurchaseRecordModal } from 'components/purchaseInfoModal';

interface IWorkspaceViewProps extends RouteComponentProps<{ workspaceId?: string }> { }

interface IWorkspaceViewState {
    loading: boolean;
    purchases: IPurchaseRecord[];
    workspace?: IWorkspace;
    detailsModalVisible: boolean;
    purchaseRecordId: string;
    appsById: AdminAppMap;
}

class AdminWorkspaceViewBase extends React.PureComponent<IWorkspaceViewProps, IWorkspaceViewState> {
    state: Readonly<IWorkspaceViewState> = {
        loading: true,
        purchases: [],
        detailsModalVisible: false,
        purchaseRecordId: '',
        appsById: {},
    };

    async componentDidMount() {
        const info = await getMyInfo();
        if (info.systemRole !== DeveloperSystemRole.Admin) {
            notification.error({
                message: 'Invalid User Role',
                description: 'Invalid user role for the previous page (401). If you believe this is incorrect, please contact Rocket.Chat Support.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const apps = await getAdminApps();
        const bundles = await getAdminBundles();

        const appsById: AdminAppMap = {};
        for (let i = 0; i < apps.length; i++) {
            const app = apps[i];
            appsById[app.appId] = app;
        }

        for (let i = 0; i < bundles.length; i++) {
            const bundle = bundles[i];
            appsById[bundle.appId] = bundle;
        }

        this.setState({
            appsById,
            loading: false,
        });
    }

    componentDidUpdate = () => {
        //if there is no workspaceId in the url path
        //then reset the state
        if (!this.props.match.params || !this.props.match.params.workspaceId) {
            if (this.state.purchases.length > 0) {
                this.setState({ purchases: [] });
            }

            if (this.state.workspace) {
                this.setState({ workspace: undefined });
            }

            return;
        }

        //if there's a workspace loaded and it has an id,
        //then don't try to load anything
        if (this.state.workspace && this.state.workspace.id) {
            return;
        }

        console.log('component updated', this.props.match.params);
        console.log(this.props.location.state);

        this.setState({ loading: true }, async () => {
            await this.loadWorkspace();
            await this.loadWorkspacePurchases();

            this.setState({ loading: false });
        });
    }

    loadWorkspace = async () => {
        if (!this.props.match.params.workspaceId) {
            return;
        }

        try {
            const workspace = await getAdminWorkspaceByID(this.props.match.params.workspaceId);
            this.setState({ workspace });
        } catch {
            message.warn('Invalid workspace id. No workspace found by the provided id.');
            this.props.history.push({ pathname: '/publisher/admin/workspace' });
        }
    }

    loadWorkspacePurchases = async () => {
        if (!this.props.match.params.workspaceId) {
            return;
        }

        try {
            const purchases = await getAdminWorkspacePurchasesByID(this.props.match.params.workspaceId);
            this.setState({ purchases });
        } catch {
            message.warn('Failed to load the purchases for the workspace.');
            this.props.history.push({ pathname: '/publisher/admin/workspace' });
        }
    }

    getPriceColumn = (text: void, pr: IPurchaseRecord) => {
        const app = this.state.appsById[pr.appId];

        if (!app) {
            return 'n/a';
        }

        if (pr.purchaseType === 'buy' || !pr.subscriptionInfo) {
            if (app.price > 0) {
                return `$${app.price.toFixed(2)}`;
            }

            return 'FREE';
        }

        const { subscriptionInfo } = pr;

        if (subscriptionInfo.externallyManaged) {
            return 'Externally managed subscription';
        }

        const periodEnd = new Date(subscriptionInfo.periodEnd);

        return (
            <div>
                {subscriptionInfo.status === 'active' ? <Tag color="green">active</Tag> : <Tag color="magenta">{subscriptionInfo.status}</Tag>}
                <Tag>{`${subscriptionInfo.seats}/${subscriptionInfo.maxSeats} seats`}</Tag>
                <Tag>{`${periodEnd.getMonth() + 1}/${periodEnd.getDate()}/${periodEnd.getFullYear()}`}</Tag>
            </div>
        );
    }

    toggleDetails = (purchaseRecordId: string) => {
        return () => this.setState({
            purchaseRecordId,
            detailsModalVisible: !this.state.detailsModalVisible,
        });
    }

    columns: ColumnProps<IPurchaseRecord>[] = [
        {
            key: 'name', title: 'App Name',
            render: (text, record) => this.state.appsById[record.appId] ? this.state.appsById[record.appId].latest.name : 'n/a',
        },
        {
            key: 'date', title: 'Date', dataIndex: 'date', defaultSortOrder: 'descend',
            sorter: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
            render: (text) => new Date(text).toLocaleString(),
        },
        {
            key: 'price', title: 'Pricing Info',
            render: this.getPriceColumn,
        },
        {
            key: 'details', title: 'View Details',
            render: (nothing, record) => <Button type="link" onClick={this.toggleDetails(record._id)}>Details</Button>,
        },
    ]

    get workspaceTitle() {
        const { workspace } = this.state;

        if (!workspace) {
            return null;
        }

        return `for "${workspace.nickname} (${workspace.address})"`;
    }

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Workspace Purchase Records {this.workspaceTitle}</h2>
                </Layout.Header>
                <Layout.Content>
                    <Table
                        columns={this.columns}
                        dataSource={this.state.purchases}
                        rowKey={(p) => p._id}
                        loading={this.state.loading}
                    />

                    <WorkspaceIDEntryModal visible={!this.props.match.params || !this.props.match.params.workspaceId || this.props.match.params.workspaceId === ''} />
                    <ViewPurchaseRecordModal visible={this.state.detailsModalVisible} purchaseRecordId={this.state.purchaseRecordId} onClose={this.toggleDetails('')} />
                </Layout.Content>
            </Layout>
        )
    }
}

export const AdminWorkspaceView = withRouter(AdminWorkspaceViewBase);

