import React from 'react';
import { Drawer, Row, Col, Form, Button, Input, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IPublisher } from 'models/publisher';

import { postAdminUpdatePublisher } from 'api/admin';

interface IFormValues {
    name: string;
    canCreatePaidApps: boolean;
    canCreateBundles: boolean;
    canUseNPMModules: boolean;
    canUseWebhooks: boolean;
    accountId: string;
}

interface IEditPublisherDrawerProps extends FormComponentProps<IFormValues> {
    isVisible: boolean;
    publisher?: IPublisher;
    close: () => Promise<void>;
    finished: () => Promise<void>;
}

interface IEditPublisherDrawerState {
    isSaving: boolean;
}

class EditPublisherDrawerBase extends React.PureComponent<IEditPublisherDrawerProps, IEditPublisherDrawerState> {
    state: Readonly<IEditPublisherDrawerState> = {
        isSaving: false,
    };

    onClose = () => {
        this.props.close();
        this.props.form.resetFields();
    };

    handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!this.props.publisher) {
            return;
        }

        const { form } = this.props;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ isSaving: true }, async () => {
                await postAdminUpdatePublisher({ ...this.props.publisher, ...values }, this.props.publisher!._id);

                await this.props.finished();

                setTimeout(() => {
                    if (!this.props.isVisible) {
                        //reset the form and state
                        form.resetFields();
                        this.setState({ isSaving: false });
                    }
                }, 500);
            });
        });
    }

    //#region publisher name
    get publisherName() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Name" colon={false}>
                {getFieldDecorator('name', {
                    initialValue: this.props.publisher ? this.props.publisher.name : '',
                    rules: [{ required: true, message: 'Please input the publisher\'s name.' }]
                })(
                    <Input disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region create paid apps
    get canCreatePaidAppsInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={'Create Paid Paids'} colon={false}>
                {getFieldDecorator('canCreatePaidApps', {
                    valuePropName: 'checked',
                    initialValue: this.props.publisher ? this.props.publisher.canCreatePaidApps : false,
                    rules: [{ required: true, message: 'Please set whether this publisher can create paid Apps' }]
                })(
                    <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region create bundles
    get canCreateBundlesInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={'Create Bundles'} colon={false}>
                {getFieldDecorator('canCreateBundles', {
                    valuePropName: 'checked',
                    initialValue: this.props.publisher ? this.props.publisher.canCreateBundles : false,
                    rules: [{ required: true, message: 'Please set whether this publisher can create Bundles' }]
                })(
                    <Switch checkedChildren={"Yes"} unCheckedChildren={"No"} disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region npm modules
    get canUseNPMModules() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Use NPM Modules" colon={false}>
                {getFieldDecorator('canUseNPMModules', {
                    valuePropName: 'checked',
                    initialValue: this.props.publisher ? this.props.publisher.canUseNPMModules : false,
                    rules: [{ required: true, message: 'Please set whether this publisher can use NPM Modules or not' }]
                })(
                    <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region webhooks
    get canUseWebhooks() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Use Webhooks" colon={false}>
                {getFieldDecorator('canUseWebhooks', {
                    valuePropName: 'checked',
                    initialValue: this.props.publisher ? this.props.publisher.canUseWebhooks : false,
                    rules: [{ required: true, message: 'Please set whether this publisher can use webhooks or not' }]
                })(
                    <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region publisher account id
    get publisherAccountId() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Account ID" colon={false}>
                {getFieldDecorator('accountId', {
                    initialValue: this.props.publisher ? this.props.publisher.accountId : '',
                })(
                    <Input disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    render() {
        const { publisher } = this.props;

        return (
            <Drawer
                title={"Edit Publisher: " + (publisher ? publisher.name : '')}
                width={500}
                onClose={this.onClose}
                visible={this.props.isVisible}
                closable={this.state.isSaving}
            >
                <Form layout="vertical" id="edit-publisher">
                    <Row gutter={16}>
                        <Col span={12}>{this.publisherName}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{this.canCreatePaidAppsInput}</Col>
                        <Col span={12}>{this.canCreateBundlesInput}</Col>
                        <Col span={12}>{this.canUseNPMModules}</Col>
                        <Col span={12}>{this.canUseWebhooks}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>{this.publisherAccountId}</Col>
                    </Row>
                </Form>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={this.state.isSaving}>Cancel</Button>
                    <Button onClick={this.handleSubmit} type="primary" disabled={this.state.isSaving} loading={this.state.isSaving}>Save</Button>
                </div>
            </Drawer>
        );
    }
}

export const EditPublisherDrawer = Form.create<IEditPublisherDrawerProps>()(EditPublisherDrawerBase);
