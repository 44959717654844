/* eslint-disable @typescript-eslint/no-explicit-any */
export const debounce = (callback: (...args: any[]) => void, time = 250) => {
    let intervalId: NodeJS.Timeout;

    return (...args: any[]) => {
        clearTimeout(intervalId);

        intervalId = setTimeout(() => {
            callback(...args) // eslint-disable-line
        }, time);
    };
};
