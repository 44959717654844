export interface IDeveloper {
    _id: string;
    publisherId: string;
    cloudUserId: string;
    rocketChatUsername?: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: boolean;

    createdDate: string;
    modifiedDate: string;
    lastActivityDate: string;

    role: DeveloperRole;
    systemRole: DeveloperSystemRole;
}

export enum DeveloperRole {
    Viewer = 'viewer',
    Developer = 'developer',
    Owner = 'owner',
}

export enum DeveloperSystemRole {
    User = 'user',
    Viewer = 'viewer',
    Support = 'support',
    Reviewer = 'reviewer',
    Admin = 'admin',
}
