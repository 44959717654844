import React from 'react';
import qs from 'query-string';
import { Layout, Row, Col, Button, Table, Icon, Input, Popover, Dropdown, Menu, notification, Modal } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { ClickParam } from 'antd/lib/menu';

import { IPublisher } from 'models/publisher';
import { IDeveloper } from 'models/developer';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import Loading from 'containers/Loading';

import { AddDeveloperModal } from './addDeveloperModal';
import { ChangeDeveloperRoleModal } from './changeDeveloperRoleModal';

import { retrieveStripeUri } from 'api/oauth';
import { getPublisher, getPublisherDevelopers, updatePublisherInfo, retrieveStripeDashboardLink, removeDeveloper } from 'api/publisher';

interface IPublisherViewProps extends RouteComponentProps, IWithPermissionProps { }

interface IPublisherViewState {
    loading: boolean;
    isSaving: boolean;
    isEditing: boolean;
    stripeLink: string;
    isLoadingDashboard: boolean;
    addDeveloperVisible: boolean;
    changeDeveloperRoleVisible: boolean;
    changeRoleDeveloper?: IDeveloper;
    publisher?: IPublisher;
    developers: IDeveloper[];
}

class PublisherBaseView extends React.PureComponent<IPublisherViewProps, IPublisherViewState> {
    state: Readonly<IPublisherViewState> = {
        loading: true,
        isSaving: false,
        isEditing: false,
        stripeLink: '',
        isLoadingDashboard: false,
        addDeveloperVisible: false,
        changeDeveloperRoleVisible: false,
        developers: []
    };

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({ loading: true }, async () => {
            try {
                const [publisher, developers] = await Promise.all([getPublisher(), getPublisherDevelopers()]);

                if (!publisher.stripeAccountId) {
                    await this.loadStripeConnectLink();
                }

                this.setState({ publisher, developers });
            } catch (e) {
                notification.error({
                    message: 'An Error Occured',
                    description: (<span>
                        An error occured processing your request.<br />Code: {e.code}<br />Error: {e.error}
                    </span>),
                });
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    loadStripeConnectLink = async () => {
        const result = await retrieveStripeUri();

        const parsed = qs.parse(result.url.split('?')[1]);
        window.localStorage.setItem('rcMarketplaceStripe', JSON.stringify(parsed));

        this.setState({ stripeLink: result.url });
    }

    get disableSave() {
        // const formValues = this.props.form.getFieldsValue()
        // const errors = this.props.form.getFieldsError()
        // return Object.keys(errors).length === 0 || Object.keys(errors).some(key => errors[key] !== undefined) || ['email'].every(key => this.state[key] === formValues[key])
        return false;
    }

    toggleEditing = () => {
        this.setState({ isEditing: !this.state.isEditing });
    }

    saveButtonClick = () => {
        this.setState({ isSaving: true }, async () => {
            if (!this.state.publisher) {
                return;
            }

            await updatePublisherInfo(this.state.publisher);

            this.setState({ isSaving: false, isEditing: false });
        });
    }

    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { publisher } = this.state;
        if (!publisher) {
            return;
        }

        publisher.name = e.target.value;

        this.setState({ publisher });
    }

    onTOSChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { publisher } = this.state;

        if (!publisher) {
            return;
        }

        publisher.tosLink = e.target.value;

        this.setState({ publisher });
    }

    onPrivacyLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { publisher } = this.state;
        if (!publisher) {
            return;
        }

        publisher.privacyLink = e.target.value;

        this.setState({ publisher });
    }

    onWebhookLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { publisher } = this.state;
        if (!publisher) {
            return;
        }

        publisher.webhookLink = e.target.value;

        this.setState({ publisher });
    }

    onStripeConnect = () => {
        window.location.href = this.state.stripeLink;
    }

    onStripeDashboard = () => {
        this.setState({ isLoadingDashboard: true }, async () => {
            const result = await retrieveStripeDashboardLink();

            window.open(result.url, '_blank');
            this.setState({ isLoadingDashboard: false });
        });
    }

    get stripeConnectButtonPopover() {
        return (
            <div>We use <a href="https://stripe.com/connect" target="_blank" rel="noopener noreferrer">Stripe Connect</a> for payouts.</div>
        );
    }

    get stripeConnectDashboardPopover() {
        return (
            <div>View your account information<br />in the Stripe Connect Dashboard.</div>
        );
    }

    get stripeConnectButton() {
        if (!this.props.permissions.features.publisher.canUpdate) {
            return null;
        }

        if (!this.state.publisher) {
            return null;
        }

        if (this.state.publisher.stripeAccountId) {
            return (
                <Popover placement="bottom" title="Stripe Connect Dashboard" content={this.stripeConnectDashboardPopover}>
                    <Button
                        type="primary"
                        onClick={this.onStripeDashboard}
                        icon="link"
                        loading={this.state.isLoadingDashboard}
                    >Stripe Dashboard</Button>
                </Popover>
            );
        }

        return (
            <Popover placement="bottom" content={this.stripeConnectButtonPopover} title="Stripe Connect">
                <Button
                    type="primary"
                    onClick={this.onStripeConnect}
                    icon="api"
                    loading={!this.state.stripeLink}
                    disabled={!this.state.stripeLink}
                >Connect Stripe</Button>
            </Popover>
        );
    }

    //#region add developer
    toggleAddUserModal = () => {
        this.setState({ addDeveloperVisible: !this.state.addDeveloperVisible });
    }

    userModalClosedWithAdd = () => {
        this.loadData();

        this.toggleAddUserModal();
    }
    //#endregion add developer

    //#region change developer role
    closeChangeRoleModal = (saved: boolean) => {
        if (saved) {
            this.loadData();
        }

        this.setState({ changeRoleDeveloper: undefined, changeDeveloperRoleVisible: false });
    }
    //#endregion change developer role

    get headerButtons() {
        if (!this.props.permissions.features.publisher.canUpdate) {
            return null;
        }

        if (this.state.isEditing) {
            return (
                <Button.Group>
                    <Button disabled={this.state.isSaving} onClick={this.toggleEditing} icon="stop">Cancel</Button>
                    <Button type="primary" loading={this.state.isSaving} disabled={this.disableSave} onClick={this.saveButtonClick} icon="save">Save changes</Button>
                </Button.Group>
            );
        }

        return (
            <Button.Group>
                <Button onClick={this.toggleEditing} icon="edit">Edit</Button>
                <Button onClick={this.toggleAddUserModal} icon="user-add">Add User</Button>
                {this.stripeConnectButton}
            </Button.Group>
        );
    }

    get header() {
        return (
            <Layout.Header className='profile-header'>
                <h2>Publisher Profile</h2>
                {this.headerButtons}
            </Layout.Header>
        )
    }

    columns: ColumnProps<IDeveloper>[] = [
        {
            title: 'Name', key: 'name', render: (name, record) => record.firstName ? `${record.firstName} ${record.lastName}` : 'n/a',
        },
        {
            title: 'Email', dataIndex: 'email', key: 'email',
        },
        {
            title: 'Role', dataIndex: 'role', key: 'role', className: 'title-caps',
        },
        {
            title: 'Last Activity', dataIndex: 'lastActivityDate', key: 'lastActivityDate',
            render: (lastActivityDate) => new Date(lastActivityDate).toLocaleString(),
        },
        {
            title: 'Actions', key: 'actions',
            render: (nothing, record) => (
                <Dropdown overlay={this.getActionsOverlayFor(record)} trigger={['hover']}>
                    <span className="ant-dropdown-link">
                        Actions <Icon type="down" />
                    </span>
                </Dropdown>
            ),
        },
    ]

    getActionsOverlayFor(developer: IDeveloper) {
        return (
            <Menu onClick={this.onActionsMenuClick(developer)}>
                <Menu.Item key="change-role" disabled={!this.props.permissions.features.publisher.canUpdate}>
                    <Icon type="control" /> Change Role
                </Menu.Item>
                <Menu.Item key="remove" disabled={!this.props.permissions.features.publisher.canDelete}>
                    <Icon type="user-delete" /> Remove
                </Menu.Item>
            </Menu>
        );
    }

    onActionsMenuClick = (developer: IDeveloper) => {
        return async (clickInfo: ClickParam) => {
            console.log('actions menu click:', clickInfo);

            switch (clickInfo.key) {
                case 'change-role':
                    this.setState({ changeDeveloperRoleVisible: true, changeRoleDeveloper: developer });
                    break;
                case 'remove':
                    this.removeUserConfimation(developer);
                    break;
                default:
                    break;
            }
        };
    }

    removeUserConfimation = (developer: IDeveloper) => {
        Modal.confirm({
            title: `Are you sure you want to remove ${developer.firstName} ${developer.lastName}?`,
            content: 'Removing them will cause them to loose access to your publisher account and they will have to manually added back.',
            onOk: async () => {
                try {
                    await removeDeveloper(developer._id);
                    this.loadData();
                } catch (e) {
                    console.error('error while removing a developer:', e);
                    notification.error({
                        message: 'An Error Occured',
                        description: (<span>
                            An error occured processing your request.<br />Code: {e.code}<br />Error: {e.error}
                        </span>),
                    });
                }
            },
        });
    }

    get tosLink() {
        if (!this.state.publisher) {
            return null;
        }

        if (this.state.isEditing) {
            return (
                <div>
                    <Input onChange={this.onTOSChange} defaultValue={this.state.publisher!.tosLink} disabled={this.state.isSaving} />
                </div>
            );
        }

        return (
            <div>
                TOS Link: <a href={this.state.publisher.tosLink} target="_blank" rel="noopener noreferrer">{this.state.publisher.tosLink}</a>
            </div>
        );
    }

    get privacyLink() {
        if (!this.state.publisher) {
            return null;
        }

        if (this.state.isEditing) {
            return (
                <div>
                    <Input onChange={this.onPrivacyLinkChange} defaultValue={this.state.publisher.privacyLink} disabled={this.state.isSaving} />
                </div>
            );
        }

        return (
            <div>
                Privacy Link: <a href={this.state.publisher.privacyLink} target="_blank" rel="noopener noreferrer">{this.state.publisher.privacyLink}</a>
            </div>
        );
    }

    get webhookLink() {
        if (!this.state.publisher) {
            return null;
        }

        if (this.state.isEditing) {
            return (
                <div>
                    <Input onChange={this.onWebhookLinkChange} defaultValue={this.state.publisher.webhookLink} disabled={this.state.isSaving} />
                </div>
            );
        }

        if (!this.state.publisher.webhookLink) {
            return null;
        }

        return (
            <div>
                Webhook Link: {this.state.publisher.webhookLink}
            </div>
        );
    }

    get balances() {
        const { publisher } = this.state;

        if (!publisher || !publisher.stripeAccountId) {
            return null;
        }

        return (
            <div>
                <span>Available Balance: ${publisher.stripeAvailableBalance.toFixed(2)}</span><br />
                <span>Pending Balance: ${publisher.stripePendingBalance.toFixed(2)}</span>
            </div>
        );
    }

    get publisherView() {
        const { publisher, developers, isEditing, isSaving, addDeveloperVisible, changeDeveloperRoleVisible, changeRoleDeveloper } = this.state;

        return (
            <Layout>
                {this.header}
                <Layout.Content>
                    <Row>
                        <Col span={8}>
                            <div>{isEditing ? <Input onChange={this.onNameChange} defaultValue={publisher!.name} disabled={isSaving} /> : 'Name: ' + publisher!.name}</div>
                            {this.tosLink}
                            {this.privacyLink}
                            {this.webhookLink}
                            {this.balances}
                        </Col>
                    </Row>
                    <Row>
                        <Layout.Header className="profile-header">
                            <h2>Developers</h2>
                        </Layout.Header>

                        <Table<IDeveloper>
                            rowKey={(d) => d._id}
                            columns={this.columns}
                            dataSource={developers}
                        />
                    </Row>

                    <AddDeveloperModal visible={addDeveloperVisible} onCancel={this.toggleAddUserModal} onSave={this.userModalClosedWithAdd} />
                    <ChangeDeveloperRoleModal visible={changeDeveloperRoleVisible} developer={changeRoleDeveloper} onClose={this.closeChangeRoleModal} />
                </Layout.Content>
            </Layout>
        )
    }

    render() {
        return this.state.loading ? <Loading /> : this.publisherView;
    }
}

export const PublisherView = connectPermissions({ feature: 'publisher' })(withRouter(PublisherBaseView));
