import React from 'react';
import currency from 'currency.js';
import { Table, Row, Col, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IAppUsage, IAppUsageItem } from 'models/appUsage';

import { getAdminAppUsages } from 'api/admin';

interface IAdminAppUsageTableProps {
    appId: string;
}

interface IAdminAppUsageTableState {
    loading: boolean;
    usages: IAppUsage[];
    total: number;
    page: number;
}

export class AdminAppUsageTable extends React.PureComponent<IAdminAppUsageTableProps, IAdminAppUsageTableState> {
    state: Readonly<IAdminAppUsageTableState> = {
        loading: true,
        usages: [],
        total: 0,
        page: 0,
    };

    columns: ColumnProps<IAppUsage>[] = [
        {
            title: 'Workspace Nickname', dataIndex: 'workspaceLabel', key: 'workspaceLabel',
        },
        {
            title: 'Billing Cycle ID', dataIndex: 'billingCycleId', key: 'billingCycleId',
        },
        {
            title: 'Usage Items', dataIndex: 'items', key: 'usageItems',
            render: (items: IAppUsageItem[]) => `${ items.length }`,
        },
        {
            title: 'Total', dataIndex: 'items', key: 'totalAmount',
            render: (items: IAppUsageItem[]) => {
                let total = currency(0, { precision: 2 });

                items.forEach((i) => total = total.add(i.price));

                return `${ total.format(true) }`;
            },
        },
        {
			title: 'Date', dataIndex: 'createdDate',
			sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
			render: (text) => new Date(text).toLocaleDateString(),
		},
    ]

    componentDidMount() {
        this.fetchAppUsages();
    }

    onPaginationChange = (page: number) => {
        this.setState({ page }, this.fetchAppUsages);
    }

    fetchAppUsages = () => {
        if (!this.props.appId) {
            return;
        }

        this.setState({ loading: true }, async () => {
            try {
                let page = this.state.page;
                if (page > 0) {
                    page = page - 1;
                }

                const usagesResult = await getAdminAppUsages(this.props.appId, 5, page*5);

                this.setState((prevState) => {
                    return {
                        ...prevState,
                        total: usagesResult.meta && usagesResult.meta.total ? usagesResult.meta.total : 0,
                        usages: usagesResult.data,
                    };
                });
            } catch (e) {
                console.warn('failed to load the usage records', e);
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={4}>
                        <Typography.Title level={3}>Usage Records</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table
                            dataSource={this.state.usages}
                            columns={this.columns}
                            rowKey="id"
                            pagination={{
                                hideOnSinglePage: true,
                                pageSize: 5,
                                total: this.state.total,
                                current: this.state.page,
                                onChange: this.onPaginationChange,
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            }}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
