import React from 'react';
import { Row, Col, Typography, Table, Badge, Button, Tooltip, Icon } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { IPublisherAppOverview } from 'models/appOverview';
import { IPrice } from 'models/appPrice';
import { AppPurchaseType } from 'models/purchaseRecord';

import { ChangePriceDrawer } from './changePriceDrawer';

interface IPricsTableProps {
    app?: IPublisherAppOverview;
    prices: IPrice[];
    disallowChange?: boolean;
    refresh?: () => Promise<void>;
}

interface IPricesTableState {
    changeDrawerVisible: boolean;
}

export class PricesTable extends React.PureComponent<IPricsTableProps, IPricesTableState> {
    state: Readonly<IPricesTableState> = {
        changeDrawerVisible: false,
    };

    get uniquesTitle() {
        return (
            <React.Fragment>
                Uniques&nbsp;
                <Tooltip overlay="Amount of unique, purchase type specific conversions">
                    <Icon type="info-circle" />
                </Tooltip>
            </React.Fragment>
        );
    }

    get activesTitle() {
        return (
            <React.Fragment>
                Active Subscriptions&nbsp;
                <Tooltip overlay="Number of active subscriptions, includes externally managed">
                    <Icon type="info-circle" />
                </Tooltip>
            </React.Fragment>
        );
    }

    columns: ColumnProps<IPrice>[] = [
        {
            title: 'Type', key: 'name', dataIndex: 'name', className: 'title-caps',
            render: (value, price) => {
                let purchaseType: AppPurchaseType | 'free' = price.type;
                if (price.type === AppPurchaseType.Buy && price.buyPrice === 0) {
                    purchaseType = 'free';
                }

                if (!value) {
                    return purchaseType;
                }

                return (
                    <React.Fragment>
                        <Typography.Text>{ value }</Typography.Text><br />
                        <Typography.Text type="secondary">{ purchaseType }</Typography.Text>
                    </React.Fragment>
                );
            }
        },
        {
            title: 'Status', key: 'status', dataIndex: 'active',
            // sorter //TODO: enable sorting
            render: (active: boolean) => {
                return (
                    <Badge color={active ? '#2de0a5' : '#c4c4c4'} text={active ? 'Active' : 'Legacy'} />
                );
            }
        },
        {
            title: 'Price', key: 'price', className: 'title-caps',
            render: (value: undefined, price) => {
                if (price.type === AppPurchaseType.Buy) {
                    if (price.buyPrice === 0) {
                        return 'free';
                    } {
                        return `$${ price.buyPrice }`;
                    }
                }

                return (
                    <React.Fragment>
                        { price.monthly.enabled ? `$${ price.monthly.price.toFixed(2) } monthly` : null }
                        { price.monthly.enabled && price.yearly.enabled ? <br /> : null }
                        { price.yearly.enabled ? `$${ price.yearly.price.toFixed(2) } yearly` : null }
                    </React.Fragment>
                );
            }
        },
        {
            title: 'Trial (days)', key: 'trial', dataIndex: 'trialDuration',
            render: (trialDuration: number, price) => {
                if (!price.trialEnabled || price.type === AppPurchaseType.Buy) {
                    return '-';
                }

                return `${ trialDuration }`;
            }
        },
        {
            title: 'Created', key: 'createdDate', dataIndex: 'createdDate',
            sortOrder: 'descend', sorter: (a: IPrice, b: IPrice) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
            render: (createdDate: string) => {
                return new Date(createdDate).toLocaleDateString();
            }
        },
        {
            title: this.uniquesTitle, key: 'uniquePurchaseRecords', dataIndex: 'unique',
        },
        {
            title: this.activesTitle, key: 'activeSubscriptions', dataIndex: 'actives',
            render: (count: number, price: IPrice) => {
                if (price.type !== AppPurchaseType.Subscription) {
                    return '-';
                }

                return `${ count }`;
            }
        },
    ]

    onChangeClick = () => {
        this.setState({ changeDrawerVisible: true });
    }

    closeChangeDrawer = async (saved: boolean) => {
        if (saved && typeof this.props.refresh === 'function') {
            await this.props.refresh();
        }

        this.setState({ changeDrawerVisible: false });
    }

    render() {
        return (
            <React.Fragment>
                <Row type="flex" justify="start" style={{ marginTop: '20px' }}>
                    <Col span={2}>
                        <Typography.Title level={3}>Prices</Typography.Title>
                    </Col>
                    { this.props.disallowChange || (this.props.app && this.props.app.isEnterpriseOnly) ? null :
                        <Col span={4}>
                            <Button size="small" onClick={this.onChangeClick} style={{ marginTop: '6px' }}>Change</Button>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col span={24}>
                        <Table<IPrice>
                            dataSource={this.props.prices}
                            columns={this.columns}
                            rowKey={(job) => job.id}
                            pagination={{ hideOnSinglePage: true }}
                        />
                    </Col>
                </Row>

                <ChangePriceDrawer
                    app={this.props.app}
                    prices={this.props.prices}
                    isVisible={this.state.changeDrawerVisible}
                    close={this.closeChangeDrawer}
                />
            </React.Fragment>
        );
    }
}
