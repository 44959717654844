import React from 'react';
import moment from 'moment';
import { Drawer, Form, Row, Col, Divider, Select, Switch, Input, InputNumber, DatePicker, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { AdminAppMap, IAppOverview } from 'models/appOverview';
import { AppPurchaseType } from 'models/purchaseRecord';
import { IAdminCreatePurchaseRecordPayload } from 'models/payloads/admin';

import { getAdminApps, postAdminPurchase } from 'api/admin';

interface FormValues {
    appId: string;
    externallyManaged: boolean;
    workspaceId: string;
    planId: string;
    subscriptionSeats: number;
    subscriptionPeriodEnd: moment.Moment;
}

interface ICreatePurchaseRecordProps extends FormComponentProps<FormValues> {
    visible: boolean;
    close: () => void;
}

interface ICreatePurchaseRecordState {
    apps: IAppOverview[];
    appsMap: AdminAppMap;
    disabled: boolean;
    saving: boolean;
}

class CreatePurchaseRecordModalBase extends React.PureComponent<ICreatePurchaseRecordProps, ICreatePurchaseRecordState> {
    state: Readonly<ICreatePurchaseRecordState> = {
        apps: [],
        disabled: true,
        saving: false,
        appsMap: {},
    };

    constructor(props: ICreatePurchaseRecordProps) {
        super(props);
    }

    async componentDidMount() {
        try {
            const apps = await getAdminApps();

            const map: AdminAppMap = {};
            apps.forEach((a) => {
                map[a.appId] = a;
            });

            this.setState({ disabled: false, apps, appsMap: map });
        } catch (e) {
            console.warn('failed to load the apps for the create purchase record:', e);
        }
    }

    cancel = () => {
        this.props.close();
    }

    save = () => {
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (err) {
                return;
            }

            this.setState({ saving: true, disabled: true }, async () => {
                try {
                    const payload: IAdminCreatePurchaseRecordPayload = {
                        appId: values.appId,
                        workspaceId: values.workspaceId,
                        externallyManaged: values.externallyManaged,
                        planId: values.planId,
                        seats: values.subscriptionSeats || 0,
                        addonTierId: '',
                        periodEnd: values.subscriptionPeriodEnd ? values.subscriptionPeriodEnd.toJSON() : moment().toJSON(),
                    };

                    await postAdminPurchase(payload);
                    this.props.close();
                } catch (e) {
                    console.log('error while submitting creating a purchase record:', e);
                } finally {
                    this.setState({ saving: false, disabled: false });
                }
            });
        });
    }

    onAppChange = () => {
        if (this.props.form.getFieldValue('planId')) {
            this.props.form.setFieldsValue({ planId: '' });
        }
    }

    get appSelector() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="App">
                { getFieldDecorator('appId', {
                    rules: [{ required: true, message: 'Select the App which will be marked as purchased.' }],
                })(
                    <Select disabled={this.state.disabled} onChange={this.onAppChange}>
                        { this.state.apps.map((a) => (
                            <Select.Option key={a.appId} value={a.appId}>
                                { a.latest.name }
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        );
    }

    get externallyManaged() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Externally Managed">
                {getFieldDecorator('externallyManaged', {
                    valuePropName: 'checked',
                    initialValue: true,
                    rules: [{ required: true }],
                })(
                    <Switch disabled />
                )}
            </Form.Item>
        );
    }

    get workspaceIdInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Workspace ID" style={{ marginBottom: '45px' }}>
                {getFieldDecorator('workspaceId', {
                    initialValue: '',
                    rules: [{ required: true, message: 'A valid Workspace ID is required.' }],
                })(
                    <Input disabled={this.state.disabled} />
                )}
            </Form.Item>
        );
    }

    //#region subscription inputs
    get planSelector() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const app = this.state.appsMap[getFieldValue('appId')];
        if (!app || !app.pricingPlans) {
            return null;
        }

        return (
            <Form.Item label="Plan">
                {getFieldDecorator('planId', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please input the plan which should be used' }],
                })(
                    <Select disabled={this.state.disabled}>
                        { app.pricingPlans.map((plan) => (
                            <Select.Option key={plan.id} value={plan.id}>
                                { plan.strategy } (Is Per Seat: { plan.isPerSeat ? 'yes' : 'no' })
                            </Select.Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
        );
    }

    get subscriptionSeatsInput() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const app = this.state.appsMap[getFieldValue('appId')];
        const selectedPlanId = getFieldValue('planId');
        if (!app || !app.pricingPlans) {
            return null;
        }

        let disabledInput = this.state.disabled;
        if (selectedPlanId) {
            const plan = app.pricingPlans.find((plan) => plan.id === selectedPlanId);
            if (plan && !plan.isPerSeat) {
                disabledInput = true;
            }
        } else {
            disabledInput = true;
        }

        return (
            <Form.Item label="Seats">
                {getFieldDecorator('subscriptionSeats', {
                    initialValue: 1,
                    rules: [{ required: true, message: 'Please input the number of seats' }],
                })(
                    <InputNumber min={1} disabled={disabledInput} />
                )}
            </Form.Item>
        );
    }

    disabledPeriodEnd = (current: moment.Moment | null) => {
        return moment().add(-1, 'days') >= (current || moment());
    }

    get periodEndPicker() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Period End">
                {getFieldDecorator('subscriptionPeriodEnd', {
                    initialValue: moment(),
                    rules: [{ required: true, message: 'Please select the period end date' }],
                })(
                    <DatePicker
                        showTime
                        showToday
                        disabledDate={this.disabledPeriodEnd}
                        disabled={this.state.disabled}
                    />
                )}
            </Form.Item>
        );
    }

    get subscriptionInfoInputs() {
        const selectedAppId = this.props.form.getFieldValue('appId');
        if (!selectedAppId) {
            return null;
        }

        const app = this.state.appsMap[selectedAppId];
        if (!app) {
            return null;
        }

        if (app.purchaseType !== AppPurchaseType.Subscription) {
            return null;
        }

        return (
            <Row gutter={24}>
                <Divider type="horizontal">Subscription Info</Divider>
                <Col span={24}>
                    { this.planSelector }
                </Col>
                <Col span={12}>
                    { this.subscriptionSeatsInput }
                </Col>
                <Col span={12}>
                    { this.periodEndPicker }
                </Col>
            </Row>
        );
    }
    //#endregion subscription inputs


    render() {
        return (
            <Drawer
                visible={this.props.visible}
                title="Create a Purchase Record"
                width="500"
                onClose={this.cancel}
                closable={!this.state.saving}
                maskClosable={!this.state.saving}
            >
                <Form>
                    <Row gutter={16}>
                        <Col span={16}>
                            { this.appSelector }
                        </Col>
                        <Col span={8}>
                            { this.externallyManaged }
                        </Col>
                    </Row>

                    { this.subscriptionInfoInputs }

                    <Row gutter={16}>
                        <Divider type="horizontal">Workspace Info</Divider>
                        <Col>{ this.workspaceIdInput }</Col>
                    </Row>
                </Form>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                        zIndex: 1234,
                    }}
                >
                    <Button onClick={this.cancel} disabled={this.state.disabled} style={{ marginRight: 8 }}>Cancel</Button>
                    <Button onClick={this.save} type="primary" disabled={this.state.disabled} loading={this.state.saving}>Save</Button>
                </div>
            </Drawer>
        );
    }
}

export const CreatePurchaseRecordModal = Form.create<ICreatePurchaseRecordProps>()(CreatePurchaseRecordModalBase);
