import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, notification, Table, Icon, Menu, Dropdown, message } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { ColumnProps } from 'antd/lib/table';

import { IPublisher } from 'models/publisher';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import { getAdminPublishers } from 'api/admin';

import { EditPublisherDrawer } from './editPublisherDrawer';
import { ContactPublisherDrawer } from './contactPublisherDrawer';
import { getCloudOAuthInfo } from 'api/misc';

interface IPublishersListViewProps extends RouteComponentProps, IWithPermissionProps {}

interface IPublishersListViewState {
    loading: boolean;
    publishers: IPublisher[];
    drawerVisible: boolean;
    editingPublisher?: IPublisher;
    contactDrawerVisible: boolean;
    emailingPublisher?: IPublisher;
    cloudUrl: string;
}

class AdminPublishersListViewBase extends React.PureComponent<IPublishersListViewProps, IPublishersListViewState> {
    state: Readonly<IPublishersListViewState> = {
        loading: true,
        publishers: [],
        drawerVisible: false,
        contactDrawerVisible: false,
        cloudUrl: 'https://cloud.rocket.chat',
    };

    async componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IPublishersListViewProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:publishers'].canRead) {
            console.log('user can not read the system:publishers');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Publishers. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const publishers = await getAdminPublishers();
        const cloudOAuthInfo = await getCloudOAuthInfo();

        this.setState({
            loading: false,
            publishers,
            cloudUrl: cloudOAuthInfo.cloudUrl,
        });
    }

    //#region edit drawer
    drawerClose = async () => {
        this.setState({ drawerVisible: false, contactDrawerVisible: false, editingPublisher: undefined, emailingPublisher: undefined });
    }

    drawerFinished = async () => {
        await this.loadData();
        this.drawerClose();
    }
    //#endregion edit drawer

    onActionClick = (publisher: IPublisher) => async ({ key }: ClickParam) => {
        switch (key) {
            case 'edit':
                this.setState({ drawerVisible: true, editingPublisher: publisher });
                return;
            case 'info':
                this.props.history.push({ pathname: `/publisher/admin/publishers/${publisher._id}` });
                return;
            case 'email':
                this.setState({ contactDrawerVisible: true, emailingPublisher: publisher });
                return;
            default:
                message.info(`You clicked on the ${key} action item on ${publisher.name}`);
                break;
        }
    }

    getActionMenuItems = (publisher: IPublisher) => {
        return (
            <Menu onClick={this.onActionClick(publisher)}>
                <Menu.Item key="info">
                    <Icon type="info-circle" /> View Details
                </Menu.Item>
                <Menu.Item key="edit" disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="edit" /> Edit
                </Menu.Item>
                <Menu.Item key="email" disabled={!this.props.permissions.features['system:publishers'].canUpdate}>
                    <Icon type="mail" /> Email
                </Menu.Item>
            </Menu>
        );
    }

    columns: ColumnProps<IPublisher>[] = [
        {
            key: 'name', title: 'Publisher Name', dataIndex: 'name', defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            key: 'balances', title: 'Available | Pending',
            render: (_, record) => !record.stripeAccountId ? '-' : `$${ record.stripeAvailableBalance.toFixed(2) } | $${ record.stripePendingBalance.toFixed(2) }`
        },
        {
            key: 'canCreatePaidApps', title: 'Create Paid Apps?',
            render: (text, record) => record.canCreatePaidApps ? 'Yes' : 'No',
        },
        {
            key: 'canCreateBundles', title: 'Create Bundles?', width: '15%',
            render: (text, record) => record.canCreateBundles ? 'Yes' : 'No',
        },
        {
            key: 'canUseNPMModules', title: 'Use NPM Modules?', width: '15%',
            render: (text, record) => record.canUseNPMModules ? 'Yes' : 'No',
        },
        {
            key: 'accountid',  title: 'Account ID', dataIndex: 'accountId',
            render: (accountId: string) => {
                if (!accountId) {
                    return <span>Not linked</span>;
                }

                const url = `${ this.state.cloudUrl }/admin/accounts/${accountId}`;

                return <a href={url} target="_blank" rel="noopener noreferrer">{accountId} <Icon type="export" /></a>;
            },
        },
        {
            key: 'actions', title: 'Actions',
            render: (nothing, record) => {
                return (
                    <Layout>
                        <Dropdown overlay={this.getActionMenuItems(record)} trigger={['hover']}>
                            <span className="ant-dropdown-link">
                                Actions <Icon type="down" />
                            </span>
                        </Dropdown>
                    </Layout>
                );
            }
        },
    ]

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Publishers</h2>
                </Layout.Header>

                <Layout.Content>
                    <Table
                        loading={this.state.loading}
                        columns={this.columns}
                        dataSource={this.state.publishers}
                        rowKey={(p) => p._id}
                    />

                    <EditPublisherDrawer publisher={this.state.editingPublisher} isVisible={this.state.drawerVisible} finished={this.drawerFinished} close={this.drawerClose} />
                    <ContactPublisherDrawer publisher={this.state.emailingPublisher} isVisible={this.state.contactDrawerVisible} finished={this.drawerFinished} close={this.drawerClose} />
                </Layout.Content>
            </Layout>
        )
    }
}

export const AdminPublisherListView = connectPermissions({ feature: 'system:publishers', system: true })(withRouter(AdminPublishersListViewBase));
