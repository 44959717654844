import React, { useState } from 'react';
import { nanoid } from 'nanoid';
import { Unsubscribe } from 'redux';

import { store } from 'store';
import { checkForAccess, checkForMultipleAccess, SeralizedPermissionChecks } from 'store/slices/accessControl';

import { serializeEnforceRequest, permissionActions, areObjectsEquivalent } from 'utils/auth';

//#region prop types
export type FeatureAccess = {
    canRead: boolean;
    canCreate: boolean;
    canUpdate: boolean;
    canDelete: boolean;
};

export type WithPermissionFeatureAccess = { [key: string]: FeatureAccess };

export type WithPermissionPermissionProp = {
    loaded: boolean;
    has: (feature: string, permission: string, group?: string) => boolean;
    features: WithPermissionFeatureAccess;
};

export interface IWithPermissionProps {
    permissions: WithPermissionPermissionProp;
}
//#endregion prop types

interface IPermissionData {
    feature: string;
    system?: boolean;
}

interface WithHasPermissionReworkedState {
    instanceId: string;
    loaded: boolean;
    features: WithPermissionFeatureAccess;
    hasPermissions: SeralizedPermissionChecks;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debugLog(...args: any[]) {
    if (process.env.NODE_ENV !== 'production' || window.localStorage.getItem('MARKETPLACE_DEBUGGING')) {
        console.log(...args);
    }
}

export function connectPermissions(data?: IPermissionData) {
    const instanceId = nanoid();

    let initialFeaturesValue: WithPermissionFeatureAccess = {};
    if (data && data.feature) {
        initialFeaturesValue = {
            [data.feature]: { canRead: false, canCreate: false, canUpdate: false, canDelete: false },
        };
    }

    let count = 0;

    return function _innerConnectPermissions<P extends IWithPermissionProps>(ComponentToWrap: React.ComponentType<P>) {
        type Props = Omit<P, "permissions">;
        const displayName = ComponentToWrap.displayName || ComponentToWrap.name || 'Component';

        class ComponentWithPermissions extends React.PureComponent<Props, WithHasPermissionReworkedState> {
            static displayName = `connectPermissions(${displayName})`;
            unsubscribeFromRedux?: Unsubscribe;

            state: Readonly<WithHasPermissionReworkedState> = {
                instanceId,
                loaded: false,
                features: initialFeaturesValue,
                hasPermissions: {},
            }

            componentDidMount() {
                this.unsubscribeFromRedux = store.subscribe(this.storeUpdated);
                this.loadOrUpdatePermissions();
            }

            componentWillUnmount() {
                if (this.unsubscribeFromRedux) {
                    this.unsubscribeFromRedux();
                }
            }

            storeUpdated = () => {
                debugLog(instanceId, 'store updated');
                const { accessControl } = store.getState();

                //I understand the following can be combined into one if statement
                //however, this makes it easier to debug problems that arise
                if (!accessControl.loaded) {
                    debugLog(instanceId, 'the access control system is not loaded yet');
                    return;
                }

                if (typeof accessControl.developer === 'undefined') {
                    debugLog(instanceId, 'the developer data is undefined (not loaded yet)')
                    return;
                }

                this.loadOrUpdatePermissions();
            }

            loadOrUpdatePermissions = () => {
                count++;
                debugLog(instanceId, 'loading or updating the permissions', count);

                const rootState = store.getState();
                if (!rootState.accessControl.loaded || !rootState.accessControl.developer) {
                    debugLog(instanceId, 'access control enforcer not loaded yet');
                    return;
                }

                if (!data || !data.feature) {
                    if (!this.state.loaded) {
                        debugLog(instanceId, 'setting the HOC state as loaded');
                        this.setState({ loaded: true });
                    }

                    if (Object.keys(this.state.hasPermissions).length === Object.keys(rootState.accessControl.serializedChecks).length) {
                        return;
                    }

                    debugLog(instanceId, 'updating the local instance cache of seralizedChecks');
                    this.setState({ hasPermissions: rootState.accessControl.serializedChecks });

                    return;
                }

                const info = {
                    serializedChecks: rootState.accessControl.serializedChecks,
                    developerId: rootState.accessControl.developer ? rootState.accessControl.developer._id : '',
                    publisherId: rootState.accessControl.developer ? rootState.accessControl.developer.publisherId : '',
                };

                let group = info.publisherId;
                if (data.system) {
                    group = 'system';
                }

                const checkedActions = permissionActions.map((a) => {
                    const s = serializeEnforceRequest(data.feature, a, info.developerId, group);
                    return info.serializedChecks[s];
                });

                let internallyLoaded = false;
                if (checkedActions.includes(undefined)) {
                    debugLog(instanceId, 'the initial checks indicated the check for access has not happened yet, calling it now', checkedActions);
                    store.dispatch(checkForMultipleAccess({ feature: data.feature, permissions: permissionActions, group }));
                    return;
                } else {
                    internallyLoaded = true;
                }

                //TODO: if the checked actions is cached and already in the state, the state will never get `loaded` updated to true

                const result: FeatureAccess = { canRead: checkedActions[0] || false, canCreate: checkedActions[1] || false, canUpdate: checkedActions[2] || false, canDelete: checkedActions[3] || false };

                if (this.state.features[data.feature] && areObjectsEquivalent(this.state.features[data.feature], result)) {
                    debugLog(instanceId, 'the feature access checks in the state are the same as the checked result');
                    this.setState({ loaded: internallyLoaded }); //TODO: verify this is needed/helpful?
                    return;
                }

                this.setState({
                    loaded: internallyLoaded,
                    features: {
                        ...this.state.features,
                        [data.feature]: result,
                    },
                });
            }

            hasPermission = (feature: string, permission: string, group?: string) => {
                debugLog(instanceId, 'has?:', feature, permission, group);

                if (!group) {
                    group = 'system';
                }

                const rootState = store.getState();
                const info = {
                    serializedChecks: rootState.accessControl.serializedChecks,
                    loaded: rootState.accessControl.loaded,
                    developerId: rootState.accessControl.developer ? rootState.accessControl.developer._id : '',
                    publisherId: rootState.accessControl.developer ? rootState.accessControl.developer.publisherId : '',
                };

                if (!info.loaded || !info.developerId) {
                    store.dispatch(checkForAccess({ feature, permission: permission, group }));
                    return false;
                }

                const s = serializeEnforceRequest(feature, permission, info.developerId, group);
                if (typeof info.serializedChecks[s] === 'undefined') {
                    store.dispatch(checkForAccess({ feature, permission: permission, group }));
                    return false;
                }

                if (this.state.hasPermissions[s] !== info.serializedChecks[s]) {
                    debugLog(instanceId, 'updating the local state with updated permission check:', s, info.serializedChecks[s]);

                    this.setState({
                        hasPermissions: {
                            ...this.state.hasPermissions,
                            [s]: info.serializedChecks[s],
                        },
                    });
                }

                // it can be undefined thus the validation check of true
                // aka: failing safe
                return info.serializedChecks[s] === true;
            }

            render() {
                return (
                    <ComponentToWrap
                        {...(this.props as P)}
                        permissions={{
                            loaded: this.state.loaded,
                            features: this.state.features,
                            has: this.hasPermission,
                        }}
                    />
                );
            }
        }

        ComponentWithPermissions.displayName = `connectPermissions(${displayName})`;

        return ComponentWithPermissions
    }
}

export function usePermissions() {
    const [instanceId] = useState(nanoid());

    const hasPermission = (feature: string, permission: string, group?: string) => {
        debugLog(instanceId, 'has?:', feature, permission, group);

        if (!group) {
            group = 'system';
        }

        const rootState = store.getState();
        const info = {
            serializedChecks: rootState.accessControl.serializedChecks,
            loaded: rootState.accessControl.loaded,
            developerId: rootState.accessControl.developer ? rootState.accessControl.developer._id : '',
            publisherId: rootState.accessControl.developer ? rootState.accessControl.developer.publisherId : '',
        };

        if (!info.loaded || !info.developerId) {
            store.dispatch(checkForAccess({ feature, permission: permission, group }));
            return false;
        }

        const s = serializeEnforceRequest(feature, permission, info.developerId, group);
        if (typeof info.serializedChecks[s] === 'undefined') {
            store.dispatch(checkForAccess({ feature, permission: permission, group }));
            return false;
        }

        // it can be undefined thus the validation check of true
        // aka: failing safe
        return info.serializedChecks[s] === true;
    }

    return hasPermission;
}
