import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Dropdown, Empty, Icon, Layout, message, Result, Table, Menu } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { ClickParam } from 'antd/lib/menu';

import { IWebhookEndpoint } from 'models/webhookEndpoint';
import { IPublisher } from 'models/publisher';

import { NewWebhookDrawer } from './newWebhookDrawer';

import { getPublisher } from 'api/publisher';
import { getWebhooks } from 'api/webhooks';

interface IWebhooksProps extends RouteComponentProps {}

interface IWebhooksState {
    loading: boolean;
    webhooks: IWebhookEndpoint[];
    publisher?: IPublisher;
    newDrawerVisible: boolean;
}

class WebhooksListViewBase extends React.PureComponent<IWebhooksProps, IWebhooksState> {
    state: Readonly<IWebhooksState> = {
        loading: true,
        webhooks: [],
        newDrawerVisible: false,
    };

    async componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const publisher = await getPublisher();

        let webhooks: IWebhookEndpoint[] = [];
        if (publisher.canUseWebhooks) {
            webhooks = await getWebhooks();
        }

        this.setState({ webhooks, publisher, loading: false });
    }

    get notEnabledMessage() {
        if (!this.state.publisher) {
            return null;
        }

        return (
            <Result
                status="403"
                title="Webhooks Disabled"
                subTitle="Sorry, you webhooks are disabled for your publisher account."
                extra={<Button type="primary" href={`mailto:marketplace@rocket.chat?subject=Webhook%20Access%20Request&body=To%20whom%20it%20may%20concern%2C%0D%0A%0D%0AI%20would%20like%20to%20gain%20access%20to%20the%20Webhooks%20system%20in%20Rocket.Chat%20Publisher%20portal.%0D%0A%0D%0AMy%20publisher%20id%20is%3A%20${ this.state.publisher._id }%0D%0A%0D%0AThank%20you`}>Request Access</Button>}
            />
        );
    }

    get emptyText() {
        return (
            <Empty description="No webhooks found! Create one today 🎉">
                <Button type="primary" onClick={this.onNewWebhookClick} disabled={this.state.loading}>New Webhook</Button>
            </Empty>
        );
    }

    get headerButton() {
        if (this.state.publisher && !this.state.publisher.canUseWebhooks) {
            return null;
        }

        return (
            <div className="applist-header-buttons">
                <Button type="primary" icon="plus" onClick={this.onNewWebhookClick} disabled={this.state.loading}>New Webhook</Button>
            </div>
        );
    }

    onNewWebhookClick = () => {
        this.setState({ newDrawerVisible: true });
    }

    onCloseNewDrawer = async (saved: boolean) => {
        if (saved) {
            await this.loadData();
        }

        this.setState({ newDrawerVisible: false });
    }

    onActionClick = (webhook: IWebhookEndpoint) => async ({ key }: ClickParam) => {
		switch (key) {
            case 'details':
                this.props.history.push(`/publisher/webhooks/${ webhook.id }`);
                return;
            case 'edit':
                console.log('editing:', webhook);
                message.info('would be editing it!');
                return;
            default:
                message.info(`unimplemented action ${ key } on the webhook ${ webhook.label }`);
        }
    }

    buildActionOverlay = (webhook: IWebhookEndpoint) => {
        return (
			<Menu onClick={this.onActionClick(webhook)}>
                <Menu.Item key="details">
                    <Icon type="info-circle" /> Details
                </Menu.Item>
                <Menu.Item key="edit" disabled>
                    <Icon type="edit" /> Edit
                </Menu.Item>
			</Menu>
		);
    }

    renderActions = (_nothing: void, webhook: IWebhookEndpoint) => {
		return (
			<Dropdown overlay={this.buildActionOverlay(webhook)}>
				<span className="ant-dropdown-link">
					Actions <Icon type="down" />
				</span>
			</Dropdown>
		);
	}

    tableColumns: ColumnProps<IWebhookEndpoint>[] = [
        { title: 'Label', dataIndex: 'label', key: 'label' },
        { title: 'Enabled', dataIndex: 'enabled', key: 'enabled', render: (enabled: boolean) => enabled ? 'Yes' : 'No' },
        { title: 'Url', dataIndex: 'url', key: 'url' },
        { title: 'Events', dataIndex: 'for', key: 'for', ellipsis: true, render: (forEvents: string[]) => Array.isArray(forEvents) ? forEvents.join(', ') : '-' },
        { title: 'Actions', key: 'actions', render: this.renderActions },
    ]

    get webhooksTable() {
        return (
            <Table
                loading={this.state.loading}
                dataSource={this.state.webhooks}
                columns={this.tableColumns}
                locale={{
                    emptyText: this.emptyText,
                }}
            />
        );
    }

    render() {
        return (
            <Layout className="applist">
                <Layout.Header className="applist-header">
                    <h2>Webhooks</h2>

                    {this.headerButton}
                </Layout.Header>
                <Layout.Content>
                    { this.state.publisher && !this.state.publisher.canUseWebhooks ? this.notEnabledMessage : this.webhooksTable }

                    <NewWebhookDrawer visible={this.state.newDrawerVisible} close={this.onCloseNewDrawer} />
                </Layout.Content>
            </Layout>
        );
    }
}

export const WebhooksListView = withRouter(WebhooksListViewBase);
