import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { Button, Icon, Layout, message } from 'antd';
import { CollapseType } from 'antd/lib/layout/Sider';

import { SideBar } from 'containers/Sidebar';

import { store } from 'store';
import { loadAccessControl } from 'store/slices/accessControl';

import { getPublisher } from 'api/publisher';
import { getCloudOAuthInfo } from 'api/misc';

import './main-layout.less';

//#region views
import { RegisterView } from 'views/Register';
import { PublisherView } from 'views/Publisher';
import { AppsListView } from 'views/AppsList';
import { AppView } from 'views/App';
import { NewAppBundleView } from 'views/NewAppBundle';
import { BundlesListView } from 'views/BundlesList';
import { BundleView } from 'views/Bundle';
import { ProfileView } from 'views/Profile';
import { AdminDashboardView } from 'views/Admin';
import { AdminAppsView } from 'views/Admin/Apps';
import { AdminAppView } from 'views/Admin/App';
import { AdminBundlesListView } from 'views/Admin/Bundles';
import { AdminBundleView } from 'views/Admin/Bundle';
import { EmailTemplateListView } from 'views/Admin/Emails';
import { AdminPublisherListView } from 'views/Admin/Publishers';
import { AdminPublisherView } from 'views/Admin/Publishers/details';
import { AdminPurchasesListView } from 'views/Admin/Purchases';
import { AdminWorkspaceView } from 'views/Admin/Workspace';
import { AdminJobsList } from 'views/Admin/Jobs';
import { AdminCategoriesList } from 'views/Admin/Categories';
import { StripeOAuthCallbackView } from 'views/oAuth/stripe';
import { NewAppExtendedView } from 'views/NewAppExtended';
import { UpdateAppExtendedView } from 'views/NewAppExtended/update';
import { EditDetailsView } from 'views/EditDetails';
import { WebhooksListView } from 'views/Webhooks';
import { WebhookView } from 'views/Webhooks/webhookView';
import { PurchasesListView } from 'views/Purchases';
import { AdminFeaturedList } from 'views/Admin/Featured';

//#endregion views

interface IMainLayoutProps extends RouteComponentProps, DispatchProp { }

interface IMainLayoutState {
    collapsed: boolean;
    showTrigger: boolean;
    isLoading: boolean;
}

class MainLayout extends React.PureComponent<IMainLayoutProps, IMainLayoutState> {
    state: Readonly<IMainLayoutState> = {
        collapsed: false,
        showTrigger: false,
        isLoading: true,
    }

    async componentWillMount() {
        await store.dispatch(loadAccessControl());
        const cloudOAuth = await getCloudOAuthInfo();

        try {
            const pub = await getPublisher();

            // if the publisher is on the register page and the publisher is already flagged as registered
            // then send them to their list of apps
            if (this.props.history.location.pathname === '/publisher/register' && pub.isRegistered) {
                message.info('Thank you for signing up for a Rocket.Chat Marketplace Publisher account!');
                this.props.history.push({ pathname: '/publisher/apps' });
                return;
            }

            // If the publisher isn't registered, then let's do some actions
            if (!pub.isRegistered) {
                // if they're not on the registration page, then let's send them there
                // so that they can register
                if (this.props.history.location.pathname !== '/publisher/register') {
                    this.props.history.push('/publisher/register');
                }

                // return out here so that non-registered publishers don't see the sidebar
                return;
            }
        } catch (e) {
            //TODO: Fix this now that the error being caught here is a different format
            console.error('[main layout] error', e);

            if (e === 401) {
                const oauthState = Math.random().toString(36).substring(2);
                window.localStorage.setItem(oauthState, window.location.href);
                window.location.href = `${cloudOAuth.cloudUrl}/authorize?client_id=${cloudOAuth.cloudClientId}&scope=marketplace&redirect_uri=${cloudOAuth.marketplaceUrl}/oauth/callback&state=${oauthState}`;
                return;
            }

            if (e === 404 && this.props.history.location.pathname !== '/publisher/register') {
                this.props.history.push('/publisher/register');
                return;
            }
        }
    }

    onSidebarCollapseTriggerClick() {
        this.setState({ collapsed: !this.state.collapsed });
    }

    get sidebarCollapseTrigger() {
        const { collapsed, showTrigger } = this.state

        if (!showTrigger) {
            return null;
        }

        return (
            <Button onClick={this.onSidebarCollapseTriggerClick.bind(this)} style={{ position: 'absolute', right: collapsed ? -48 : 0, top: 0, zIndex: 1 }}>
                <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
            </Button>
        );
    }

    detectResponsiveCollapse(collapsed: boolean, type: CollapseType) {
        this.setState({ collapsed })

        if (type === 'responsive') {
            this.setState({ showTrigger: collapsed })
        }
    }

    render() {
        const { collapsed } = this.state;

        return (
            <Layout className='main-layout'>
                <Layout.Sider
                    className='sidebar'
                    {...(collapsed !== null ? { collapsed } : {})}
                    collapsedWidth={0}
                    breakpoint='md'
                    width={280}
                    trigger={null}
                    onCollapse={(collapsed, type) => this.detectResponsiveCollapse(collapsed, type)}
                >
                    <SideBar collapsed={collapsed} />
                    {this.sidebarCollapseTrigger}
                </Layout.Sider>

                <Layout.Content className="main-content">
                    <Switch>
                        <Redirect exact from='/publisher' to='/publisher/apps' />

                        <Route exact path='/publisher/register' component={RegisterView} />
                        <Route exact path='/publisher/info' component={PublisherView} />
                        <Route exact path='/publisher/apps' component={AppsListView} />
                        <Route exact path='/publisher/apps/:id' component={AppView} />
                        <Route exact path="/publisher/new-extended/app/:appId?/:versionId?/:step?" component={NewAppExtendedView} />
                        <Route exact path="/publisher/update-extended/app/:appId/:versionId?/:step?" component={UpdateAppExtendedView} />
                        <Route exact path="/publisher/edit-details/app/:appId/:versionId?/:step?" component={EditDetailsView} />
                        <Route exact path='/publisher/new/bundle' component={NewAppBundleView} />
                        <Route exact path='/publisher/bundles' component={BundlesListView} />
                        <Route exact path='/publisher/bundles/:id' component={BundleView} />
                        <Route exact path='/publisher/profile' component={ProfileView} />
                        <Route exact path="/publisher/webhooks" component={WebhooksListView} />
                        <Route exact path="/publisher/webhooks/:id" component={WebhookView} />
                        <Route exact path="/publisher/purchases" component={PurchasesListView} />

                        <Route exact path='/publisher/admin' component={AdminDashboardView} />
                        <Route exact path='/publisher/admin/apps' component={AdminAppsView} />
                        <Route exact path='/publisher/admin/apps/:id' component={AdminAppView} />
                        <Route exact path='/publisher/admin/bundles' component={AdminBundlesListView} />
                        <Route exact path='/publisher/admin/bundles/:id' component={AdminBundleView} />
                        <Route exact path='/publisher/admin/emails' component={EmailTemplateListView} />
                        <Route exact path='/publisher/admin/purchases/:purchaseId?' component={AdminPurchasesListView} />
                        <Route exact path='/publisher/admin/publishers' component={AdminPublisherListView} />
                        <Route exact path='/publisher/admin/publishers/:id' component={AdminPublisherView} />
                        <Route exact path='/publisher/admin/workspace/:workspaceId?' component={AdminWorkspaceView} />
                        <Route exact path='/publisher/admin/jobs' component={AdminJobsList} />
                        <Route exact path='/publisher/admin/categories' component={AdminCategoriesList} />
                        <Route exact path='/publisher/admin/featured' component={AdminFeaturedList} />

                        <Route exact path='/publisher/oauth/stripe' component={StripeOAuthCallbackView} />
                    </Switch>
                </Layout.Content>
            </Layout>
        );
    }
}

export default connect()(withRouter(MainLayout));
