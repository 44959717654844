import React from 'react';
import { Form, Icon, Spin, Upload, Button, Typography, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DraggerProps } from 'antd/lib/upload';

import { IPublisherAppOverview } from 'models/appOverview';

import { postNewApp, startAppUpdate } from 'api/apps';

interface IStep0Props extends FormComponentProps {
    app?: IPublisherAppOverview;
    goNext: () => void;
    isUpdate?: boolean;
    setApp: (app: IPublisherAppOverview) => Promise<void>;
}

interface IStep0State {
    submitting: boolean;
    file?: File;
}

class NewAppStepZeroViewBase extends React.PureComponent<IStep0Props, IStep0State> {
    state: Readonly<IStep0State> = {
        submitting: false,
    };

    get uploadExtra() {
        return (
            <p className="upload-description">
                The Rocket.Chat Marketplace allows submission of zip files. These files can be created by running <Typography.Text code>rc-apps package --no-compile</Typography.Text>, which packages your app and creates the zip file under <Typography.Text code>dist/</Typography.Text> folder.
                <br />
                Learn more at <a href="https://developer.rocket.chat/apps-engine/app-submission-to-the-marketplace#app-submission-through-cli" target="_blank" rel="noopener noreferrer">App Submission through CLI</a>
            </p>
        );
    }

    get uploadApp() {
        const uploadProps: DraggerProps = {
            accept: 'application/x-compressed,application/x-zip-compressed,application/zip,multipart/x-zip',
            multiple: false,
            showUploadList: true,
            disabled: this.state.submitting,
            beforeUpload: (file: File) => {
                this.setState({ file });
                return false;
            },
            onRemove: () => {
                this.setState({ file: undefined });
            },
        };

        return (
            <Form.Item>
                { this.uploadExtra }

                <Spin spinning={this.state.submitting}>
                    <Upload.Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Drag and drop the app package here</p>
                        <p className="ant-upload-hint">or browse to choose the file</p>
                    </Upload.Dragger>
                </Spin>
            </Form.Item>
        );
    }

    onNextClick = () => {
        if (!this.state.file) {
            return;
        }

        this.setState({ submitting: true }, async () => {
            const data = new FormData();
            data.set('app', this.state.file!);

            try {
                let app: IPublisherAppOverview;
                if (this.props.isUpdate) {
                    app = await startAppUpdate(this.props.app!.appId, data);
                } else {
                    app = await postNewApp(data);
                }

                await this.props.setApp(app!);

                this.props.goNext();
            } catch (e) {
                this.setState({ submitting: false });
                if (typeof e.error === 'string') {
                    notification.error({ message: `${e.error} (${e.code})` });
                } else {
                    console.log('error occured while submitting an app:', e);
                }
            }
        });
    }

    get nextButton() {
        return (
            <Button
                type="primary"
                size="large"
                onClick={this.onNextClick}
                loading={this.state.submitting}
                disabled={this.state.submitting || !this.state.file}
            >
                Next
            </Button>
        );
    }

    render() {
        return (
            <Form hideRequiredMark>
                { this.uploadApp }
                { this.nextButton }
            </Form>
        );
    }
}

export const NewAppStepZeroView = Form.create<IStep0Props>()(NewAppStepZeroViewBase);
