import React from 'react';
import { Layout, Row, Col, Badge, Avatar, Descriptions, Typography, Button, Icon, Modal, Tag, List } from 'antd';
import { withRouter, Link, RouteComponentProps } from 'react-router-dom';

import { IAppOverview, IPublisherAppOverview } from 'models/appOverview';
import { AppInfoVersionMapByInternalId } from 'models/appInfo';
import { IAppStatistics } from 'models/appStatus';
import { IJob } from 'models/compiler/job';
import { IPrice } from 'models/appPrice';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

import AppsTable from 'views/AppsList/AppsTable';
import Loading from 'containers/Loading';
import { JobsTable } from 'components/jobs/index';
import { PricesTable } from 'components/prices/table';

import './app.less';

import { statusToColor, capitalizeFirstLetter } from 'utils/appStatus';
import { getApp, getAppVersions, getAppStats, getAppJobs, getAppPrices } from 'api/apps';

interface IAppViewProps extends RouteComponentProps<{ id: string }>, IWithPermissionProps { }

interface IAppViewState {
    loading: boolean;
    app?: IPublisherAppOverview;
    versions: IAppOverview[];
    versionById: AppInfoVersionMapByInternalId;
    jobs: IJob[];
    stats?: IAppStatistics;
    prices: IPrice[];
}

class AppViewBase extends React.PureComponent<IAppViewProps, IAppViewState> {
    intervalId?: NodeJS.Timeout;

    state: Readonly<IAppViewState> = {
        loading: true,
        versions: [],
        versionById: {},
        jobs: [],
        prices: [],
    };

    uploadUpdateClick = () => this.props.history.push({ pathname: `/publisher/update-extended/app/${ this.props.match.params.id }` });

    async componentDidMount() {
        await this.loadInformation();

        this.intervalId = setInterval(this.loadInformation.bind(this), 10000);
    }

    componentWillUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    loadInformation = async () => {
        const { id } = this.props.match.params;

        const [app, versions, stats, jobs, prices] = await Promise.all([
            getApp(id),
            getAppVersions(id),
            getAppStats(id),
            getAppJobs(id),
            getAppPrices(id),
        ]);

        const versionById: AppInfoVersionMapByInternalId = {};
        const apps: IAppOverview[] = versions.map((v) => {
            versionById[v.internalId!] = v;

            return {
                ...app,
                latest: v,
            };
        });

        this.setState({
            loading: false,
            app,
            versions: apps,
            stats,
            jobs,
            versionById,
            prices,
        });
    }

    onActionTaken = async () => {
        await this.loadInformation();
    }

    onStatsClick = () => {
        if (!this.state.app || !this.state.stats) {
            return;
        }

        const { stats } = this.state;
        const { purchaseType, latest } = this.state.app;

        const content = [<List.Item key='totalDownloads'>Total Downloads: {stats.totalDownloads}</List.Item>];
        if (purchaseType === 'buy') {
            content.push(<List.Item key='purchases'>Purchases: {stats.purchases}</List.Item>);
        } else {
            content.push(<List.Item key='activeSubscriptions'>Active Subscriptions: {stats.activeSubscriptions}</List.Item>);
            content.push(<List.Item key='inactiveSubscriptions'>Inactive Subscriptions: {stats.inactiveSubscriptions}</List.Item>);
        }

        Modal.info({
            title: latest.name + '\'s Stats',
            content: (
                <Layout>
                    <List>{content}</List>

                    <h3>Downloads</h3>
                    <List
                        dataSource={Object.keys(stats.appVersions)}
                        renderItem={(v, index) => {
                            const stat = stats.appVersions[v];

                            return (
                                <List.Item key={'version-' + v + '-' + index}>v{v}: {stat.downloads}</List.Item>
                            );
                        }}
                    />
                </Layout>
            ),
        });
    };

    get appBundleHeaderDescription() {
        if (!this.state.app) {
            return null;
        }

        const { bundledIn } = this.state.app;

        return bundledIn?.map((b) =>
            <Tag key={"bundleTag-" + b.bundleId} color="geekblue">
                <Link to={{ pathname: `/publisher/bundles/${b.bundleId}` }}>{b.bundleName}</Link>
            </Tag>
        );
    }

    get appHeader() {
        const { app } = this.state;
        if (!app) {
            return null;
        }

        const descriptions = [
            <Descriptions.Item key="shortDescription" label="Short Description" span={3}>{app.latest.shortDescription || '-'}</Descriptions.Item>,
            <Descriptions.Item key="longDescription" label="Long Description" span={3}>{app.latest.description}</Descriptions.Item>,
            <Descriptions.Item key="categories" label="Categories" span={3}>{app.latest.categories.join(', ')}</Descriptions.Item>,
            <Descriptions.Item key="status" label="Status">
                <Badge color={statusToColor[app.latest.status]} text={capitalizeFirstLetter(app.latest.status)} />
            </Descriptions.Item>,
        ];

        if (app?.bundledIn?.length !== 0) {
            descriptions.push(<Descriptions.Item key="bundledIn" label="Bundled In" span={2}>{this.appBundleHeaderDescription}</Descriptions.Item>);
        }

        descriptions.push(<Descriptions.Item key="purchaseType" label="Purchase Type">{capitalizeFirstLetter(app.purchaseType)}</Descriptions.Item>);
        descriptions.push(
            <Descriptions.Item key="appStats" label="Stats">
                <Button type="dashed" onClick={this.onStatsClick}>View Stats</Button>
            </Descriptions.Item>
        );

        return (
            <Layout.Content>
                <Row type="flex" justify="space-between">
                    <Col span={8}>
                        <Typography.Title level={2}>{app.latest.name}</Typography.Title>
                    </Col>
                    {this.props.permissions.features['app'].canUpdate ?
                        <Col span={4}>
                            <Button type="primary" onClick={this.uploadUpdateClick}>
                                <Icon type="cloud-upload" /> Upload Update</Button>
                        </Col>
                    : null}
                </Row>
                <Row type="flex" align="middle">
                    <Col span={2}>
                        <Avatar className='avatar' size='large' shape='square' src={`data:image/jpg;base64,${app.latest.iconFileData}`} />
                    </Col>
                    <Col span={22}>
                        <Descriptions bordered>
                            {descriptions}
                        </Descriptions>
                    </Col>
                </Row>
            </Layout.Content>
        )
    }

    get appView() {
        return (
            <Layout>
                {this.appHeader}

                <Layout.Content>
                    <Row>
                        <Col span={24} style={{ marginBottom: '25px' }}>
                            <PricesTable app={this.state.app} prices={this.state.prices} refresh={this.loadInformation} />
                        </Col>

                        <Col span={4}>
                            <Typography.Title level={3}>Versions</Typography.Title>
                        </Col>

                        <Col span={24}>
                            <AppsTable
                                type="versions"
                                tableProps={{
                                    loading: this.state.loading,
                                    dataSource: this.state.versions,
                                    rowKey: (record) => record.latest.version,
                                    pagination: { hideOnSinglePage: true },
                                }}
                                onActionTaken={this.onActionTaken}
                                canUpdate={this.props.permissions.features['app'].canUpdate}
                            />
                        </Col>

                        <Col span={24}>
                            <JobsTable jobs={this.state.jobs} versionById={this.state.versionById} />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }

    render() {
        return this.state.loading ? <Loading /> : this.appView
    }
}

export const AppView = connectPermissions({ feature: 'app' })(withRouter(AppViewBase));
