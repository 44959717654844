import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Table, Dropdown, Menu, Icon, Button, notification } from 'antd';
import { ClickParam } from 'antd/lib/menu';
import { ColumnProps } from 'antd/lib/table';

import { IEmailTemplate } from 'models/emailTemplate';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

import { getAdminEmailTemplates } from 'api/admin';

import { AdminEditEmailTemplateDrawer } from './editEmailDrawer';

interface IEmailTemplateListViewProps extends RouteComponentProps, IWithPermissionProps {}

interface IEmailTemplateListViewState {
    loading: boolean;
    isDrawerVisible: boolean;
    emailTemplates: IEmailTemplate[];
    editingTmpl?: IEmailTemplate;
}

class EmailTemplateListViewBase extends React.PureComponent<IEmailTemplateListViewProps, IEmailTemplateListViewState> {
    state: Readonly<IEmailTemplateListViewState> = {
        loading: true,
        isDrawerVisible: false,
        emailTemplates: [],
    };

    async componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: IEmailTemplateListViewProps) {
        if (!prevProps.permissions.loaded && this.props.permissions.loaded) {
            this.loadData();
        }
    }

    loadData = async () => {
        if (!this.props.permissions.loaded) {
            console.log('permission system has not been loaded yet.');
            return;
        }

        if (!this.props.permissions.features['system:emailTemplates'].canRead) {
            console.log('user can not read the system:emailTemplates');
            notification.error({
                message: 'Invalid Access Level',
                description: 'It appears you do not have access to read the system Email Templates. If you believe this to be incorrect, please contact the Cloud Team.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const templates = await getAdminEmailTemplates();

        this.setState({
            loading: false,
            emailTemplates: templates,
        });
    }

    onActionClick = (emailTemplate: IEmailTemplate) => async ({ key }: ClickParam) => {
        switch (key) {
            case 'edit':
                this.setState({ isDrawerVisible: true, editingTmpl: emailTemplate });
                return;
            default:
                return;
        }
    }

    getEmailTemplateMenuItems = (emailTemplate: IEmailTemplate) => {
        return (
            <Menu onClick={this.onActionClick(emailTemplate)}>
                <Menu.Item key="edit">
                    <Icon type="edit" /> Edit
                </Menu.Item>
            </Menu>
        );
    }

    renderActions = (action: void, emailTemplate: IEmailTemplate) => {
        const overlay = this.getEmailTemplateMenuItems(emailTemplate);

        return (
            <Layout>
                <Dropdown overlay={overlay} trigger={['hover']}>
                    <span className="ant-dropdown-link">
                        Actions <Icon type="down" />
                    </span>
                </Dropdown>
            </Layout>
        );
    }

    columns: ColumnProps<IEmailTemplate>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            sorter: (a, b) => a.subject.localeCompare(b.subject),
        },
        {
            title: 'Parent Layout',
            dataIndex: 'layout',
            sorter: (a, b) => a.layout.localeCompare(b.layout),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: this.renderActions,
        }
    ]

    onDrawerClose = () => {
        this.setState({ isDrawerVisible: false, editingTmpl: undefined });
    }

    onDrawerSuccess = async () => {
        await this.loadData();
    }

    onNewButtonClick = () => {
        this.setState({ isDrawerVisible: true, editingTmpl: undefined });
    }

    render() {
        return (
            <Layout className='applist'>
                <Layout.Header className='applist-header'>
                    <h2>Email Templates</h2>
                    <Button type="primary" icon="plus" onClick={this.onNewButtonClick} style={{ marginRight: '15px' }}>New Email Template</Button>
                </Layout.Header>
                <Layout.Content>
                    <Table
                        columns={this.columns}
                        dataSource={this.state.emailTemplates}
                        rowKey={(e) => e.id}
                    />

                    <AdminEditEmailTemplateDrawer
                        visible={this.state.isDrawerVisible}
                        onClose={this.onDrawerClose}
                        onSuccess={this.onDrawerSuccess}
                    />
                </Layout.Content>
            </Layout>
        )
    }
}

export const EmailTemplateListView = connectPermissions({ feature: 'system:emailTemplates', system: true })(withRouter(EmailTemplateListViewBase));
