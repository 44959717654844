import React from 'react';
import { Drawer, Row, Col, Form, Button, Icon, Radio, InputNumber, Checkbox, notification, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IAppPricingPlan, IAppPricingTier } from 'models/appPricing';

interface INewAppPricingPlanProps extends FormComponentProps {
    isVisible: boolean;
    close: () => void;
    finished: (plan: Partial<IAppPricingPlan>) => void;
}

interface INewAppPricingPlanState {
    tiers: IAppPricingTier[];
}

class NewAppPricingPlanBase extends React.PureComponent<INewAppPricingPlanProps, INewAppPricingPlanState> {
    state: Readonly<INewAppPricingPlanState> = {
        tiers: [],
    };

    onClose = () => {
        this.props.close();

        this.props.form.resetFields();
        this.setState({
            tiers: [],
        });
    };

    handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        const { form } = this.props;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            //if it is per seat, ensure there is at least two tiers otherwise it should be flat rate
            if (values.isPerSeat && this.state.tiers.length <= 1) {
                notification.error({
                    message: 'Invalid Tier Count',
                    description: 'When the pricing plan is per seat there must be at least two tiers.',
                });

                return;
            }

            if (!values.isPerSeat && values.price <= 0) {
                notification.error({
                    message: 'Invalid Flat Price',
                    description: 'When the pricing plan is not per seat, the price must be greater than 0.',
                });
                return;
            }


            //TODO: Fix this
            this.props.finished({
                strategy: values.strategy,
                price: values.isPerSeat ? 0 : values.price,
                trialDays: values.trialDays,
                isPerSeat: values.isPerSeat,
                tiers: this.state.tiers,
            });

            setTimeout(() => {
                if (!this.props.isVisible) {
                    //reset the form and state
                    form.resetFields();
                    this.setState({
                        tiers: [],
                    });
                }
            }, 500);
        });
    }

    //#region plan strategy
    get planStrategy() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Strategy" colon={false}>
                {getFieldDecorator('strategy', {
                    initialValue: 'monthly',
                    rules: [{ required: true, message: 'Please select the plan\'s strategy.' }]
                })(
                    <Radio.Group buttonStyle="solid">
                        <Radio.Button value="monthly">Monthly</Radio.Button>
                        <Radio.Button value="yearly">Yearly</Radio.Button>
                    </Radio.Group>
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region trial days
    get trailDaysInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Trial Days" colon={false}>
                {getFieldDecorator('trialDays', {
                    initialValue: 14,
                    rules: [{ required: true, message: 'Please set the number of days for the trial.' }]
                })(
                    <InputNumber min={0} max={30} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region flat price
    get flatPriceInput() {
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const isDisabled = getFieldValue('isPerSeat');

        return (
            <Form.Item label="Flat Price" colon={false}>
                {getFieldDecorator('price', {
                    initialValue: 3,
                    rules: [{ required: this.state.tiers.length === 0, message: 'Please set price per month (only used when no tiers).' }]
                })(
                    <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                        min={0.00}
                        max={9999}
                        step={0.01}
                        disabled={isDisabled}
                    />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region is per seat
    get isPerSeatInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Is Per Seat" colon={false}>
                {getFieldDecorator('isPerSeat', {
                    valuePropName: 'checked',
                    initialValue: false,
                    rules: [{ required: true, message: 'Please set whether this is per seat or not' }]
                })(
                    <Checkbox>Yes</Checkbox>
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region tiers
    onNewTierClick = () => {
        this.setState((prevState) => {
            let min = 1;
            let max = 50;
            if (prevState.tiers.length > 0) {
                const lastTier = prevState.tiers[prevState.tiers.length-1];
                min = lastTier.maximum+1;
                max = min + 99;
            }

            const newTier: IAppPricingTier = { price: 0.01, perUnit: true, minimum: min, maximum: max };

            return {
                ...prevState,
                tiers: [...prevState.tiers, newTier],
            };
        });
    }

    handleTierPriceChange = (tierIndex: number) => {
        return (value: number | undefined) => {
            const tier = this.state.tiers[tierIndex];
            tier.price = value ? value : 0;

            this.setState((prevState) => {
                const tiers = [...prevState.tiers];
                tiers[tierIndex] = tier;

                return {
                    ...prevState,
                    tiers,
                };
            });
        }
    }

    handleTierPerUnitChange = (tierIndex: number) => {
        return (e: CheckboxChangeEvent) => {
            const tier = this.state.tiers[tierIndex];
            tier.perUnit = e.target.checked;

            this.setState((prevState) => {
                const tiers = [...prevState.tiers];
                tiers[tierIndex] = tier;

                return {
                    ...prevState,
                    tiers,
                };
            });
        }
    }

    handleTierMinimumChange = (tierIndex: number) => {
        return (value: number | undefined) => {
            const tier = this.state.tiers[tierIndex];
            tier.minimum = value ? value : 0;

            this.setState((prevState) => {
                const tiers = [...prevState.tiers];
                tiers[tierIndex] = tier;

                return {
                    ...prevState,
                    tiers,
                };
            });
        }
    }

    handleTierMaximumChange = (tierIndex: number) => {
        return (value: number | undefined) => {
            const tier = this.state.tiers[tierIndex];
            tier.maximum = value ? value : 100;

            this.setState((prevState) => {
                const tiers = [...prevState.tiers];
                tiers[tierIndex] = tier;

                return {
                    ...prevState,
                    tiers,
                };
            });
        }
    }

    removeTier = (tierIndex: number) => {
        return () => {
            const tiers = this.state.tiers;
            tiers.splice(tierIndex, 1);

            this.setState((prevState) => {
                const theTiers = [...tiers];

                return {
                    ...prevState,
                    tiers: theTiers,
                };
            });
        }
    }

    get tiersInputLabel() {
        return (
            <span>Tiers &nbsp;
                <Button
                    size="small"
                    onClick={this.onNewTierClick}
                    icon="plus"
                >New tier</Button>
            </span>
        );
    }

    get tiersInputArea() {
        return (
            <Form.Item label={this.tiersInputLabel} required={true} colon={false}>
                {this.state.tiers.map((t, index) => {
                    return (
                        <Row key={'tier-' + index}>
                            <Col offset={2} span={22}>
                                <span><Button size="small" type="dashed" onClick={this.removeTier(index)}><Icon type="close-square" /></Button> Tier {index + 1}:</span>
                                { t.price === 0 ? <Alert message="This tier is free, please ensure this is desired." type="warning" /> : null }
                                <ul style={{ listStyle: 'none' }}>
                                    <li style={{ marginTop: '5px' }}>
                                        <span>Price: </span>
                                        <InputNumber
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                                            min={0}
                                            size="small"
                                            step={0.01}
                                            value={t.price}
                                            onChange={this.handleTierPriceChange(index)}
                                        />
                                    </li>
                                    <li style={{ marginTop: '5px' }}>
                                        <span>Per Unit: </span>
                                        <Checkbox checked={t.perUnit} onChange={this.handleTierPerUnitChange(index)}></Checkbox>
                                    </li>
                                    <li style={{ marginTop: '5px' }}>
                                        <span>Minimum: </span>
                                        <InputNumber
                                            min={1}
                                            step={1}
                                            size="small"
                                            value={t.minimum}
                                            onChange={this.handleTierMinimumChange(index)}
                                        />
                                    </li>
                                    <li style={{ marginTop: '5px' }}>
                                        <span>Maximum: </span>
                                        <InputNumber
                                            min={-1}
                                            step={1}
                                            size="small"
                                            value={t.maximum}
                                            onChange={this.handleTierMaximumChange(index)}
                                        />
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    );
                })}
            </Form.Item>
        );
    }
    //#endregion

    render() {
        const { getFieldValue } = this.props.form;

        return (
            <Drawer
                title="New Pricing Plan"
                width={500}
                onClose={this.onClose}
                visible={this.props.isVisible}
            >
                <Form layout="vertical" id="new-pricing-plan">
                    <Row gutter={16}>
                        <Col span={12}>{this.planStrategy}</Col>
                        <Col span={12}>{this.trailDaysInput}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{this.flatPriceInput}</Col>
                        <Col span={12}>{this.isPerSeatInput}</Col>
                    </Row>
                    { getFieldValue('isPerSeat') ?
                        <Row gutter={16}>
                            <Col span={24}>{this.tiersInputArea}</Col>
                        </Row>
                    : null}
                </Form>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} style={{ marginRight: 8 }}>Cancel</Button>
                    <Button onClick={this.handleSubmit} type="primary">Save</Button>
                </div>
            </Drawer>
        );
    }
}

export const NewAppPricingPlan = Form.create<INewAppPricingPlanProps>()(NewAppPricingPlanBase);
