import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Avatar, Table, Menu, Icon, Dropdown, Badge, Tooltip, Tag, Modal, Input, message, Layout, notification } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { ClickParam } from 'antd/lib/menu';

import { IAdminAppOverview, ISimpleBundleInfo } from 'models/appOverview';
import { AppStatus, IAppInfo } from 'models/appInfo';
import { AdminAppAction } from 'models/payloads/admin';

import { DiagnosticsViewerModal } from 'components/jobs/diagnosticsViewer';
import { MakeEnterpriseOnlyModal } from 'components/makeEnterpriseOnlyModal';
import { MakeAppUsageBasedModal } from 'components/makeAppUsageBased';
import { IWithPermissionProps, connectPermissions } from 'components/access/hasPermissionHOC';

import { getAdminDownloadUrl, postAdminAction } from 'api/admin';

import { statusToColor, statusSorter, capitalizeFirstLetter } from 'utils/appStatus';

interface IAppsTableProps extends RouteComponentProps, IWithPermissionProps {
    type: 'adminVersions' | 'adminApps' | 'bundles' | 'adminBundleVersions' | 'adminBundledApps';
    tableProps: TableProps<IAdminAppOverview>;
    onActionTaken: () => void;
}

interface IAppsTableState {
    diagnosticsViewerVisible: boolean;
    selectedApp?: IAppInfo;
    selectedOverview?: IAdminAppOverview;
    isEEOnlyModalVisible: boolean;
    isUsageBasedModalVisible: boolean;
    isLoading: boolean;
}

class AppsTable extends React.PureComponent<IAppsTableProps, IAppsTableState> {
	state: Readonly<IAppsTableState> = {
        diagnosticsViewerVisible: false,
        isEEOnlyModalVisible: false,
        isUsageBasedModalVisible: false,
        isLoading: false,
    };

	renderBundleBadges = (bundles?: ISimpleBundleInfo[]) => {
		if (!bundles || bundles.length === 0) {
			return;
		}

		return bundles.map((b) =>
			<Tag key={"bundleTag-" + b.bundleId} color="geekblue">
				{b.bundleName}
			</Tag>
		);
	};

	renderName = (name: string, app: IAdminAppOverview) => (
		<div className='app-td-wrapper'>
			<Avatar className='app-avatar' size='small' shape='square'
				src={`data:image/jpg;base64,${app.latest.iconFileData}`} />
			<span className='app-name table-column-name'> {app.latest.name} {this.renderBundleBadges(app?.bundledIn)}</span>
		</div>
	);

	renderStatus = (status: AppStatus, app: IAdminAppOverview) => {
		return (
			<div className='app-status'>
				<Badge color={statusToColor[status]} text={capitalizeFirstLetter(status)} />
				{
					status === 'rejected' ?
						<Tooltip placement="top" arrowPointAtCenter title={app.latest.rejectionNote}>
							&nbsp;<Icon type="alert" theme="twoTone" twoToneColor="#f00" />
						</Tooltip>
                    : null
				}
				{
					status === 'reviewed' || status === 'compiler-error' ?
						<Tooltip placement="top" arrowPointAtCenter title={app.latest.reviewedNote}>
							&nbsp;<Icon type="alert" theme="twoTone" twoToneColor="#ff9d00" />
						</Tooltip>
                    : null
				}
			</div>
		);
	}

	renderVersion = (version: string, app: IAdminAppOverview) => {
		if (!app.latest.changesNote) {
			return <span>v{app.latest.version}</span>;
		}

		return (
			<Tooltip placement="top" arrowPointAtCenter title={app.latest.changesNote}>
				<span>v{app.latest.version}</span>&nbsp;<Icon type="info-circle" theme="twoTone" twoToneColor="#cccc00" />
			</Tooltip>
		);
	}

    onMakeAsDraftClick = (app: IAdminAppOverview) => {
		return new Promise((resolve) => {
			Modal.confirm({
				title: `Set ${app.latest.name} v${app.latest.version} as a Draft?`,
				content: `Are you sure you want to make ${app.latest.name} v${app.latest.version} a draft again? Doing so enables the publisher to edit the details of the App again.`,
				onOk() {
					resolve(true);
				},
				onCancel() {
					resolve(false);
				},
			});
		});
	}

	onApproveClick = (app: IAdminAppOverview) => {
		return new Promise((resolve) => {
			Modal.confirm({
				title: `Approve ${app.latest.name} v${app.latest.version}?`,
				content: `Are you sure you want to approve ${app.latest.name} v${app.latest.version}? Approving the ${ app.isBundle ? 'Bundle' : 'App' } allows the publisher to publish it and make it public.`,
				onOk() {
					resolve(true);
				},
				onCancel() {
					resolve(false);
				},
			});
		});
	}

	onRejectClick = (app: IAdminAppOverview): Promise<{ reject: boolean, reason?: string }> => {
		let value = '';

		return new Promise((resolve) => {
			Modal.confirm({
				icon: <Icon type="warning" />,
				title: `Reject ${app.latest.name} v${app.latest.version}?`,
				content: (
					<div>
						<p>Are you sure you want to reject {app.latest.name} v{app.latest.version}? Rejecting the { app.isBundle ? 'Bundle' : 'App' } prevents the publisher from making it public and replacing that version.</p>
						<Input.TextArea rows={4} placeholder="Rejection reason" onChange={(e) => value = e.target.value} />
					</div>
				),
				onOk() {
					resolve({ reject: true, reason: value });
				},
				onCancel() {
					resolve({ reject: false });
				},
			});
		});
	}

	onReviewClick = (app: IAdminAppOverview): Promise<{ reviewed: boolean, note: string }> => {
		let value = '';

		return new Promise((resolve) => {
			Modal.confirm({
				icon: <Icon type="issues-close" />,
				title: `Review ${app.latest.name} v${app.latest.version}?`,
				content: (
					<div>
						<p>What would you like the author of {app.latest.name} v{app.latest.version} to change? What are your review notes?</p>
						<Input.TextArea rows={4} placeholder="Rejection reason" onChange={(e) => value = e.target.value} />
					</div>
				),
				onOk() {
					resolve({ reviewed: true, note: value });
				},
				onCancel() {
					resolve({ reviewed: false, note: '' });
				},
			});
		});
	}

    onEnterpriseClick = (app: IAdminAppOverview) => {
        this.setState({ selectedOverview: app, isEEOnlyModalVisible: true });
	}

    onEEModalClose = () => {
        if (typeof this.props.onActionTaken === 'function') {
            this.props.onActionTaken();
        }

        this.setState({ selectedOverview: undefined, isEEOnlyModalVisible: false });
    }

    onUsageBasedClick = (app: IAdminAppOverview) => {
        this.setState({ selectedOverview: app, isUsageBasedModalVisible: true });
    }

    onUsageBasedModalClose = () => {
        if (typeof this.props.onActionTaken === 'function') {
            this.props.onActionTaken();
        }

        this.setState({ selectedOverview: undefined, isUsageBasedModalVisible: false });
    }

	onDiagnosticsViewerClose = () => {
		this.setState({ diagnosticsViewerVisible: false, selectedApp: undefined });
	}

	onActionClick = (app: IAdminAppOverview) => async ({ key }: ClickParam) => {
        try {
            switch (key) {
                case 'info':
                    this.props.history.push({ pathname: `/publisher/admin/${ app.isBundle ? 'bundles' : 'apps' }/${app.latest.id}` });
                    return;
                case 'reject': {
                    const rejectResult = await this.onRejectClick(app);
                    if (rejectResult.reject) {
                        await postAdminAction(app.latest.id, app.latest.internalId!, AdminAppAction.Reject, { rejected: true, rejectionReason: rejectResult.reason! });
                        message.success(`${app.latest.name} v${app.latest.version} is rejected!`);
                    }
                    break;
                }
                case 'set-draft':
                    if (await this.onMakeAsDraftClick(app)) {
                        await postAdminAction(app.latest.id, app.latest.internalId!, AdminAppAction.MakeDraft, { approved: true });
                        message.success(`${app.latest.name} v${app.latest.version} is approved!`);
                    }
                    break;
                case 'approve':
                    if (await this.onApproveClick(app)) {
                        this.setState({ isLoading: true });
                        await postAdminAction(app.latest.id, app.latest.internalId!, AdminAppAction.Approve, { approved: true });
                        message.success(`${app.latest.name} v${app.latest.version} is approved!`);
                        this.setState({ isLoading: false });
                    }
                    break;
                case 'review': {
                    const reviewResult = await this.onReviewClick(app);
                    if (reviewResult.reviewed) {
                        await postAdminAction(app.latest.id, app.latest.internalId!, AdminAppAction.Review, { review: true, reviewNote: reviewResult.note });
                        message.success(`${app.latest.name} v${app.latest.version} is reviewed!`);
                    }
                    break;
                }
                case 'unpublish':
                    message.info(`Unpublishing the app ${app.latest.name} v${app.latest.version}`);
                    await postAdminAction(app.latest.id, app.latest.internalId!, AdminAppAction.Unpublish, { unpublish: true });
                    message.success(`${app.latest.name} v${app.latest.version} is now unpublished.`);
                    break;
                case 'publish':
                    message.info(`Publishing the app ${app.latest.name} v${app.latest.version}`);
                    await postAdminAction(app.latest.id, app.latest.internalId!, AdminAppAction.Publish, { publish: true });
                    message.success(`${app.latest.name} v${app.latest.version} is now published!`);
                    break;
                case 'download': {
                    message.info(`Downloading the source of the app: ${app.latest.name} v${app.latest.version}`);
                    const url = await getAdminDownloadUrl(app.latest.internalId!, false);
                    window.open(url, '_blank');
                    break;
                }
                case 'download-compiled': {
                    message.info(`Downloading the compiled version of the app: ${app.latest.name} v${app.latest.version}`);
                    const downloadUrl = await getAdminDownloadUrl(app.latest.internalId!, true);
                    window.open(downloadUrl, '_blank');
                    break;
                }
                case 'view-publisher':
                    this.props.history.push({ pathname: `/publisher/admin/publishers/${app.publisher.id}` });
                    break;
                case 'compiler-error-view':
                    this.setState({ diagnosticsViewerVisible: true, selectedApp: app.latest });
                    return;
                case 'queue-compile':
                    message.info(`Requesting a compile for ${ app.latest.name } v${ app.latest.version }`);
                    await postAdminAction(app.latest.id, app.latest.internalId!, AdminAppAction.Compile, {});
                    message.success(`${app.latest.name} v${app.latest.version} is now being compiled!`);
                    break;
                case 'enterpriseOnly':
                    this.onEnterpriseClick(app);
                    break;
                case 'usageBased':
                    this.onUsageBasedClick(app);
                    break;
                default:
                    message.info(`Unimplemented option: ${key}`);
                    return;
            }

            this.props.onActionTaken && this.props.onActionTaken();
        } catch (e) {
            console.error(`error while taking the "${ key }" action:`, e);

            if (e && typeof e === 'object') {
                notification.error({
                    message: 'An Error Occured',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    description: (<span>An error occured processing your request.<br />Code: { (e as any).code }<br />Error: { (e as any).error }</span>),
                });
            }
        }
	};

	getAppsListMenuItems = (app: IAdminAppOverview, showDetails: boolean) => {
		return (
			<Menu onClick={this.onActionClick(app)}>
				{showDetails ? <Menu.Item key="info"><Icon type="info-circle" /> View Details</Menu.Item> : null}
                {showDetails && !app.isBundle && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="enterpriseOnly" disabled={app.isEnterpriseOnly}><Icon type="money-collect" /> Make Premium Only</Menu.Item> : null }
                {showDetails && !app.isBundle && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="usageBased" disabled={app.isUsageBased}><Icon type="dot-chart" /> Make Usage Based</Menu.Item> : null }
				{!app.isBundle && this.props.permissions.features['system:apps'].canUpdate ?
					<Menu.Item key="download">
						<Icon type="download" /> Download Source
					</Menu.Item>
				: null}
				{!app.isBundle && this.props.permissions.features['system:apps'].canUpdate ?
					<Menu.Item key="download-compiled" disabled={!app.latest.compiled}>
						<Icon type="download" /> Download Compiled
					</Menu.Item>
				: null}
				{!app.isBundle && this.props.permissions.features['system:apps'].canUpdate && !showDetails ?
					<Menu.Item key="queue-compile">
						<Icon type="experiment" /> Queue Compile
					</Menu.Item>
				: null}
				{!app.isBundle && app.latest.status === AppStatus.CompilerError ?
					<Menu.Item key="compiler-error-view">
						<Icon type="monitor" /> View Diagnostics
					</Menu.Item>
				: null}

                {!showDetails && app.latest.status === AppStatus.Compiled && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="set-draft"><Icon type="edit" /> Mark as Draft</Menu.Item> : null}
				{app.latest.status === AppStatus.Compiled && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="review"><Icon type="warning" /> Review</Menu.Item> : null}
				{app.latest.status === AppStatus.Compiled && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="approve"><Icon type="check" /> Approve</Menu.Item> : null}
				{app.latest.status === AppStatus.Compiled && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="reject"><Icon type="close" /> Reject</Menu.Item> : null}
				{app.latest.status === AppStatus.Published && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="unpublish"><Icon type="pause-circle" /> Unpublish</Menu.Item> : null}
				{(app.latest.status === AppStatus.Approved || app.latest.status === 'unpublished') && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="publish"><Icon type="thunderbolt" /> Publish</Menu.Item> : null}

				{ this.props.permissions.has('system:publishers', 'read') ?
					<Menu.Item key="view-publisher">
						<Icon type="profile" /> View Publisher
					</Menu.Item>
				: null }
			</Menu>
		);
	}

    getBundleListMenuItems = (app: IAdminAppOverview, showDetails: boolean) => {
        return (
            <Menu onClick={this.onActionClick(app)}>
                {showDetails ? <Menu.Item key="info"><Icon type="info-circle" /> View Details</Menu.Item> : null}

                {showDetails && app.latest.status === AppStatus.Submitted && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="approve"><Icon type="check" /> Approve</Menu.Item> : null}
                {showDetails && app.latest.status === AppStatus.Submitted && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="reject"><Icon type="close" /> Reject</Menu.Item> : null}
                {showDetails && app.latest.status === AppStatus.Published && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="unpublish"><Icon type="pause-circle" /> Unpublish</Menu.Item> : null}
                {showDetails && (app.latest.status === AppStatus.Approved || app.latest.status === AppStatus.Unpublished) && this.props.permissions.features['system:apps'].canUpdate ? <Menu.Item key="publish"><Icon type="thunderbolt" /> Publish</Menu.Item> : null}

                { this.props.permissions.has('system:publishers', 'read') ?
					<Menu.Item key="view-publisher">
						<Icon type="profile" /> View Publisher
					</Menu.Item>
				: null }
            </Menu>
        );
    }

	renderActions = (action: void, app: IAdminAppOverview) => {
		let overlay;

		switch (this.props.type) {
			case 'bundles':
                overlay = this.getBundleListMenuItems(app, true);
                break;
            case 'adminBundleVersions':
                overlay = this.getAppsListMenuItems(app, false);
                break;
			case 'adminApps':
			case 'adminBundledApps':
				overlay = this.getAppsListMenuItems(app, true);
				break;
			default:
				overlay = this.getAppsListMenuItems(app, false);
				break;
		}

		return (
			<Layout>
				<Dropdown overlay={overlay} trigger={['hover']}>
					<span className="ant-dropdown-link">
						Actions <Icon type="down" />
					</span>
				</Dropdown>
			</Layout>
		);
	}

	//#region columns
	versionsColumns: ColumnProps<IAdminAppOverview>[] = [
		{
			title: 'Name', dataIndex: 'latest.name',
			sorter: (a, b) => a.latest.name.localeCompare(b.latest.name),
			render: this.renderName,
		},
		{
			title: 'Author', dataIndex: 'latest.author.name',
			sorter: (a, b) => a.latest.author.name.localeCompare(b.latest.author.name),
		},
		{
			title: 'Status', dataIndex: 'latest.status',
			sorter: (a, b) => a.latest.status.localeCompare(b.latest.status),
			render: this.renderStatus,
		},
		{
			title: 'Version', dataIndex: 'latest.version',
			sorter: (a, b) => a.latest.version.localeCompare(b.latest.version),
			render: this.renderVersion,
		},
		{
			title: 'Actions', key: 'actions',
			render: this.renderActions,
		},
	];

	columns: ColumnProps<IAdminAppOverview>[] = [
		{
			title: 'Name', dataIndex: 'latest.name',
			sorter: (a, b) => a.latest.name.localeCompare(b.latest.name),
			render: this.renderName,
		},
		{
			title: 'Author', dataIndex: 'latest.author.name',
			sorter: (a, b) => a.latest.author.name.localeCompare(b.latest.author.name),
			render: (name, app) => {
				if (name || !app.publisher) {
					return name;
				}

				return app.publisher.label;
			}
		},
		{
			title: 'Status', dataIndex: 'latest.status', defaultSortOrder: 'ascend',
			sorter: (a, b) => statusSorter(a.latest.status, b.latest.status),
			render: this.renderStatus,
		},
		{
			title: 'Version', dataIndex: 'latest.version', width: '8%',
			sorter: (a, b) => a.latest.version.localeCompare(b.latest.version),
			render: this.renderVersion,
		},
		{
			title: 'Date', dataIndex: 'latest.createdDate',
			sorter: (a, b) => new Date(a.latest.createdDate).getTime() - new Date(b.latest.createdDate).getTime(),
			render: (text) => new Date(text).toLocaleDateString(),
		},
        {
            title: 'EE Only?', dataIndex: 'isEnterpriseOnly', width: '5%',
            render: (isEnterpriseOnly: boolean) => isEnterpriseOnly ? 'Yes' : '-',
        },
        {
            title: 'Usage Based?', dataIndex: 'isUsageBased', width: '5%',
            render: (isUsageBased: boolean) => isUsageBased ? 'Yes' : '-',
        },
		{
			title: 'Actions', key: 'actions', render: this.renderActions,
		}
	]
	//#endregion columns

	render() {
		return (
			<React.Fragment>
				<Table<IAdminAppOverview>
					{...this.props.tableProps}
					columns={this.props.type === 'adminVersions' || this.props.type === 'adminBundleVersions' ? this.versionsColumns : this.columns}
					scroll={{ x: 100 }}
					className="apps-table"
                    loading={this.state.isLoading}
				/>

				<DiagnosticsViewerModal
					visible={this.state.diagnosticsViewerVisible}
					appInfo={this.state.selectedApp}
					close={this.onDiagnosticsViewerClose}
					isAdmin
				/>

                <MakeEnterpriseOnlyModal
                    visible={this.state.isEEOnlyModalVisible}
                    app={this.state.selectedOverview}
                    onClose={this.onEEModalClose}
                />

                <MakeAppUsageBasedModal
                    visible={this.state.isUsageBasedModalVisible}
                    app={this.state.selectedOverview}
                    onClose={this.onUsageBasedModalClose}
                />
			</React.Fragment>
		);
	}
}

export const AdminAppsTable = connectPermissions({ feature: 'system:apps', system: true })(withRouter(AppsTable));
