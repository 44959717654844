import * as api from 'api';

import { IWebhookEndpoint } from 'models/webhookEndpoint';
import { IWebhookActivityLog } from 'models/webhookActivityLog';
import { IRestResponse } from 'models/results/rest';

export const getWebhooks = () => api.get<IWebhookEndpoint[]>(`/publisher/webhooks`, true);
export const createWebhook = (payload: Partial<IWebhookEndpoint>) => api.post<Partial<IWebhookEndpoint>, IWebhookEndpoint>(`/publisher/webhooks`, payload, true);
export const getWebhookById = (id: string) => api.get<IWebhookEndpoint>(`/publisher/webhooks/${ id }`, true);
export const getWebhookActivties = (id: string, limit = 25, offset = 0) => api.get<IRestResponse<IWebhookActivityLog[]>>(`/publisher/webhooks/${ id }/activities?limit=${ limit }&offset=${ offset }`, true);
export const resendWebhookActivity = (webhookId: string, activityId: string) => api.post<unknown, IWebhookActivityLog>(`/publisher/webhooks/${ webhookId }/activities/${ activityId }`, {}, true);
