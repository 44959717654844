import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Layout, Row, Col, Badge, Avatar, Descriptions, Typography, Button, Modal, List, Tabs, notification } from 'antd';

import { IAppOverview } from 'models/appOverview';

import AppsTable from '../AppsList/AppsTable';
import Loading from 'containers/Loading';

import { statusToColor, capitalizeFirstLetter } from 'utils/appStatus';
import { getApp, getBundledApps } from 'api/apps';
import { getPublisher } from 'api/publisher';

interface IBundleViewRouteParams {
    id: string;
}

interface IBundleViewProps extends RouteComponentProps<IBundleViewRouteParams> {}

interface IBundleViewState {
    loading: boolean;
    apps: IAppOverview[];
    bundle?: IAppOverview;
}

class BundleViewBase extends React.PureComponent<IBundleViewProps, IBundleViewState> {
    state: Readonly<IBundleViewState> = {
        loading: true,
        apps: [],
    };

    async componentDidMount() {
        const publisher = await getPublisher();
        if (!publisher || !publisher.canCreateBundles) {
            notification.error({
                message: 'Bundle Creation not Available',
                description: 'Bundle creation has not yet been enabled for you. Please contact Rocket.Chat Support if you are interested in creating bundles.',
            });

            this.props.history.push({ pathname: '/publisher/apps' });
            return;
        }

        const bundle = await getApp(this.props.match.params.id);
        if (!bundle || !bundle.isBundle) {
            notification.error({
                message: 'Invalid Bundle',
                description: 'We failed to present a bundle to you because it was not actually a bundle.',
            });

            this.props.history.push({ pathname: '/publisher/bundles' });
            return;
        }

        const apps = await getBundledApps(this.props.match.params.id);

        this.setState({ loading: false, bundle, apps });
    }

    onViewPricingClick = () => {
        if (!this.state.bundle || !this.state.bundle.pricingPlans) {
            return;
        }

        const { latest, pricingPlans } = this.state.bundle;

        Modal.info({
            title: latest.name + '\'s Pricing Plans',
            content: (
                <Tabs size="small">
                    {pricingPlans.map((p, mapIndex) => {
                        const title = capitalizeFirstLetter(p.strategy) + (p.isPerSeat ? ' per User' : '');

                        return (
                            <Tabs.TabPane tab={title} key={p.id + 'tab' + mapIndex}>
                                <List
                                    dataSource={p.tiers}
                                    renderItem={(i, index) => <List.Item key={'tier-' + mapIndex + '-' + index}>{`$${i.price}${i.perUnit ? ' per user' : ''} ${i.maximum > i.minimum ? 'between ' + i.minimum + ' - ' + i.maximum : 'more than ' + i.minimum}`}</List.Item>}
                                />
                            </Tabs.TabPane>
                        );
                    })}
                </Tabs>
            ),
        });
    }

    get bundleHeader() {
        const { bundle } = this.state;
        if (!bundle) {
            return null;
        }

        const descriptions = [
            <Descriptions.Item key="description" label="Description" span={3}>{bundle.latest.description}</Descriptions.Item>,
            <Descriptions.Item key="categories" label="Categories" span={3}>{bundle.latest.categories.join(', ')}</Descriptions.Item>,
            <Descriptions.Item key="status" label="Status">
                <Badge color={statusToColor[bundle.latest.status]} text={capitalizeFirstLetter(bundle.latest.status)} />
            </Descriptions.Item>,
        ];

        descriptions.push(<Descriptions.Item key="purchaseType" label="Purchase Type">{capitalizeFirstLetter(bundle.purchaseType)}</Descriptions.Item>);
        if (bundle.purchaseType === 'subscription') {
            descriptions.push(
                <Descriptions.Item key="pricingPlans" label="Pricing Plans">
                    <Button type="dashed" onClick={this.onViewPricingClick} disabled={!this.state.bundle || !this.state.bundle.pricingPlans}>View Plans</Button>
                </Descriptions.Item>
            );
        } else {
            descriptions.push(<Descriptions.Item key="price" label="Price">{bundle.price === 0 ? 'FREE' : `$${bundle.price.toFixed(2)}`}</Descriptions.Item>);
        }

        return (
            <Layout.Content>
                <Row type="flex" justify="space-between">
                    <Col span={8}>
                        <Typography.Title level={2}>Bundle: {bundle.latest.name}</Typography.Title>
                    </Col>
                    <Col span={4}>
                        <Button type="primary" disabled icon="edit">Edit</Button>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col span={2}>
                        <Avatar className='avatar' size='large' shape='square' src={`data:image/jpg;base64,${bundle.latest.iconFileData}`} />
                    </Col>
                    <Col span={22}>
                        <Descriptions bordered>
                            {descriptions}
                        </Descriptions>
                    </Col>
                </Row>
            </Layout.Content>
        )
    }

    get bundleView() {
        return (
            <Layout>
                {this.bundleHeader}
                <Layout.Content>
                    <Row>
                        <Col span={4}>
                            <Typography.Title level={3}>Included Apps</Typography.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <AppsTable
                                type="bundle"
                                tableProps={{
                                    loading: this.state.loading,
                                    dataSource: this.state.apps,
                                    rowKey: (record) => record.latest.id,
                                }}
                                canUpdate={false}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )
    }

    render() {
        return this.state.loading ? <Loading /> : this.bundleView;
    }
}

export const BundleView = withRouter(BundleViewBase);
