import { IAppLicense } from "./appLicense";

export interface IPurchaseSubscription {
    typeOf: PurchaseSubscriptionType;
    status: PurchaseSubscriptionStatus;
    statusFromBilling: boolean;
    isSeatBased: boolean;
    seats: number;
    maxSeats: number;
    license: IAppLicense;
    startDate: string;
    periodEnd: string;
    endDate?: string;
    externallyManaged: boolean;
    isSubscribedViaBundle: boolean;
}

export enum PurchaseSubscriptionType {
    App = 'app',
    Service = 'service',
}

export enum PurchaseSubscriptionStatus {
    Trialing = 'trialing',
    Active = 'active',
    Cancelled = 'cancelled',
    Cancelling = 'cancelling',
    PastDue = 'pastDue',
}
