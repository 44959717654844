import React from 'react';
import { Modal, Form, Select, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { DeveloperSystemRole, IDeveloper } from 'models/developer';

import { postAdminUpdateDeveloperSystemRole } from 'api/admin';

interface IAdminChangeDeveloperSystemRoleModalProps extends FormComponentProps {
    visible: boolean;
    developer?: IDeveloper;
    onClose: (saved: boolean) => void;
}

interface IAdminChangeDeveloperSystemRoleModalState {
    saving: boolean;
}

class AdminChangeDeveloperSystemRoleModalBase extends React.PureComponent<IAdminChangeDeveloperSystemRoleModalProps, IAdminChangeDeveloperSystemRoleModalState> {
    state: Readonly<IAdminChangeDeveloperSystemRoleModalState> = {
        saving: false,
    };

    onCloseClick = () => {
        this.props.onClose(false);
    }

    get systemRoleSelector() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="System Role">
                {getFieldDecorator('systemRole', {
                    initialValue: this.props.developer ? this.props.developer.systemRole : '',
                    rules: [{ required: true, message: 'Please select what system role for the user should be.' }],
                })(
                    <Select disabled={this.state.saving}>
                        <Select.Option value={DeveloperSystemRole.User}>User</Select.Option>
                        <Select.Option value={DeveloperSystemRole.Viewer}>Viewer</Select.Option>
                        <Select.Option value={DeveloperSystemRole.Support}>Support</Select.Option>
                        <Select.Option value={DeveloperSystemRole.Reviewer}>Reviewer</Select.Option>
                        <Select.Option value={DeveloperSystemRole.Admin}>Admin</Select.Option>
                    </Select>
                )}
            </Form.Item>
        );
    }

    saveRoleChange = () => {
        this.setState({ saving: true }, async () => {
            const { developer } = this.props;
            if (!developer) {
                return;
            }

            try {
                await postAdminUpdateDeveloperSystemRole(developer.publisherId, developer._id, this.props.form.getFieldValue('systemRole'));

                this.props.onClose(true);
                this.props.form.resetFields();
            } catch (e) {
                console.error('error while changing a user\'s system role:', e);
                notification.error({
                    message: 'An Error Occured',
                    description: (<span>
                        An error occured processing your request.<br />Code: { e.code}<br />Error: { e.error}
                    </span>),
                });
            } finally {
                this.setState({ saving: false });
            }
        });
    }

    render() {
        const { developer } = this.props;

        return (
            <Modal
                title={`Change ${developer && developer.firstName ? developer.firstName + ' ' + developer.lastName : '???'}'s System Role`}
                visible={this.props.visible}
                onCancel={this.onCloseClick}
                onOk={this.saveRoleChange}
                closable={!this.state.saving}
                maskClosable={!this.state.saving}
                keyboard={!this.state.saving}
                okText="Save"
                okButtonProps={{ disabled: this.state.saving, loading: this.state.saving }}
                cancelButtonProps={{ disabled: this.state.saving }}
            >
                <Form layout="vertical">
                    {this.systemRoleSelector}
                </Form>
            </Modal>
        );
    }
}

export const AdminChangeDeveloperSystemRoleModal = Form.create<IAdminChangeDeveloperSystemRoleModalProps>()(AdminChangeDeveloperSystemRoleModalBase);
