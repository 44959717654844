import React from 'react';
import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';
import { RouteComponentProps, withRouter } from 'react-router';
import { Avatar, Button, Col, Empty, Layout, List, notification, Row, Typography } from 'antd';

import { IFeaturedSections } from 'models/featured';
import { getFeaturedSections, removeFeaturedApp } from 'api/admin';

import { MarkAsFeaturedDrawer } from './markAsFeatured';

interface IFeaturedListProps extends RouteComponentProps, IWithPermissionProps {}

interface IFeaturedListState {
    loading: boolean;
    featured?: IFeaturedSections;
    drawerVisible: boolean;
}

class AdminFeaturedListBase extends React.PureComponent<IFeaturedListProps, IFeaturedListState> {
    state: Readonly<IFeaturedListState> = {
        loading: true,
        drawerVisible: false,
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        try {
            const sections = await getFeaturedSections();

            this.setState({ loading: false, featured: sections });
        } catch (e) {
            console.warn('failed to load the featured sections:', e);
            notification.error({ message: 'Failed to load the featured sections' });
        }
    }

    makeFeaturedClick = () => {
        this.setState({ drawerVisible: true });
    }

    onMakeFeaturedClosed = () => {
        this.setState({ loading: true }, async () => {
            await this.loadData();

            this.setState({ drawerVisible: false })
        });
    }

    removeAsFeatured = (sectionSlug: string, appId: string) => {
        this.setState({ loading: true }, async () => {
            try {
                await removeFeaturedApp(appId, sectionSlug);
                await this.loadData();
            } catch (e) {
                notification.warn({ message: 'Failed to remove the app from being featured' });
                console.warn('failed to remove it from being featured:', e);
            }
        });
    }

    get emptyContent() {
        if (this.state.loading) {
            return null;
        }

        if (this.state.featured && this.state.featured.sections.length !== 0) {
            return null;
        }

        return (
            <Empty description="No featured sections have been created">
                <Button type="primary" onClick={this.makeFeaturedClick}>Create One Now</Button>
            </Empty>
        );
    }

    get featuredSections() {
        if (!this.state.featured || this.state.featured.sections.length === 0) {
            return null;
        }

        return this.state.featured.sections.map((s) => (
            <React.Fragment>
                <Row>
                    <Col span={4}>
                        <Typography.Title level={3}>Slug: { s.slug }</Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <List
                            itemLayout="horizontal"
                            loading={this.state.loading}
                            dataSource={s.apps}
                            renderItem={(app) => (
                                <List.Item
                                    key={app.appId}
                                    actions={[
                                        <Button type="link" onClick={() => this.removeAsFeatured(s.slug, app.appId)}>Remove</Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={<Avatar className="app-avatar" size="small" shape="square" src={`data:image/jpg;base64,${app.latest.iconFileData}`} />}
                                        title={app.latest.name}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        ));
    }

    render() {
        return (
            <Layout>
                <Layout.Header className="applist-header">
                    <h2>Featured App Sections</h2>

                    <Button.Group>
                        <Button type="dashed" icon="plus" onClick={this.makeFeaturedClick} disabled={this.state.loading}>Make Featured</Button>
                        <Button type="primary" icon="cloud-sync" onClick={this.loadData} style={{marginRight: '15px'}} loading={this.state.loading} disabled={this.state.loading}>Refresh</Button>
                    </Button.Group>
                </Layout.Header>
                <Layout.Content>
                    { this.emptyContent }
                    { this.featuredSections }
                </Layout.Content>

                <MarkAsFeaturedDrawer visible={this.state.drawerVisible} close={this.onMakeFeaturedClosed} />
            </Layout>
        );
    }
}

export const AdminFeaturedList = connectPermissions({ feature: 'system:featured', system: true })(withRouter(AdminFeaturedListBase));
