import React from 'react';
import { Button, Row, Col, Drawer, Form, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IEmailTemplate } from 'models/emailTemplate';
import { ISuccessResult } from 'models/results/rest';

import { createEmailTemplate, updateEmailTemplate } from 'api/admin';

interface IFormValues {
    name: string;
    layout: string;
    subject: string;
    body: string;
}

interface IAdminEditEmailTemplateDrawerProps extends FormComponentProps<IFormValues> {
    visible: boolean;
    tmpl?: IEmailTemplate;
    onClose: () => void;
    onSuccess: () => Promise<void>;
}

interface IAdminEditEmailTemplateDrawerState { }

class AdminEditEmailTemplateDrawerBase extends React.PureComponent<IAdminEditEmailTemplateDrawerProps, IAdminEditEmailTemplateDrawerState> {
    state: Readonly<IAdminEditEmailTemplateDrawerState> = {};

    onSaveClick = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            const isCreation = typeof this.props.tmpl === 'undefined';
            let result: ISuccessResult;
            if (isCreation) {
                result = await updateEmailTemplate({ ...values, id: this.props.tmpl!.id });
            } else {
                result = await createEmailTemplate(values);
            }

            if (result.success) {
                notification.info({
                    message: `Email Template ${isCreation ? "Created" : "Updated"}`,
                    description: `The email template has been successfully ${isCreation ? "created!" : "updated."}`,
                });

                await this.props.onSuccess();
                this.props.form.resetFields();

                return;
            }

            notification.error({
                message: 'Email Template Update Failure',
                description: 'The email template failed to update. Check the logs.',
            });
        });
    }

    get nameInput() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Template Name">
                {getFieldDecorator('name', {
                    initialValue: this.props.tmpl ? this.props.tmpl.name : '',
                    rules: [{ required: true, message: 'Please enter the name of the template' }],
                })(
                    <Input placeholder="email template name" />
                )}
            </Form.Item>
        );
    }

    get parentLayout() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Parent Layout">
                {getFieldDecorator('layout', {
                    initialValue: this.props.tmpl ? this.props.tmpl.layout : '',
                    rules: [{ required: false }],
                })(
                    <Input
                        style={{ width: '100%' }}
                        placeholder="parent layout"
                    />
                )}
            </Form.Item>
        );
    }

    get subject() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Subject">
                {getFieldDecorator('subject', {
                    initialValue: this.props.tmpl ? this.props.tmpl.subject : '',
                    rules: [{ required: false }],
                })(
                    <Input
                        style={{ width: '100%' }}
                        placeholder="email subject"
                    />
                )}
            </Form.Item>
        );
    }

    get body() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Template Content">
                {getFieldDecorator('body', {
                    initialValue: this.props.tmpl ? this.props.tmpl.body : '',
                    rules: [{ required: true, message: 'please enter the email template body' }],
                })(
                    <Input.TextArea rows={8} placeholder="email template body" />
                )}
            </Form.Item>
        );
    }

    render() {
        let title = `Edit the template: ${this.props.tmpl ? this.props.tmpl.name : '-'}`;
        if (typeof this.props.tmpl === 'undefined') {
            title = 'New Email Template Details';
        }

        return (
            <Drawer
                title={title}
                visible={this.props.visible}
                width={720}
                onClose={this.props.onClose}
            >
                <Form layout="vertical" hideRequiredMark>
                    <Row gutter={16}>
                        <Col span={12}>
                            {this.nameInput}
                        </Col>
                        <Col span={12}>
                            {this.parentLayout}
                        </Col>
                        <Col span={24}>
                            {this.subject}
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            {this.body}
                        </Col>
                    </Row>
                </Form>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.props.onClose} style={{ marginRight: 8 }}>Cancel</Button>
                    <Button onClick={this.onSaveClick} type="primary">Save</Button>
                </div>
            </Drawer>
        );
    }
}

export const AdminEditEmailTemplateDrawer = Form.create<IAdminEditEmailTemplateDrawerProps>()(AdminEditEmailTemplateDrawerBase);
