import { IPurchaseSubscription } from "./purchaseSubscription";

export enum AppPurchaseType {
    Buy = 'buy',
    Subscription = 'subscription',
}

export interface IPurchaseRecord {
    _id: string;
    appId: string;
    workspaceId: string;
    accountId: string;
    purchaseType: AppPurchaseType;
    isBundle: boolean;
    bundledAppIds?: string[];
    price?: number;
    pricePlanId?: string;
    addonTierId?: string;
    subscriptionInfo?: IPurchaseSubscription;
    chargeId?: string;
    date: string;
    modifiedDate: string;
}
