import { TransferItem } from "antd/lib/transfer";
import { WebhookEventType } from "models/webhooks/eventType";

export const getWebhookEventsAsTransferItems = (): TransferItem[] => {
    return Object.values(WebhookEventType).map((e) => {
        return {
            key: e,
            title: `${ e }`,
        };
    });
}
