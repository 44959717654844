import React from 'react';
import { Drawer, Row, Col, Form, Button, Input, Switch } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { IDeveloper } from 'models/developer';

import { postAdminUpdateDeveloper } from 'api/admin';

interface IFormValues {
    firstName: string;
    lastName: string;
    rocketChatUsername: string;
    email: string;
    emailVerified: boolean;
    cloudUserId: string;
}

interface IEditDeveloperDrawerProps extends FormComponentProps<IFormValues> {
    isVisible: boolean;
    publisherId: string;
    developer?: IDeveloper;
    close: () => void;
    finished: () => void;
}

interface IEditDeveloperDrawerState {
    isSaving: boolean;
}

class EditDeveloperDrawerBase extends React.PureComponent<IEditDeveloperDrawerProps, IEditDeveloperDrawerState> {
    state: Readonly<IEditDeveloperDrawerState> = {
        isSaving: false,
    };

    onClose = () => {
        this.props.close();
        this.props.form.resetFields();

        this.setState({ isSaving: false });
    };

    handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const { form } = this.props;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            if (!this.props.developer) {
                return;
            }

            this.setState({ isSaving: true }, async () => {
                //TODO: reivew as it doesn't save
                await postAdminUpdateDeveloper({ ...this.props.developer!, ...values }, this.props.publisherId, this.props.developer!._id);

                this.props.finished();

                setTimeout(() => {
                    if (!this.props.isVisible) {
                        //reset the form and state
                        form.resetFields();
                        this.setState({ isSaving: false });
                    }
                }, 500);
            });
        });
    }

    //#region developer full name
    get developerFirstName() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="First Name" colon={false}>
                {getFieldDecorator('firstName', {
                    initialValue: this.props.developer ? this.props.developer.firstName : '',
                    rules: [{ required: true, message: 'Please input the developer\'s first name.' }]
                })(
                    <Input placeholder="First name" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }

    get developerLastName() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Last Name" colon={false}>
                {getFieldDecorator('lastName', {
                    initialValue: this.props.developer ? this.props.developer.lastName : '',
                    rules: [{ required: true, message: 'Please input the developer\'s last name.' }]
                })(
                    <Input placeholder="Last name" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region developer rocket.chat username
    get rocketChatUsername() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Rocket.Chat Username" colon={false}>
                {getFieldDecorator('rocketChatUsername', {
                    initialValue: this.props.developer ? this.props.developer.rocketChatUsername : '',
                    rules: [{ required: true, message: 'Please input the developer\'s Rocket.Chat Username.' }]
                })(
                    <Input placeholder="example.user" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region developer email
    get developerEmail() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Email" colon={false}>
                {getFieldDecorator('email', {
                    initialValue: this.props.developer ? this.props.developer.email : '',
                    rules: [{ required: true, message: 'Please input the developer\'s email.' }]
                })(
                    <Input type="email" placeholder="example@example.com" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region email verified
    get developerEmailVerified() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label={'Email Verified'} colon={false}>
                {getFieldDecorator('emailVerified', {
                    valuePropName: 'checked',
                    initialValue: this.props.developer ? this.props.developer.emailVerified : false,
                    rules: [{ required: true, message: 'Please set whether the developer\'s email is verified or not.' }]
                })(
                    <Switch checkedChildren={"Verified"} unCheckedChildren={"Not verified"} disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    //#region developer cloud user id
    get developerUserId() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Cloud User ID" colon={false}>
                {getFieldDecorator('cloudUserId', {
                    initialValue: this.props.developer ? this.props.developer.cloudUserId : '',
                })(
                    <Input placeholder="cloud user id" disabled={this.state.isSaving} />
                )}
            </Form.Item>
        );
    }
    //#endregion

    render() {
        const { developer } = this.props;

        return (
            <Drawer
                title={"Edit Developer: " + (developer ? `${ developer.firstName } ${ developer.lastName }` : '-')}
                width={500}
                onClose={this.onClose}
                visible={this.props.isVisible}
                closable={this.state.isSaving}
            >
                <Form layout="vertical" id="edit-developer">
                    <Row gutter={16}>
                        <Col span={12}>{this.developerFirstName}</Col>
                        <Col span={12}>{this.developerLastName}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{this.developerEmail}</Col>
                        <Col span={12}>{this.developerEmailVerified}</Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>{this.rocketChatUsername}</Col>
                        <Col span={12}>{this.developerUserId}</Col>
                    </Row>
                </Form>
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={this.state.isSaving}>Cancel</Button>
                    <Button onClick={this.handleSubmit} type="primary" disabled={this.state.isSaving}>Save</Button>
                </div>
            </Drawer>
        );
    }
}

export const EditDeveloperDrawer = Form.create<IEditDeveloperDrawerProps>()(EditDeveloperDrawerBase);
