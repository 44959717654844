import * as api from 'api';

import { IAppActivityLog } from 'models/appActivityLog';
import { IAppInfo } from 'models/appInfo';
import { IAdminAppOverview, IAppOverview } from 'models/appOverview';
import { IPrice } from 'models/appPrice';
import { IAppStatistics } from 'models/appStatus';
import { IAppUsage } from 'models/appUsage';
import { IAppCategory } from 'models/category';
import { IJob } from 'models/compiler/job';
import { DeveloperRole, DeveloperSystemRole, IDeveloper } from 'models/developer';
import { IEmailTemplate } from 'models/emailTemplate';
import { IFeaturedSections } from 'models/featured';
import { IWorkspace } from 'models/fleetcommand/workspace';
import { AdminAppAction, IAdminAppVersionActionPayload, IAdminCreatePurchaseRecordPayload, IAdminEmailPublisherPayload, IAdminMakeAppFeaturedPayload, IAdminPayoutPublisherPayload, IAdminUpdateDeveloperRolePayload, IAdminUpdateDeveloperSystemRolePayload, IAdminUpdatePurchaseRecordPayload } from 'models/payloads/admin';
import { IPublisher } from 'models/publisher';
import { IPurchaseRecord } from 'models/purchaseRecord';
import { IAdminPayoutPublisherResult, IAdminPurchaseRecordInfo } from 'models/results/admin';
import { IRestResponse, ISuccessResult } from 'models/results/rest';

export const getAdminApps = () => api.get<IAdminAppOverview[]>('/admin/apps', true);
export const getAdminApp = (id: string) => api.get<IAdminAppOverview>(`/admin/app/${id}`, true);
export const getAdminAppVersions = (id: string) => api.get<IAppInfo[]>(`/admin/app/${id}/all`, true);
export const getAdminAppStats = (id: string) => api.get<IAppStatistics>(`/admin/app/${id}/stats`, true);
export const getAdminAppJobs = (id: string) => api.get<IJob[]>(`/admin/app/${id}/jobs`, true);
export const getAdminAppJobByID = (appId: string, jobId: string) => api.get<IJob>(`/admin/app/${appId}/jobs/${jobId}`, true);
export const getAdminAppUsages = (appId: string, limit = 25, offset = 0) => api.get<IRestResponse<IAppUsage[]>>(`/admin/app/${ appId }/usage?limit=${limit}&offset=${offset}`, true);
export const getAdminAppPrices = (appId: string) => api.get<IPrice[]>(`/admin/app/${appId}/prices`, true);
export const getAdminAppAcitivityLogs = (appId: string, limit = 25, offset = 0) => api.get<IRestResponse<IAppActivityLog[]>>(`/admin/app/${appId}/activityLogs?limit=${limit}&offset=${offset}`, true);

export const getAdminDownloadUrl = async (appInfoId: string, compiled: boolean) => {
    const secret = await api.get<{ secret: string }>('/admin/secret', true);

    let downloadUrl = api.BASE_URL + '/admin/apps/download/' + appInfoId + '?secret=' + secret.secret;
    if (compiled) {
        downloadUrl = `${downloadUrl}&compiled=true`;
    }

    return downloadUrl;
};

export const postAdminAppEnterpriseOnly = (appId: string, addonTierId?: string) => api.post<{ enterpriseOnly: boolean, addonTierId?: string }, IAppOverview>(`/admin/app/${appId}/enterpriseOnly`, { enterpriseOnly: true, addonTierId }, true);
export const postAdminAppMakeUsageBased = (appId: string, allowOverridingPrice: boolean) => api.post<{ priceOverride: boolean }, IAppOverview>(`/admin/app/${appId}/usageBased`, { priceOverride: allowOverridingPrice }, true);
export const postAdminAction = (appId: string, appVersionId: string, action: AdminAppAction, data: Omit<IAdminAppVersionActionPayload, 'action'>) => {
    return api.post<IAdminAppVersionActionPayload, void>(`/admin/app/${appId}/version/${appVersionId}/${action}`, { ...data, action }, true)
};

export const getAdminEmailTemplates = () => api.get<IEmailTemplate[]>('/admin/emailTemplates', true);
export const updateEmailTemplate = (emailTemplate: Partial<IEmailTemplate>) => api.put<Partial<IEmailTemplate>, ISuccessResult>('/admin/emailTemplates', emailTemplate, true);
export const createEmailTemplate = (emailTemplate: Partial<IEmailTemplate>) => api.post<Partial<IEmailTemplate>, ISuccessResult>('/admin/emailTemplates', emailTemplate, true);

export const getAdminPurchases = (limit = 25, offset = 0) => api.get<IRestResponse<IPurchaseRecord[]>>(`/admin/purchases?limit=${limit}&offset=${offset}`, true);
export const getAdminPurchaseByID = (id: string) => api.get<IAdminPurchaseRecordInfo>(`/admin/purchases/${id}`, true);
export const postAdminPurchaseByID = (id: string, payload: IAdminUpdatePurchaseRecordPayload) => api.post<IAdminUpdatePurchaseRecordPayload, void>(`/admin/purchases/${id}`, payload, true);
export const postAdminPurchase = (payload: IAdminCreatePurchaseRecordPayload) => api.post<IAdminCreatePurchaseRecordPayload, void>(`/admin/purchases`, payload, true, { showErrorDialog: true });
export const deleteAdminPurchase = (id: string) => api.del<void>(`/admin/purchases/${ id }`, true);

export const getAdminPublishers = () => api.get<IPublisher[]>('/admin/publishers', true);
export const getAdminPublisher = (publisherId: string) => api.get<IPublisher>(`/admin/publishers/${publisherId}`, true);
export const postAdminUpdatePublisher = (publisher: Partial<IPublisher>, publisherId: string) => api.post<Partial<IPublisher>, void>(`/admin/publishers/${publisherId}`, publisher, true);
export const getAdminPublisherApps = (publisherId: string) => api.get<IAdminAppOverview[]>(`/admin/publishers/${publisherId}/apps`, true);
export const getAdminPublisherBundles = (publisherId: string) => api.get<IAdminAppOverview[]>(`/admin/publishers/${publisherId}/bundles`, true);
export const getAdminPublisherDevelopers = (publisherId: string) => api.get<IDeveloper[]>(`/admin/publishers/${publisherId}/developers`, true);
export const postAdminUpdateDeveloper = (developer: IDeveloper, publisherId: string, developerId: string) => api.post<IDeveloper, void>(`/admin/publishers/${publisherId}/developers/${developerId}`, developer, true);
export const postAdminUpdateDeveloperRole = (publisherId: string, developerId: string, role: DeveloperRole) => api.post<IAdminUpdateDeveloperRolePayload, void>(`/admin/publishers/${publisherId}/developers/${developerId}/role`, { role }, true);
export const postAdminUpdateDeveloperSystemRole = (publisherId: string, developerId: string, role: DeveloperSystemRole) => api.post<IAdminUpdateDeveloperSystemRolePayload, void>(`/admin/publishers/${publisherId}/developers/${developerId}/system-role`, { role }, true);
export const postAdminEmailPublisher = (publisherId: string, emailData: IAdminEmailPublisherPayload) => api.post<IAdminEmailPublisherPayload, void>(`/admin/publishers/${publisherId}/email`, emailData, true);
export const postAdminPayoutPublisher = (publisherId: string, amount: number) => api.post<IAdminPayoutPublisherPayload, IAdminPayoutPublisherResult>(`/admin/publishers/${publisherId}/payout`, { amount }, true);

export const getAdminWorkspaceByID = (id: string) => api.get<IWorkspace>(`/admin/workspaces/${id}`, true);
export const getAdminWorkspacePurchasesByID = (id: string) => api.get<IPurchaseRecord[]>(`/admin/workspaces/${id}/purchases`, true);

export const getAdminBundles = () => api.get<IAdminAppOverview[]>('/admin/bundles', true);
export const getAdminBundleByID = (bundleId: string) => api.get<IAdminAppOverview>(`/admin/bundles/${bundleId}`, true);
export const getAdminBundleAppsByID = (bundleId: string) => api.get<IAdminAppOverview[]>(`/admin/bundles/${bundleId}/apps`, true);

export const getAdminCompileJobs = (limit = 25, offset = 0) => api.get<IRestResponse<IJob[]>>(`/admin/jobs?limit=${limit}&offset=${offset}`, true);

export const getAdminCategories = () => api.get<IAppCategory[]>('/admin/categories', true);
export const createAdminCategory = (category: Partial<IAppCategory>) => api.post<Partial<IAppCategory>, IAppCategory>('/admin/categories', category, true);

export const getFeaturedSections = () => api.get<IFeaturedSections>('/admin/featured', true);
export const makeAppFeatured = (appId: string, sectionSlug: string) => api.post<IAdminMakeAppFeaturedPayload, void>('/admin/featured', { appId, sectionSlug }, true);
export const removeFeaturedApp = (appId: string, sectionSlug: string) => api.del<void>(`/admin/featured/${ sectionSlug }/${ appId }`, true);
