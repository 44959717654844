import React from 'react';
import { Unsubscribe } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import { ClickParam } from 'antd/lib/menu';

import RocketChatLogo from '../RocketChatLogo';
import { store } from 'store';

import { IPublisher } from 'models/publisher';
import { IDeveloper } from 'models/developer';

import { connectPermissions, IWithPermissionProps } from 'components/access/hasPermissionHOC';

import './sidebar.less';

interface ISidebarProps extends RouteComponentProps, IWithPermissionProps {
    collapsed: boolean;
}

interface ISidebarState {
    publisher?: IPublisher;
    myInfo?: IDeveloper;
}

class SidebarBase extends React.PureComponent<ISidebarProps, ISidebarState> {
    state: Readonly<ISidebarState> = {};
    unsubscribeFromRedux?: Unsubscribe;

    async componentWillMount() {
        this.unsubscribeFromRedux = store.subscribe(async () => {
            const { accessControl } = store.getState();

            this.setState({
                myInfo: accessControl.developer,
                publisher: accessControl.publisher,
            })
        });
    }

    async componentWillUnmount() {
        if (typeof this.unsubscribeFromRedux === 'function') {
            this.unsubscribeFromRedux();
        }
    }

    handleClick = (param: ClickParam) => {
        if (param.key === '/terms' || param.key === "/privacy") {
            return false;
        }

        this.props.history.push(param.key);
    }

    get publisherMenu() {
        if (!this.state.publisher || !this.state.publisher._id) {
            return null;
        }

        return (
            <Menu.ItemGroup key="g1" className="sidebar-menu-group-top">
                { this.props.permissions.has('publisher', 'read', this.state.publisher._id) ?
                    <Menu.Item key="/publisher/info">
                        <Icon type="profile" /><span>Publisher Info</span>
                    </Menu.Item>
                : null}

                { this.props.permissions.has('developer', 'read', this.state.publisher._id) ?
                    <Menu.Item key="/publisher/profile">
                        <Icon type="user" /><span>Profile</span>
                    </Menu.Item>
                : null}

                { this.props.permissions.has('app', 'read', this.state.publisher._id) ?
                    <Menu.Item key="/publisher/apps">
                        <Icon type="block" /><span>Apps</span>
                    </Menu.Item>
                : null}

                { this.props.permissions.has('bundle', 'read', this.state.publisher._id) ?
                    <Menu.Item key="/publisher/bundles" disabled={!this.state.publisher || !this.state.publisher.canCreateBundles}>
                        <Icon type="container" /><span>Bundles</span>
                    </Menu.Item>
                : null}

                <Menu.Item key="/publisher/webhooks" disabled={!this.state.publisher || !this.state.publisher.canUseWebhooks}>
                    <Icon type="api" /><span>Webhooks</span>
                </Menu.Item>

                <Menu.Item key="/publisher/purchases" disabled={!this.state.publisher}>
                    <Icon type="shopping-cart" /><span>Purchases</span>
                </Menu.Item>
            </Menu.ItemGroup>
        );
    }

    get adminMenu() {
        if (!this.state.myInfo || !this.state.myInfo._id) {
            return null;
        }

        if (!this.props.permissions.has('system:administration', 'read', 'system')) {
            return null;
        }

        return (
            <Menu.ItemGroup key='g2' className='sidebar-menu-group-top'>
                <Menu.SubMenu key="admin" title={<span><Icon type="dashboard" /><span>Administration</span></span>}>

                    <Menu.Item key='/publisher/admin'>
                        <Icon type="dashboard" /><span>Dashboard</span>
                    </Menu.Item>

                    {this.props.permissions.has('system:apps', 'read') ?
                        <Menu.Item key='/publisher/admin/apps'>
                            <Icon type="block" /><span>Apps</span>
                        </Menu.Item>
                    : null}

                    {this.props.permissions.has('system:bundles', 'read') ?
                        <Menu.Item key='/publisher/admin/bundles'>
                            <Icon type="container" /><span>Bundles</span>
                        </Menu.Item>
                    : null}

                    {this.props.permissions.has('system:publishers', 'read') ?
                        <Menu.Item key='/publisher/admin/publishers'>
                            <Icon type="profile" /><span>Publishers</span>
                        </Menu.Item>
                    : null}

                    {this.props.permissions.has('system:purchaseRecords', 'read') ?
                        <Menu.Item key='/publisher/admin/purchases'>
                            <Icon type="shopping-cart" /><span>Purchase Records</span>
                        </Menu.Item>
                    : null}

                    {this.props.permissions.has('system:workspaces', 'read') ?
                        <Menu.Item key='/publisher/admin/workspace'>
                            <Icon type="cloud" /><span>Workspace</span>
                        </Menu.Item>
                    : null}

                    {this.props.permissions.has('system:emailTemplates', 'read') ?
                        <Menu.Item key='/publisher/admin/emails'>
                            <Icon type="mail" /><span>Email Templates</span>
                        </Menu.Item>
                    : null}


                    {this.props.permissions.has('system:compileJobs', 'read') ?
                        <Menu.Item key='/publisher/admin/jobs'>
                            <Icon type="reconciliation" /><span>Compile Jobs</span>
                        </Menu.Item>
                    : null}

                    {this.props.permissions.has('system:categories', 'read') ?
                        <Menu.Item key='/publisher/admin/categories'>
                            <Icon type="copy" /><span>Categories</span>
                        </Menu.Item>
                    : null}

                    {this.props.permissions.has('system:featured', 'read') ?
                        <Menu.Item key='/publisher/admin/featured'>
                            <Icon type="crown" /><span>Featured Sections</span>
                        </Menu.Item>
                    : null}
                </Menu.SubMenu>
            </Menu.ItemGroup>
        );
    }

    render() {
        const { location, collapsed } = this.props;

        return (
            <React.Fragment>
                <RocketChatLogo style={{ margin: '20px 24px 8px' }} />
                <Menu
                    onClick={this.handleClick}
                    defaultSelectedKeys={[location.pathname]}
                    defaultOpenKeys={['admin']}
                    mode="inline"
                    inlineCollapsed={collapsed}
                >
                    {this.publisherMenu}
                    {this.adminMenu}

                    <Menu.ItemGroup key="g3" className="sidebar-menu-group-bottom">
                        <Menu.Item key="/terms">
                            <a href="https://rocket.chat/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                        </Menu.Item>
                        <Menu.Item key="/privacy">
                            <a href="https://rocket.chat/privacy" target="_blank" rel="noopener noreferrer">Privacy policy</a>
                        </Menu.Item>

                        {this.state.myInfo ? <Menu.Item key="/sign-out" className="signout">Sign out</Menu.Item> : null}
                    </Menu.ItemGroup>
                </Menu>
            </React.Fragment>
        )
    }
}

export const SideBar = connectPermissions()(withRouter(SidebarBase));
