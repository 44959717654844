import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal, Row, Col, List, message, Button, notification } from 'antd';

import Loading from 'containers/Loading';
import { EditSubscriptionDrawer } from 'views/Admin/Purchases/editSubscriptionDrawer';

import { deleteAdminPurchase, getAdminPurchaseByID } from 'api/admin';
import { getCloudOAuthInfo } from 'api/misc';

import { connectPermissions, IWithPermissionProps } from './access/hasPermissionHOC';
import { IAdminPurchaseRecordInfo } from 'models/results/admin';
import { PurchaseSubscriptionStatus } from 'models/purchaseSubscription';

interface IPurchaseRecordModalProps extends RouteComponentProps, IWithPermissionProps {
    visible: boolean;
    purchaseRecordId?: string;
    onClose?: () => void;
}

interface IPurchaseRecordModalState {
    isLoading: boolean;
    editDrawerVisible: boolean;
    isRetriggering: boolean;
    isDeleting: boolean;
    data?: Partial<IAdminPurchaseRecordInfo>;
    cloudUrl: string;
}

class PurchaseRecordModalBase extends React.PureComponent<IPurchaseRecordModalProps, IPurchaseRecordModalState> {
    state: Readonly<IPurchaseRecordModalState> = {
        isLoading: true,
        editDrawerVisible: false,
        isRetriggering: false,
        isDeleting: false,
        cloudUrl: 'https://cloud.rocket.chat',
    };

    componentDidMount = () => {
        this.loadData();
    }

    componentDidUpdate = () => {
        this.loadData();
    }

    loadData = () => {
        //TODO: fix this as it is not working correctly
        const purchaseId = this.props.purchaseRecordId || '';

        if (!purchaseId) {
            return;
        }

        if (this.state.data && this.state.data.purchaseRecord && this.state.data.purchaseRecord._id === purchaseId) {
            return;
        }

        this.setState({ isLoading: true }, async () => {
            try {
                const cloudOAuthInfo = await getCloudOAuthInfo();
                const data = await getAdminPurchaseByID(purchaseId);

                this.setState({
                    isLoading: false,
                    data,
                    cloudUrl: cloudOAuthInfo.cloudUrl,
                });
            } catch {
                if (purchaseId) {
                    message.error('Invalid purchase record id provided.');
                    this.onClose();
                }
            }
        });
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose();
        } else {
            this.props.history.push({ pathname: '/publisher/admin/purchases' });
        }

        setTimeout(() => this.setState({ isLoading: false, data: undefined }), 500);
    }

    onEditClick = () => {
        this.setState({ editDrawerVisible: true });
    }

    closeEdit = async (saved: boolean) => {
        if (saved) {
            try {
                const data = await getAdminPurchaseByID(this.props.purchaseRecordId!);
                this.setState({ isLoading: false, data });
            } catch {
                if (this.props.purchaseRecordId) {
                    message.error('Invalid purchase record id provided.');
                    this.onClose();
                }
            }
        }

        this.setState({ editDrawerVisible: false });
    }

    get details() {
        const { data } = this.state;

        if (!data || !data.account || !data.workspace || !data.purchaseRecord || !data.app) {
            return null;
        }

        const accountUrl = `${ this.state.cloudUrl }/admin/accounts/${ data.account.id }`;
        const workspaceUrl = `${ this.state.cloudUrl }/admin/workspaces/${ data.workspace.id }`;

        const { subscriptionInfo } = data.purchaseRecord;

        return (
            <List size="small">
                <List.Item>App: { data.app.latest.name }</List.Item>
                <List.Item>Account: <a href={accountUrl} target="_blank" rel="noopener noreferrer">{ data.account.name }</a></List.Item>
                <List.Item>Workspace: <a href={workspaceUrl} target="_blank" rel="noopener noreferrer">{ data.workspace.nickname } ({ data.workspace.address })</a></List.Item>
                <List.Item>Purchase Date: { new Date(data.purchaseRecord.date).toLocaleString() }</List.Item>
                <List.Item>Purchase Type: { data.app.purchaseType }</List.Item>
                <List.Item>Is a Bundle: { data.app.isBundle ? 'yes' : 'no' }</List.Item>
                {/* { data.app.purchaseType === 'subscription' && subscriptionInfo && subscriptionInfo.subscriptionId ? <List.Item>Subscription ID: { subscriptionInfo.subscriptionId }</List.Item> : null} */}
                { data.app.purchaseType === 'subscription' && subscriptionInfo ? <List.Item>Subscription Status: { subscriptionInfo.status }</List.Item> : null}
                { data.app.purchaseType === 'subscription' && subscriptionInfo ? <List.Item>Period End: { new Date(subscriptionInfo.periodEnd).toLocaleString() }</List.Item> : null}
                { data.app.purchaseType === 'subscription' && subscriptionInfo && subscriptionInfo.isSeatBased ? <List.Item>Seats: { subscriptionInfo.seats }/{ subscriptionInfo.maxSeats }</List.Item> : null}
                { data.app.purchaseType === 'subscription' && subscriptionInfo && !subscriptionInfo.isSeatBased ? <List.Item>Seats: n/a</List.Item> : null}
                { data.app.purchaseType === 'subscription' && subscriptionInfo ? <List.Item>Externally Managed: { subscriptionInfo.externallyManaged ? 'yes' : 'no' }</List.Item> : null}
                { data.app.purchaseType === 'subscription' && subscriptionInfo && data.purchaseRecord.price ? <List.Item>Calculated Price: ${ data.purchaseRecord.price.toFixed(2) }</List.Item> : null}
                { data.app.purchaseType === 'buy' ? <List.Item>Price: ${ data.app.price.toFixed(2) }</List.Item> : null}
            </List>
        );
    }

    get editDisabled () {
        if (!this.props.permissions.loaded || !this.props.permissions.features['system:purchaseRecords'].canUpdate) {
            return true;
        }

        if (!this.state.data || !this.state.data.purchaseRecord || !this.state.data.purchaseRecord.subscriptionInfo) {
            return true;
        }

        if (this.state.isLoading) {
            return true;
        }

        // we only allow editing subscriptions
        if (this.state.data.app && this.state.data.app.purchaseType !== 'subscription') {
            return true;
        }

        // we currently do not allow editing bundles
        if (this.state.data.app && this.state.data.app.isBundle) {
            return true;
        }

        // if no subscription info, don't continue and disable it since it is probably invalid data
        const subInfo = this.state.data.purchaseRecord.subscriptionInfo;
        if (!subInfo) {
            return true;
        }

        if (subInfo.status === PurchaseSubscriptionStatus.PastDue || subInfo.status === PurchaseSubscriptionStatus.Cancelled) {
            return true;
        }

        return false;
    }

    onDeleteClick = () => {
        this.setState({ isDeleting: true, isLoading: true }, async () => {
            try {
                await deleteAdminPurchase(this.state.data!.purchaseRecord!._id);
                notification.success({ message: 'purchase record successfully removed' });

                this.onClose();
            } catch (e) {
                console.warn('error retriggering:', e);
                notification.error({ message: 'failed to delete the externally managed purchase record' });
            } finally {
                this.setState({ isDeleting: false, isLoading: false });
            }
        });
    };

    get footerButtons() {
        const btns = [
            <Button type="default" key="close-btn" disabled={this.state.isLoading} onClick={this.onClose}>Close</Button>,
            <Button type="dashed" key="edit-btn" disabled={this.editDisabled} onClick={this.onEditClick}>Edit</Button>,
        ];

        if (this.state.data && !this.state.data.app?.isBundle && this.state.data.purchaseRecord?.subscriptionInfo?.externallyManaged && this.props.permissions.loaded && this.props.permissions.features['system:purchaseRecords'].canDelete) {
            btns.push(
                <Button
                    type="danger"
                    key="retrigger-btn"
                    onClick={this.onDeleteClick}
                    disabled={this.state.isDeleting || this.state.isLoading}
                    loading={this.state.isDeleting}
                >Delete</Button>
            );
        }

        return btns;
    }

    render() {
        return (
            <Modal
                title={`Purchase Record Information`}
                visible={this.props.visible}
                onCancel={this.onClose}
                footer={this.footerButtons}
                closable={!this.state.isLoading}
                maskClosable={!this.state.isLoading}
            >
                <Row>
                    <Col span={24}>
                        {this.state.isLoading && !this.state.isRetriggering ? <Loading /> : this.details}
                    </Col>
                </Row>

                <EditSubscriptionDrawer
                    purchaseRecord={this.state.data ? this.state.data.purchaseRecord : undefined}
                    app={this.state.data ? this.state.data.app : undefined}
                    visible={this.state.editDrawerVisible}
                    close={this.closeEdit}
                />
            </Modal>
        );
    }
}

export const ViewPurchaseRecordModal = connectPermissions({ feature: 'system:purchaseRecords', system: true })(withRouter(PurchaseRecordModalBase));
