import * as casbin from 'casbin';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { loadAccessControlData } from 'api/ac';
import { getMyInfo } from 'api/profile';

import { serializeEnforceRequest } from 'utils/auth';
import { IDeveloper } from 'models/developer';
import { IDeveloperAccessResult } from 'models/permissions';

import { RootState } from 'store';
import { IPublisher } from 'models/publisher';
import { getPublisher } from 'api/publisher';

export type SeralizedPermissionChecks = { [key: string]: boolean | undefined };

interface IAccessControlState {
    loading: boolean;
    loaded: boolean;
    data: IDeveloperAccessResult;
    developer?: IDeveloper;
    publisher?: IPublisher;
    serializedChecks: SeralizedPermissionChecks;
}

const initialState: IAccessControlState = {
    loading: false,
    loaded: false,
    data: {
        model: '',
        policies: {
            system: [],
            publisher: [],
        },
        roles: {
            system: [],
            publisher: [],
        },
    },
    serializedChecks: {},
};

export let enforcer: casbin.Enforcer;

export const loadAccessControl = createAsyncThunk(
    'accessControl/load',
    async () => {
        console.log('LOADING ACCESS CONTROL');
        const whoami = await getMyInfo();
        const publisher = await getPublisher();
        const data = await loadAccessControlData();

        const model = casbin.newModelFromString(data.model);
        enforcer = await casbin.newEnforcer(model);

        const systemPolicies = data.policies.system.map((p) => enforcer.addNamedPolicy('p', ...p));
        const publisherPolicies = data.policies.publisher.map((p) => enforcer.addNamedPolicy('p', ...p));

        await Promise.all(systemPolicies);
        await Promise.all(publisherPolicies);

        const systemRoles = data.roles.system.map((r) => enforcer.addNamedGroupingPolicy('g', whoami._id, r, 'system'));
        const publisherRoles = data.roles.publisher.map((r) => enforcer.addNamedGroupingPolicy('g', whoami._id, r, whoami.publisherId));

        await Promise.all(systemRoles);
        await Promise.all(publisherRoles);

        console.log('ENFORCER loaded.');

        return {
            data,
            developer: whoami,
            publisher: publisher,
        };
    },
    {
        condition: (arg: void, { getState }) => {
            const { accessControl } = getState() as RootState;
            // if the `loading` property of the state is true
            // a request is already being called so don't load it again
            return !accessControl.loading;
        },
    },
);

export interface ICheckForAccessArg {
    feature: string;
    permission: string;
    group?: string;
}

interface ICheckForMultipleAccessArg {
    feature: string;
    permissions: string[];
    group?: string;
}

export const checkForAccess = createAsyncThunk(
    'accessControl/check',
    async (payload: ICheckForAccessArg, thunkAPI) => {
        const {accessControl} = thunkAPI.getState() as RootState;

        if (!enforcer || !accessControl.developer) {
            return;
        }

        const serialized = serializeEnforceRequest(payload.feature, payload.permission, accessControl.developer._id, payload.group ? payload.group : accessControl.developer.publisherId);
        // Using async casbin.Enforcer.enforce() instead enforceSync because enforceSync ignores
        // the subject obtained by g() when checking permissions, therefore always returning true
        // if feature + action is present without considering the role.
        const result = await enforcer.enforce(accessControl.developer._id, payload.group ? payload.group : 'system', payload.feature, payload.permission);

        if (!serialized) {
            return;
        }

        return {
            serialized,
            result,
        };
    },
    {
        condition: (arg: ICheckForAccessArg, { getState }) => {
            const { accessControl } = getState() as RootState;
            // if the `loading` property of the state is true
            // a request is already being called so don't load it again
            return !accessControl.loading;
        },
    },
);

export const checkForMultipleAccess = createAsyncThunk(
    'accessControl/checkForMultipleAccess',
    async (payload: ICheckForMultipleAccessArg, thunkAPI) => {
        const {accessControl} = thunkAPI.getState() as RootState;

        if (!enforcer || !accessControl.developer) {
            return;
        }

        const results = [];

        for (const p in payload.permissions) {
            const permission = payload.permissions[p];

            const serialized = serializeEnforceRequest(payload.feature, permission, accessControl.developer!._id, payload.group ? payload.group : accessControl.developer!.publisherId);
            // Using async casbin.Enforcer.enforce() instead enforceSync because enforceSync ignores
            // the subject obtained by g() when checking permissions, therefore always returning true
            // if feature + action is present without considering the role.
            const result = await enforcer.enforce(accessControl.developer!._id, payload.group ? payload.group : 'system', payload.feature, permission);

            results.push({serialized, result});
        }

        return results;
    },
    {
        condition: (arg: ICheckForMultipleAccessArg, { getState }) => {
            const { accessControl } = getState() as RootState;
            // if the `loading` property of the state is true
            // a request is already being called so don't load it again
            return !accessControl.loading;
        },
    },
);

export const accessControlSlice = createSlice({
    name: 'accessControl',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(loadAccessControl.pending, (state) => {
            state.loading = true;
        })
        .addCase(loadAccessControl.fulfilled, (state, action) => {
            state.loaded = true;
            state.loading = false;
            state.data = action.payload.data;
            state.developer = action.payload.developer;
            state.publisher = action.payload.publisher;
        })
        .addCase(loadAccessControl.rejected, (state) => {
            state.loading = false;
        })
        .addCase(checkForAccess.pending, (state) => {
            state.loading = true;
        })
        .addCase(checkForAccess.fulfilled, (state, action) => {
            const { payload } = action;

            if (typeof payload === "undefined") {
                return;
            }

            if (typeof payload.serialized === "undefined") {
                return;
            }

            state.serializedChecks[payload.serialized] = payload.result;
            state.loading = false;
        })
        .addCase(checkForAccess.rejected, (state) => {
            state.loading = false;
        })
        .addCase(checkForMultipleAccess.pending, (state) => {
            state.loading = true;
        })
        .addCase(checkForMultipleAccess.fulfilled, (state, action) => {
            const { payload } = action;

            if (typeof payload === "undefined") {
                return;
            }

            state.loading = false;

            payload.forEach((p) => {
                if (typeof p.serialized === "undefined") {
                    return;
                }

                state.serializedChecks[p.serialized] = p.result;
            });
        })
        .addCase(checkForMultipleAccess.rejected, (state) => {
            state.loading = false;
        });
    },
});

export default accessControlSlice.reducer;
