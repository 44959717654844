import React from 'react';
import { Drawer, Form, Row, Col, Button, Input, Icon, Switch, Transfer } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { getWebhookEventsAsTransferItems } from 'utils/webhookEvents';
import { createWebhook } from 'api/webhooks';

interface IFormValues {
    label: string;
    enabled: boolean;
    url: string;
}

interface INewWebhookProps extends FormComponentProps<IFormValues> {
    visible: boolean;
    close: (saved: boolean) => Promise<void>;
}

interface INewWebhookState {
    isLoading: boolean;
    isSaving: boolean;
}

class NewWebhookDrawerBase extends React.PureComponent<INewWebhookProps, INewWebhookState> {
    state: Readonly<INewWebhookState> = {
        isLoading: true,
        isSaving: false,
    };

    componentDidMount() {
        //TODO: load events from server
    }

    onClose = () => {
        this.props.close(false);
        this.props.form.resetFields();
    }

    onSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ isSaving: true }, async () => {
                try {
                    await createWebhook(values);

                    await this.props.close(true);
                    this.props.form.resetFields();
                } catch (e) {
                    //TODO: handle this
                    console.warn('failed to create a webhook:', e);
                } finally {
                    this.setState({ isSaving: false });
                }
            });
        });
    }

    get isSaveDisabled(): boolean {
        if (this.state.isSaving) {
            return true;
        }

        const forValues = this.props.form.getFieldValue('for');
        if (!forValues || !Array.isArray(forValues) || forValues.length === 0) {
            return true;
        }

        return false;
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                title="New Webhook"
                width={500}
                visible={this.props.visible}
                onClose={this.onClose}
                closable={!this.state.isSaving}
                maskClosable={!this.state.isSaving}
            >
                <Form layout="vertical">
                    <Row gutter={16}>
                        <Col span={14}>
                            <Form.Item label="Label">
                                {getFieldDecorator('label', {
                                    initialValue: '',
                                    rules: [{ required: true, message: 'Please provide a label for the webhook' }],
                                })(
                                    <Input
                                        style={{ width: '100%' }}
                                        disabled={this.state.isSaving}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col offset={3} span={7}>
                            <Form.Item label="Enabled">
                                {getFieldDecorator('enabled', {
                                    valuePropName: 'checked',
                                    initialValue: true,
                                    rules: [{ required: true, message: 'Please let us know if this webhook should be enabled or not' }],
                                })(
                                    <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" disabled={this.state.isSaving} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item label="Enabled">
                                {getFieldDecorator('url', {
                                    initialValue: '',
                                    rules: [
                                        { required: true, message: 'Please provide the url of the webhook' },
                                        { pattern: /(^https:\/\/)/, message: 'Invalid url, it must start with "https://".' },
                                    ],
                                })(
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="https://mysite.com/webhook"
                                        disabled={this.state.isSaving}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item label="Events">
                                {getFieldDecorator('for', {
                                    valuePropName: 'targetKeys',
                                    initialValue: [],
                                    rules: [{ required: true, message: 'You must select at least one event this webhook listens for.' }]
                                })(
                                    <Transfer
                                        dataSource={getWebhookEventsAsTransferItems()}
                                        render={(item) => item.title!}
                                        disabled={this.state.isSaving}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={this.onClose} style={{ marginRight: 8 }} disabled={this.state.isSaving}><Icon type="stop" /> Cancel</Button>
                    <Button onClick={this.onSubmit} type="primary" disabled={this.isSaveDisabled}><Icon type="save" /> Save</Button>
                </div>
            </Drawer>
        );
    }
}

export const NewWebhookDrawer = Form.create<INewWebhookProps>()(NewWebhookDrawerBase);
