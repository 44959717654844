import React from 'react';
import { Modal, Row, Col, Form, InputNumber, message } from 'antd';

import { IPublisher } from 'models/publisher';

import { postAdminPayoutPublisher } from 'api/admin';

interface IPayoutPublisherModalProps {
    visible: boolean;
    publisher?: IPublisher;
    close: (saved: boolean) => void;
}

interface IPayoutPublisherModalState {
    isSubmitting: boolean;
    amount: number;
}

export class PayoutPublisherModal extends React.PureComponent<IPayoutPublisherModalProps, IPayoutPublisherModalState> {
    state = {
        isSubmitting: false,
        amount: 10,
    };

    onCancel = () => {
        this.props.close(false);
        this.setState({ isSubmitting: false, amount: 10 });
    }

    onPayout = () => {
        this.setState({ isSubmitting: true }, async () => {
            if (!this.props.publisher) {
                return;
            }

            let result;
            try {
                result = await postAdminPayoutPublisher(this.props.publisher._id, this.state.amount);
            } catch (e) {
                message.error(e.error);
                this.setState({ isSubmitting: false });
                return;
            }

            this.props.close(true);
            this.setState({ isSubmitting: false, amount: 10 });

            Modal.info({
                title: 'Expected arrival of funds',
                content: (<p>{ result.expected.toLocaleString() }</p>)
            });
        });
    }

    onPayoutAmountChange = (value: number | undefined) => {
        this.setState({ amount: value ? value : 0 });
    }

    render() {
        const { publisher } = this.props;
        if (!publisher) {
            return null;
        }

        return (
            <Modal
                title={`Initiate a Payout for ${ publisher.name }`}
                visible={this.props.visible}
                onCancel={this.onCancel}
                onOk={this.onPayout}
                okText="Payout"
                okButtonProps={{ disabled: this.state.isSubmitting, loading: this.state.isSubmitting }}
                cancelButtonProps={{ disabled: this.state.isSubmitting }}
                closable={!this.state.isSubmitting}
                maskClosable={!this.state.isSubmitting}
            >
                <Row>
                    <Col span={24}>
                        <Form layout="inline">
                            <Form.Item label="Payout Amount">
                                <InputNumber
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value ? value.replace(/\$\s?|(,*)/g, '') : ''}
                                    value={this.state.amount}
                                    min={10}
                                    max={publisher.stripeAvailableBalance}
                                    onChange={this.onPayoutAmountChange}
                                    disabled={this.state.isSubmitting}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Modal>
        );
    }
}
