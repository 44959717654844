import React from 'react';
import { Modal, Form, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

import { createAdminCategory } from 'api/admin';

interface IFormValues {
    title: string;
}

interface INewCategoryModalProps extends FormComponentProps<IFormValues> {
    visible: boolean;
    close: (saved: boolean) => void;
}

interface INewCategoryModalState {
    saving: boolean;
}

class NewCategoryModalBase extends React.PureComponent<INewCategoryModalProps, INewCategoryModalState> {
    state: Readonly<INewCategoryModalState> = {
        saving: false,
    };

    onCancel = () => this.props.close(false)

    onSave = () => {
        this.props.form.validateFields(async (err, values) => {
            if (err) {
                return;
            }

            try {
                await createAdminCategory(values);
                this.props.close(true);
            } catch (e) {
                console.warn('error creating the category:', e);
            }
        });
    }

    get title() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form.Item label="Category Title">
                {getFieldDecorator('title', {
                    initialValue: '',
                    rules: [{ required: true, message: 'Please state the title of the category', whitespace: true }],
                })(
                    <Input disabled={this.state.saving} />
                )}
            </Form.Item>
        );
    }

    render() {
        return (
            <Modal
                title="New Category"
                visible={this.props.visible}
                onOk={this.onSave}
                okText="Save"
                okButtonProps={{ disabled: this.state.saving, loading: this.state.saving }}
                onCancel={this.onCancel}
                cancelButtonProps={{ disabled: this.state.saving }}
                closable={!this.state.saving}
            >
                <Form>
                    {this.title}
                </Form>
            </Modal>
        );
    }
}

export const NewCategoryModal = Form.create<INewCategoryModalProps>()(NewCategoryModalBase);
